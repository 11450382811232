import { EventType } from '../../../../../../events/eventType';
import { useEmit } from '../../../../../../hooks/useEmit';

const useTeacherHRAController = () => {
  // const dispatch = useAppDispatch();

  const openHRARequestEmitter = useEmit<void>(EventType.HRAOpenRequest, undefined, {
    enabled: false,
  });

  const prevUnitRequestEmitter = useEmit<void>(EventType.HRAPrevUnit, undefined, {
    enabled: false,
  });

  const nextUnitRequestEmitter = useEmit<void>(EventType.HRANextUnit, undefined, {
    enabled: false,
  });

  const setTextRequestEmitter = useEmit<string>(EventType.HRASetText, undefined, {
    enabled: false,
  });

  const setIsUnitCompleteEmitter = useEmit<boolean>(EventType.HRASetIsUnitComplete, undefined, {
    enabled: false,
  });

  const closeHraRequestEmitter = useEmit<void>(EventType.HRACloseRequest, undefined, {
    enabled: false,
  });

  const onPrevUnit = () => {
    prevUnitRequestEmitter.queueMessage();
  };

  const onNextUnit = () => {
    nextUnitRequestEmitter.queueMessage();
  };

  const onNavigateToHRA = () => {
    openHRARequestEmitter.queueMessage();
  };

  const onSetText = (text: string) => {
    setTextRequestEmitter.queueMessage(text);
  };

  const onUnitComplete = (isUnitComplete: boolean) => {
    setIsUnitCompleteEmitter.queueMessage(isUnitComplete);
  };

  const onExitAssessment = () => {
    closeHraRequestEmitter.queueMessage();
  };

  return {
    onNavigateToHRA,
    onPrevUnit,
    onNextUnit,
    onSetText,
    onUnitComplete,
    onExitAssessment,
  };
};

export default useTeacherHRAController;
