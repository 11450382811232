import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { InstructionIndicatorThreshold } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { AssessmentUnitSubmission, useSubmitAssessment } from '@hoot/hooks/api/assessment/useSubmitAssessment';
import { StepTestAnswer, UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import {
  calculateScore,
  getInstructionIndicator,
  pmaStepTestAnswerToStepTestAnswerDictionary,
  stepTestAnswerToPmaStepTestAnswerDictionary,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/assessment-utils';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import QuestionnaireLayout2 from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout2';
import useTeacherHRAController from '../useTeacherHRAController';

/** INTERFACES */

export interface HRAQuestionnaireLayout_2_Props {
  assessmentId: string;
  unitIdentifier: UnitIdentifierHRAV2.U_1_5;
  unitId: string;
  unitTitle: string;
  fieldName: string;
  instructions: string;
  letterOptions: string[];
  instructionIndicatorThresholds: InstructionIndicatorThreshold[];
  submissionId?: string;
  defaultAnswers?: StepTestAnswer[];
  isPreviousUnitEnabled: boolean;
}

/** StepTest */
const HRA_QUESTIONNAIRE_LAYOUT_2 = (props: HRAQuestionnaireLayout_2_Props) => {
  const {
    assessmentId,
    unitId,
    unitIdentifier,
    fieldName,
    unitTitle,
    instructions,
    letterOptions,
    submissionId,
    instructionIndicatorThresholds,
    defaultAnswers,
    isPreviousUnitEnabled,
  } = props;

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);
  const [questionAnswerPairs, setQuestionAnswerPairs] = useState<UnitQuestionAnswerPair[]>(
    letterOptions.map<UnitQuestionAnswerPair>((lo, i) => ({
      fullPrompt: lo,
      wordPrompt: lo,
      // Overly complicated b/c these StepTest enums are duplicated and just slightly different.
      answer: defaultAnswers
        ? (stepTestAnswerToPmaStepTestAnswerDictionary[defaultAnswers[i]] ?? PMAStepTestAnswer.NoAnswer)
        : PMAStepTestAnswer.NoAnswer,
    })),
  );
  const { teacherId, studentId } = useSelector((state: RootState) => state.activeLesson.inLesson!);

  const hasUnitBeenSubmitted = !!submissionId;

  const dispatch = useAppDispatch();
  const hraController = useTeacherHRAController();
  const submitUnitRequest = useSubmitAssessment(assessmentId, unitId);

  const handleOnChange = (val: UnitQuestionAnswerPair[]) => {
    setQuestionAnswerPairs(val);
  };

  const handleAllQuestionsAnswered = () => {
    hraController.onUnitComplete(true);
  };

  const handleSetText = (text: string) => {
    hraController.onSetText(text);
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      hraController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    setQuestionAnswerPairs([]);
    hraController.onPrevUnit();
  };

  const onNextUnit = () => {
    hraController.onNextUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    // Map the form answers to values the API expects.
    const answers = questionAnswerPairs.map<StepTestAnswer>((x) => pmaStepTestAnswerToStepTestAnswerDictionary[x.answer]);

    const unitSubmissionData = {
      [fieldName]: answers,
    };
    const score = calculateScore(answers);
    const instructionIndicator = getInstructionIndicator(score, instructionIndicatorThresholds);

    const submitData: AssessmentUnitSubmission = {
      submissionId: submissionId,
      studentProfileId: studentId,
      teacherAccountId: teacherId,
      score: score,
      instructionIndicator: instructionIndicator?.instructionLevel,
      submissionData: unitSubmissionData,
      transitionScore: score,
    };
    submitUnitRequest.mutate(submitData, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: `Unit ${unitTitle} successfully submitted.` }));
        hraController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: `Oops! There was a problem submitting unit ${unitTitle}.` }));
      },
    });
  };

  return (
    <QuestionnaireLayout2
      value={questionAnswerPairs}
      onChange={handleOnChange}
      title="Hoot Reading Assessment"
      unitIdentifier={unitIdentifier}
      instruction={instructions}
      unitTitle={unitTitle}
      onPreviousUnitClick={onPreviousUnitClick}
      onSubmit={onNextUnitAndSubmissionClick}
      onNext={onNextUnit}
      isNextEnabled={true}
      isPrevEnabled={isPreviousUnitEnabled}
      isDirty={isUnitQuestionnaireDirty}
      setIsDirty={setIsUnitQuestionnaireDirty}
      confirmPrevUnitClick={confirmPrevUnitClick}
      isSubmitting={submitUnitRequest.isLoading}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onAllQuestionsAnswered={handleAllQuestionsAnswered}
      allowedValues={letterOptions}
      onSetText={handleSetText}
      hasUnitBeenSubmitted={hasUnitBeenSubmitted}
      onExitAssessment={hraController.onExitAssessment}
    />
  );
};

export default HRA_QUESTIONNAIRE_LAYOUT_2;
