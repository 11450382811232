import { DailyFactoryOptions } from '@daily-co/daily-js';
import { DailyProvider, useCallObject, useDaily, useDailyEvent } from '@daily-co/daily-react';
import React, { PropsWithChildren, useCallback } from 'react';
import { IS_PROD } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import { LessonEndedMessage } from '@hoot/events/messages/lesson-ended-message';
import { LessonJoinedMessage } from '@hoot/events/messages/lesson-joined.message';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';

const callObjFactoryConfig: DailyFactoryOptions = {
  sendSettings: {
    video: {
      maxQuality: 'low',
      encodings: {
        low: {
          maxBitrate: 100000,
          scaleResolutionDownBy: 1, // uses either the default device res, or the contraint defined in the DailyConfig
          maxFramerate: 10,
        },
      },
    },
  },
  dailyConfig: {
    userMediaVideoConstraints: {
      width: 320, // This will be used as the base resolution for send settings scaling
    },
  },
};

export const DailyHootLayout = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const dailyCallObj = useDaily();

  // Set Initial State for Session Data
  useDailyEvent(
    'joined-meeting',
    useCallback(() => {
      if (dailyCallObj) {
        dailyCallObj.setLocalVideo(true);
        dailyCallObj.setLocalAudio(true);

        const data = dailyCallObj.meetingSessionState().data;
        console.log('[Initial Meeting Session State]: ', data);
      }
    }, [dailyCallObj]),
  );

  const joinLesson = useCallback(
    (message: LessonJoinedMessage) => {
      console.log(`Received lesson-joined event.`);
      const {
        lesson: { dailyToken, dailyUrl },
      } = message;
      if (dailyCallObj) {
        console.log('[Daily]: Joining Meeting...');

        dailyCallObj
          .join({
            token: dailyToken,
            url: dailyUrl,
            inputSettings: {
              video: {
                processor: {
                  type: 'none',
                },
              },
              audio: {
                processor: {
                  type: IS_PROD ? 'noise-cancellation' : 'none',
                },
              },
            },
          })
          .then(() => {
            console.log('[Daily]: Connected to meeting!');
          })
          .catch((err) => {
            console.log('[Daily]: Issue joining meeting', err);
          });
      }
    },
    [dailyCallObj],
  );

  useSocketSubscription<LessonJoinedMessage>(EventType.LessonJoined, {
    onEventReceived(message) {
      joinLesson(message);
    },
  });

  useSocketSubscription<LessonEndedMessage>(EventType.LessonEnded, {
    async onEventReceived(lessonEndedMessage) {
      console.log(`Received lesson-ended event.`, lessonEndedMessage);
      if (dailyCallObj) {
        await dailyCallObj.leave();
      }
    },
  });

  return children;
};

export function DailyProviderManager(props: { children: React.ReactNode }) {
  const callObj = useCallObject({
    options: callObjFactoryConfig,
  });

  return (
    <DailyProvider callObject={callObj}>
      <DailyHootLayout>{props.children}</DailyHootLayout>
    </DailyProvider>
  );
}
