import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import AssessmentTextUnit from '@hoot/ui/components/v2/assessments/student/AssessmentTextUnit';
import AssessmentUnitContainer, { AssessmentUnitContainerProps } from '@hoot/ui/components/v2/assessments/student/AssessmentUnitContainer';
import StudentLessonReaderPage from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentLessonReaderPage';
import { RootState } from '../../../../../redux/store';
import { AssessmentImage } from '../../../../components/v2/assessments/student/AssessmentImage';

type PMAUnitProps = AssessmentUnitContainerProps;

const PMAUnit = (props: PMAUnitProps) => {
  const currentUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);
  const text = useSelector((state: RootState) => state.pma.text);

  if (isUnitComplete) {
    return (
      <AssessmentUnitContainer {...props}>
        <AssessmentImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} />
      </AssessmentUnitContainer>
    );
  }

  switch (currentUnit) {
    case PMAUnitIdentifier.Module_1_Unit_1:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/segmentation.svg'} />
        </AssessmentUnitContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_2:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/blending.svg'} />
        </AssessmentUnitContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_3:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/deletion.svg'} />
        </AssessmentUnitContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_4:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/substitution.svg'} />
        </AssessmentUnitContainer>
      );
    case PMAUnitIdentifier.Module_1_Unit_5:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentTextUnit text={text ?? ''} isLetterUnit={true} />
        </AssessmentUnitContainer>
      );
    case PMAUnitIdentifier.Module_2_Unit_1:
    case PMAUnitIdentifier.Module_2_Unit_2:
    case PMAUnitIdentifier.Module_2_Unit_3:
    case PMAUnitIdentifier.Module_2_Unit_4:
    case PMAUnitIdentifier.Module_2_Unit_5:
    case PMAUnitIdentifier.Module_2_Unit_6:
    case PMAUnitIdentifier.Module_3_Unit_1:
    case PMAUnitIdentifier.Module_3_Unit_2:
    case PMAUnitIdentifier.Module_3_Unit_3:
    case PMAUnitIdentifier.Module_3_Unit_4:
    case PMAUnitIdentifier.Module_3_Unit_5:
    case PMAUnitIdentifier.Module_3_Unit_6:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentTextUnit text={text ?? ''} />
        </AssessmentUnitContainer>
      );
    case PMAUnitIdentifier.Module_4_Unit_2:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} />
        </AssessmentUnitContainer>
      );

    case PMAUnitIdentifier.Module_4_Unit_1:
    case PMAUnitIdentifier.Module_4_Unit_3:
    case PMAUnitIdentifier.Module_4_Unit_4:
      return <StudentLessonReaderPage />;
    default:
      return (
        <AssessmentUnitContainer {...props}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} />
        </AssessmentUnitContainer>
      );
  }
};

type StudentPMAPageProps = AssessmentUnitContainerProps;

const StudentPMAPage = (props: StudentPMAPageProps) => {
  return (
    <Stack sx={{ height: '100%', padding: 2 }}>
      <PMAUnit {...props} />
    </Stack>
  );
};

export default StudentPMAPage;
