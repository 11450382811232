import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { InstructionIndicatorThreshold } from '../../../../../../../hooks/api/assessment/useGetAssessmentUnit';
import { AssessmentUnitSubmission, useSubmitAssessment } from '../../../../../../../hooks/api/assessment/useSubmitAssessment';
import { StepTestAnswer, UnitIdentifierHRAV2 } from '../../../../../../../models/api/enums/hoot-reading-assessment';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { QuestionnaireLayout1 } from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout1';
import {
  calculateScore,
  getInstructionIndicator,
  pmaStepTestAnswerToStepTestAnswerDictionary,
  stepTestAnswerToPmaStepTestAnswerDictionary,
} from '../assessment-utils';
import useTeacherHRAController from '../useTeacherHRAController';

export interface HRAQuestionnaireLayout_1_Props {
  assessmentId: string;
  unitIdentifier: UnitIdentifierHRAV2.U_1_1 | UnitIdentifierHRAV2.U_1_2 | UnitIdentifierHRAV2.U_1_3 | UnitIdentifierHRAV2.U_1_4;
  unitId: string;
  unitTitle: string;
  instructions: string;
  fieldName: string;
  instructionIndicatorThresholds: InstructionIndicatorThreshold[];
  submissionId?: string;
  defaultAnswers?: StepTestAnswer[];
  prompts: HRAQuestionnaireLayout_1_Prompts[];
  isPreviousUnitEnabled: boolean;
}

export interface HRAQuestionnaireLayout_1_Prompts {
  sentence: string;
  word: string;
  expectedResponse: string;
  wordsToBold: string;
}

export function HRA_QUESTIONNAIRE_LAYOUT_1(props: HRAQuestionnaireLayout_1_Props) {
  const {
    assessmentId,
    unitId,
    unitTitle,
    instructions,
    prompts,
    fieldName,
    submissionId,
    instructionIndicatorThresholds,
    defaultAnswers,
    isPreviousUnitEnabled,
    unitIdentifier,
  } = props;

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, _setIsUnitQuestionnaireDirty] = useState<boolean>(false);
  const [questionAnswerPairs, setQuestionAnswerPairs] = useState<UnitQuestionAnswerPair[]>([]);

  const hasUnitBeenSubmitted = !!submissionId;

  const resetForm = useCallback(() => {
    setQuestionAnswerPairs(
      prompts.map<UnitQuestionAnswerPair>((p, i) => ({
        fullPrompt: p.sentence,
        wordPrompt: p.word,
        answer: defaultAnswers
          ? (stepTestAnswerToPmaStepTestAnswerDictionary[defaultAnswers[i]] ?? PMAStepTestAnswer.NoAnswer)
          : PMAStepTestAnswer.NoAnswer,
        expectedAnswer: p.expectedResponse,
      })),
    );
  }, [prompts, defaultAnswers, setQuestionAnswerPairs]);

  // When the unit has changed, then reset the form.
  useEffect(() => {
    resetForm();
  }, [unitId, resetForm]);

  const dispatch = useAppDispatch();
  const hraController = useTeacherHRAController();

  const { studentId, teacherId } = useSelector((state: RootState) => state.activeLesson.inLesson)!;

  const submitUnitRequest = useSubmitAssessment(assessmentId, unitId);

  const isUnitComplete = useSelector((state: RootState) => state.hra.hra?.isUnitComplete);

  const handleOnChange = (val: UnitQuestionAnswerPair[]) => {
    setQuestionAnswerPairs(val);
  };

  const handleAllQuestionsAnswered = () => {
    hraController.onUnitComplete(true);
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      hraController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    setQuestionAnswerPairs([]);
    hraController.onPrevUnit();
  };

  const onNextUnit = () => {
    hraController.onNextUnit();
  };

  const onNextUnitAndSubmissionClick = () => {
    // Map the form answers to values the API expects.
    const answers = questionAnswerPairs.map<StepTestAnswer>((x) => pmaStepTestAnswerToStepTestAnswerDictionary[x.answer]);

    const unitSubmissionData = {
      [fieldName]: answers,
    };
    const score = calculateScore(answers);
    const instructionIndicator = getInstructionIndicator(score, instructionIndicatorThresholds);

    const submitData: AssessmentUnitSubmission = {
      submissionId: submissionId,
      studentProfileId: studentId,
      teacherAccountId: teacherId,
      score: score,
      instructionIndicator: instructionIndicator?.instructionLevel,
      submissionData: unitSubmissionData,
      transitionScore: score,
    };
    submitUnitRequest.mutate(submitData, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: `Unit ${unitTitle} successfully submitted.` }));
        hraController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: `Oops! There was a problem submitting unit ${unitTitle}.` }));
      },
    });
  };

  return (
    <QuestionnaireLayout1
      key={unitId}
      unitIdentifier={unitIdentifier}
      value={questionAnswerPairs}
      onChange={handleOnChange}
      title="Hoot Reading Assessment"
      instruction={instructions}
      unitTitle={unitTitle}
      onPreviousUnitClick={onPreviousUnitClick}
      onNext={onNextUnit}
      onSubmit={onNextUnitAndSubmissionClick}
      isNextEnabled={true}
      isPrevEnabled={isPreviousUnitEnabled}
      confirmPrevUnitClick={confirmPrevUnitClick}
      prompts={props.prompts}
      isUnitComplete={isUnitComplete}
      isLoading={submitUnitRequest.isLoading}
      isSubmitting={submitUnitRequest.isLoading}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onAllQuestionsAnswered={handleAllQuestionsAnswered}
      hasUnitBeenSubmitted={hasUnitBeenSubmitted}
      onExitAssessment={hraController.onExitAssessment}
    />
  );
}
