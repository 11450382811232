import { useState } from 'react';
import { featureFlags } from '@hoot/constants/featureFlags';
import { EventType } from '@hoot/events/eventType';
import { GetBookRequestMessage } from '@hoot/events/messages/get-book-request-message';
import { GetBookResponseMessage } from '@hoot/events/messages/get-book-response-message';
import { LibrarySearchResultsMessage } from '@hoot/events/messages/library-search-results.message';
import { ToggleFavouriteBookRequestMessage } from '@hoot/events/messages/toggle-favourite-book-request.message';
import { ToggleFavouriteBookResponseMessage } from '@hoot/events/messages/toggle-favourite-book-response.message';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { useEmit } from '@hoot/hooks/useEmit';
import useProfile from '@hoot/hooks/useProfile';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookmarkType } from '@hoot/models/api/library';
import { UserProfileType } from '@hoot/models/api/user';
import {
  LibraryContext,
  PaginationMode,
  clearLibraryResults,
  handleLibrarySearchResultsMessage,
  handleToggledFavouriteLibraryBookResponse,
  libraryKeyLookup,
  setLibraryQuery,
  setPaginationMode,
  setPreviewBook,
  shelfTypeToLibraryTabDictionary,
  useLibraries,
} from '@hoot/redux/reducers/librarySlice';
import { useAppDispatch } from '@hoot/redux/store';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import { getDefaultLibraryQuery } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';
import { preloadImageQueue } from '../../../../../utils/preloadImage';

interface UseTeacherLibraryControllerProps {
  libraryContext: LibraryContext.SandboxLibrary | LibraryContext.StudentSpecificLibrary;
  studentProfileId?: string;
}

const useTeacherOutOfLessonLibraryController = (props: UseTeacherLibraryControllerProps) => {
  const { libraryContext, studentProfileId } = props;

  const [isLoadingBooks, setIsLoadingBooks] = useState(false);

  const libraryKey = libraryKeyLookup[libraryContext];
  const { query } = useLibraries()[libraryKey];
  const { profile } = useProfile();

  const dispatch = useAppDispatch();

  const searchLibraryRequestEmitter = useEmit<QueryLibraryV2>(EventType.SearchLibraryRequest, undefined, { enabled: false });
  const toggleFavouriteBookEmitter = useEmit<ToggleFavouriteBookRequestMessage>(EventType.ToggleFavouriteBookRequest, undefined, { enabled: false });
  const getBookRequest = useEmit<GetBookRequestMessage>(EventType.GetBookRequest, undefined, { enabled: false });

  const { isFeatureEnabled } = useFeatureFlags();
  const isSC10263Active = isFeatureEnabled(featureFlags.SC_10263_in_lesson_hra);

  // Library results handler.
  useSocketSubscription<LibrarySearchResultsMessage>(EventType.SearchLibraryResults, {
    onEventReceived: (response) => {
      dispatch(
        handleLibrarySearchResultsMessage({
          libraryContext,
          librarySearchResultsMessage: response,
        }),
      );
      setIsLoadingBooks(false);
    },
  });

  // Get book response.
  useSocketSubscription<GetBookResponseMessage>(EventType.GetBookResponse, {
    onEventReceived: (response) => {
      preloadImageQueue(response.book.pages.map((p) => p.url));
      dispatch(
        setPreviewBook({
          libraryContext: libraryContext,
          previewBook: response.book,
        }),
      );
    },
  });

  // Toggle fav book response.
  useSocketSubscription<ToggleFavouriteBookResponseMessage>(EventType.ToggleFavouriteBookResponse, {
    onEventReceived: (response) => {
      dispatch(handleToggledFavouriteLibraryBookResponse({ libraryContext: libraryContext, response }));
    },
  });

  const onSearchLibrary = (newQuery: QueryLibraryV2) => {
    setIsLoadingBooks(true);

    dispatch(setLibraryQuery({ libraryContext: libraryContext, query: newQuery }));

    searchLibraryRequestEmitter.queueMessage({
      ...newQuery,
      teacherProfileId: profile!.id,
      studentProfileId,
    });
  };

  const onPaginationModeChanged = (paginationMode: PaginationMode) => {
    // Toggling the views will result in a total refresh (pagination is lost), so reset the data.
    dispatch(clearLibraryResults(libraryContext));
    dispatch(setPaginationMode({ libraryContext: libraryContext, paginationMode }));

    // Refresh/reset the library results when the view have been changed.
    onSearchLibrary({
      ...query,
      ...getDefaultLibraryQuery(paginationMode, isSC10263Active, shelfTypeToLibraryTabDictionary[query?.shelfType ?? ShelfType.AllBooks]),
    });
  };

  const onFetchNextPage = () => {
    if (query) {
      onSearchLibrary({
        ...query,
        page: query.page + 1,
      });
    }
  };

  const onToggleFavouriteBook = (bookId: string, profileId: string, profileType: UserProfileType) => {
    const toggleFavouriteBookMessage: ToggleFavouriteBookRequestMessage = {
      bookId,
      profileId,
      profileType,
      shelf: profileType === UserProfileType.Student ? 'student' : 'my',
    };
    toggleFavouriteBookEmitter.queueMessage(toggleFavouriteBookMessage);
  };

  const onPreviewBook = (bookId: string) => {
    getBookRequest.queueMessage({
      bookId,
      studentProfileId,
      studentBookmarkType: BookmarkType.InLesson,
    });
  };

  const onDismissBookPreview = (invalidateFavs: boolean) => {
    dispatch(setPreviewBook({ libraryContext, previewBook: null }));

    // If we toggled the preview book to/from favourites, and we're currently displaying all favourited books, then
    // this list is invalid and must be refreshed.
    if (invalidateFavs && query?.shelfType === ShelfType.Favorites) {
      onSearchLibrary({
        ...query,
        page: 1,
      });
    }
  };

  return {
    isLoadingBooks,
    onSearchLibrary,
    onPaginationModeChanged,
    onFetchNextPage,
    onToggleFavouriteBook,
    onPreviewBook,
    onDismissBookPreview,
  };
};

export default useTeacherOutOfLessonLibraryController;
