import { SvgIcon, SvgIconProps } from '@mui/material';

const ExpandButtonIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="19" viewBox="0 0 20 19" fill="none" {...props}>
    <g filter="url(#filter0_dd_14013_109683)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00065 2.33325H14.0007C15.4734 2.33325 16.6673 3.52716 16.6673 4.99992V12.9999C16.6673 14.4727 15.4734 15.6666 14.0007 15.6666H6.00065C4.52789 15.6666 3.33398 14.4727 3.33398 12.9999V4.99992C3.33398 3.52716 4.52789 2.33325 6.00065 2.33325ZM10.0007 5.83325C10.2768 5.83325 10.5007 6.05711 10.5007 6.33325V8.49992H12.6673C12.9435 8.49992 13.1673 8.72378 13.1673 8.99992C13.1673 9.27606 12.9435 9.49992 12.6673 9.49992H10.5007V11.6666C10.5007 11.9427 10.2768 12.1666 10.0007 12.1666C9.72451 12.1666 9.50065 11.9427 9.50065 11.6666V9.49992H7.33398C7.05784 9.49992 6.83398 9.27606 6.83398 8.99992C6.83398 8.72378 7.05784 8.49992 7.33398 8.49992H9.50065V6.33325C9.50065 6.05711 9.72451 5.83325 10.0007 5.83325Z"
        fill="#808080"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_14013_109683"
        x="-0.666667"
        y="-1"
        width="21.3333"
        height="21.3333"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="0.666667" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_14013_109683" />
        <feOffset dy="0.666667" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14013_109683" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.666667" />
        <feGaussianBlur stdDeviation="0.666667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_14013_109683" result="effect2_dropShadow_14013_109683" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14013_109683" result="shape" />
      </filter>
    </defs>{' '}
  </SvgIcon>
);

export default ExpandButtonIcon;
