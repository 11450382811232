import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { Box, Stack } from '@mui/system';
import { Fragment, useEffect, useState } from 'react';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { hootTokens } from '../../../../../theme/v2/tokens';
import { Button } from '../../../core/Button';
import HootTypography from '../../../core/HootTypography';
import { Icon } from '../../../core/Icon';
import { QuestionnaireContainer } from '../QuestionnaireContainer';
import { QuestionnaireFooter } from '../QuestionnaireFooter';
import UnitCompleteCard from '../UnitCompleteCard';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';

interface Props {
  value: UnitQuestionAnswerPair[];
  title: string;
  instruction: string;
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  unitTitle: string;
  prompts: PMAQuestionnaireLayout_1_Prompts[];
  isUnitComplete?: boolean;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  leaveDialog: boolean;
  setLeaveDialog: (val: boolean) => void;
  onChange: (val: UnitQuestionAnswerPair[]) => void;
  onAllQuestionsAnswered: () => void;
  onPreviousUnitClick: () => void;
  onNext?: () => void;
  onSubmit: () => void;
  confirmPrevUnitClick: () => void;
  hasUnitBeenSubmitted: boolean;
  additionalInstruction?: React.ReactNode;
  onExitAssessment: () => void;
}

export interface PMAQuestionnaireLayout_1_Prompts {
  sentence: string;
  word: string;
  expectedResponse: string;
  wordsToBold: string;
}

export function QuestionnaireLayout1(props: Props) {
  const [currentIndex, setIndex] = useState(0);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);

  const allFormFieldsFilledOut = props.value.filter((uqap) => uqap.answer === PMAStepTestAnswer.NoAnswer).length === 0;

  const answers = props.value.map((p) => p.answer);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);
  const allQuestionsAnswered = props.value.length > 0 && !props.value.some((pair) => pair.answer === PMAStepTestAnswer.NoAnswer);

  useEffect(() => {
    if (allQuestionsAnswered) {
      props.onAllQuestionsAnswered();
    }
  }, [allQuestionsAnswered]);

  const prompt = props.prompts[currentIndex];

  const handleCorrectClicked = () => {
    const updatedState = props.value.map((a, idx) =>
      idx === currentIndex
        ? {
            fullPrompt: a.fullPrompt,
            wordPrompt: a.wordPrompt,
            answer: PMAStepTestAnswer.Correct,
          }
        : a,
    );

    props.onChange(updatedState);
    setIndex((currentState) => (currentState < props.prompts.length - 1 ? (currentState += 1) : currentState));
    setIsUnitQuestionnaireDirty(true);
  };

  const handleIncorrectClicked = () => {
    const updatedState = props.value.map((a, idx) =>
      idx === currentIndex
        ? {
            fullPrompt: a.fullPrompt,
            wordPrompt: a.wordPrompt,
            answer: PMAStepTestAnswer.Wrong,
          }
        : a,
    );
    props.onChange(updatedState);
    setIndex((currentState) => (currentState < props.prompts.length - 1 ? (currentState += 1) : currentState));
    setIsUnitQuestionnaireDirty(true);
  };

  const handleRowClick = (index: number) => {
    setIndex(index);
    setShowEditAnswerDialog(true);
  };

  const handleEditAnswer = (newAnswer: PMAStepTestAnswer) => {
    const updatedState = props.value.map((a, idx) =>
      idx === currentIndex ? { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: newAnswer } : a,
    );
    props.onChange(updatedState);
    setIndex((currentState) => (currentState < props.prompts.length - 1 ? (currentState += 1) : currentState));
    setShowEditAnswerDialog(false);
  };

  const handleCancelEdit = () => {
    setShowEditAnswerDialog(false);
  };

  const handleSubmit = () => {
    if (!props.onNext || isUnitQuestionnaireDirty) {
      props.onSubmit();
    } else {
      if (props.onNext) {
        props.onNext();
      }
    }
  };

  const disableSubmissionBtn = !allFormFieldsFilledOut || props.isLoading || props.isSubmitting;
  const primaryButtonLabel = !disableSubmissionBtn && !isUnitQuestionnaireDirty ? 'Continue' : undefined;

  return (
    <QuestionnaireContainer
      unitIdentifier={props.unitIdentifier}
      title={props.title}
      instruction={props.instruction}
      unitTitle={props.unitTitle}
      additionalInstruction={props.additionalInstruction}
      onExitAssessment={props.onExitAssessment}
    >
      <Stack>
        <Stack marginTop="32px" direction="row" justifyContent="space-evenly">
          {props.isUnitComplete ? (
            <UnitCompleted hasBeenSubmitted={props.hasUnitBeenSubmitted} />
          ) : (
            <WordPrompt
              expectedResponse={prompt.expectedResponse}
              sentence={prompt.sentence}
              onCorrectClick={handleCorrectClicked}
              onIncorrectClick={handleIncorrectClicked}
              wordsToBold={prompt.wordsToBold}
            />
          )}
          <ScoreCard prompts={props.prompts} answers={answers} currentIndex={currentIndex} onRowClick={handleRowClick} />
        </Stack>
        <EditAnswerDialog
          open={showEditAnswerDialog}
          questionLabel={props.prompts[currentIndex].word}
          defaultAnswer={answers[currentIndex]}
          onSubmit={handleEditAnswer}
          onCancel={handleCancelEdit}
          setIsUnitQuestionnaireDirty={setIsUnitQuestionnaireDirty}
        />
      </Stack>
      <QuestionnaireFooter
        allFormFieldsFilledOut={allFormFieldsFilledOut}
        onPreviousUnitClick={props.onPreviousUnitClick}
        setLeaveDialog={props.setLeaveDialog}
        isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
        disableSubmissionBtn={disableSubmissionBtn}
        onNextUnitAndSubmissionClick={handleSubmit}
        isNextEnabled={props.isNextEnabled}
        isPrevEnabled={props.isPrevEnabled}
        submitButtonLabel={primaryButtonLabel}
      />
      <LeaveCurrentUnitDialog open={props.leaveDialog} setOpen={props.setLeaveDialog} confirmLeave={props.confirmPrevUnitClick} />
    </QuestionnaireContainer>
  );
}

function UnitCompleted(props: { hasBeenSubmitted: boolean }) {
  return (
    <Stack
      flex={1}
      sx={{
        borderRadius: '8px',
        border: `solid 1px ${hootTokens.palette.black}`,
        padding: '24px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        padding="16px 0px 16px 0px"
        sx={{
          backgroundColor: hootTokens.surface[2],
          borderRadius: '8px',
        }}
      >
        <UnitCompleteCard hasBeenSubmitted={props.hasBeenSubmitted} />
      </Stack>
    </Stack>
  );
}

function WordPrompt(props: {
  expectedResponse: string;
  sentence: string;
  wordsToBold: string;
  onCorrectClick: () => void;
  onIncorrectClick: () => void;
}) {
  const { expectedResponse, sentence, wordsToBold, onCorrectClick, onIncorrectClick } = props;

  // Emphasizes the words within the full prompt.
  const formatFullPrompt = (): JSX.Element => {
    const wordsToBoldArray = wordsToBold.split(/\s/);
    const wordsFromSentence = sentence.split(/\s/);

    return (
      <HootTypography variant="displaysmall" textAlign="center" fontWeight={400} isPII={false}>
        {wordsFromSentence.map((w, idx) => (
          <Fragment key={`${w}-${idx}`}>
            {wordsToBoldArray.includes(w.replace(/[\.,?!]/g, '')) ? <span style={{ fontWeight: 700 }}>{`${w} `}</span> : `${w} `}
          </Fragment>
        ))}
      </HootTypography>
    );
  };

  return (
    <Stack
      flex={1}
      sx={{
        borderRadius: '8px',
        border: `solid 1px ${hootTokens.palette.black}`,
        padding: '24px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        padding="16px 0px 16px 0px"
        sx={{
          backgroundColor: hootTokens.surface[2],
          borderRadius: '8px',
          textAlign: 'center',
          px: 1,
          py: 2,
        }}
      >
        <HootTypography variant="headlinesmall" isPII={false}>
          {formatFullPrompt()}
        </HootTypography>
      </Stack>
      <Stack marginTop="16px" alignItems="center">
        <HootTypography variant="labelsmall" isPII={false}>
          Expected Response
        </HootTypography>
        <Box
          sx={{
            backgroundColor: hootTokens.surface[2],
            borderRadius: '8px',
            padding: '8px',
          }}
        >
          <HootTypography variant="labelsmall" isPII={false}>
            {expectedResponse}
          </HootTypography>
        </Box>
      </Stack>
      <Stack marginTop="16px" direction="row" justifyContent="center" spacing="16px">
        <Button color="error.190" startIcon={<Icon name="close_filled_square" />} variant="contained" onClick={onIncorrectClick}>
          Incorrect
        </Button>
        <Button
          color="success.190"
          startIcon={<Icon htmlColor={hootTokens.palette.success[80]} name="check_box_filled" />}
          variant="contained"
          onClick={onCorrectClick}
        >
          Correct
        </Button>
      </Stack>
    </Stack>
  );
}

function ScoreCard(props: {
  prompts: PMAQuestionnaireLayout_1_Prompts[];
  answers: PMAStepTestAnswer[];
  currentIndex: number;
  onRowClick: (index: number) => void;
}) {
  const { prompts, answers, currentIndex, onRowClick } = props;
  return (
    <Stack marginLeft="120px" flex={1} padding="24px" spacing="24px">
      <HootTypography variant="tableheadingactive" isPII={false}>
        SCORECARD
      </HootTypography>
      {prompts.map((w, idx) => (
        <ScoreCardRow key={w.word} index={idx + 1} isCurrentWord={idx === currentIndex} word={w.word} answer={answers[idx]} onClick={onRowClick} />
      ))}
    </Stack>
  );
}

function ScoreCardRow(props: { index: number; isCurrentWord: boolean; word: string; answer: PMAStepTestAnswer; onClick: (index: number) => void }) {
  const { index, isCurrentWord, word, answer, onClick } = props;
  const handleRowClick = () => {
    onClick(index - 1);
  };

  function Prefix() {
    if (isCurrentWord) {
      return <Icon sx={{ height: '16px' }} name="play" />;
    }

    return `${index}.`;
  }

  function PostFixIcon() {
    switch (answer) {
      case PMAStepTestAnswer.NoAnswer:
        return null;
      case PMAStepTestAnswer.Correct:
        return <Icon sx={{ height: '24px', width: '24x' }} htmlColor={hootTokens.palette.success[80]} name="check_box_filled" />;
      case PMAStepTestAnswer.Wrong:
        return <Icon sx={{ height: '20px', width: '20px' }} htmlColor={hootTokens.palette.error[80]} name="close_filled_square" />;
    }
  }

  return (
    <Stack
      sx={{ cursor: answer !== PMAStepTestAnswer.NoAnswer ? 'pointer' : undefined }}
      direction="row"
      alignItems="center"
      spacing="16px"
      onClick={answer !== PMAStepTestAnswer.NoAnswer ? handleRowClick : undefined}
    >
      <HootTypography
        sx={{ color: answer === PMAStepTestAnswer.NoAnswer ? hootTokens.palette.neutral[140] : hootTokens.palette.black }}
        variant="bodylarge"
        isPII={false}
      >
        <Prefix /> {word}
      </HootTypography>
      <PostFixIcon />
    </Stack>
  );
}
