import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { InstructionIndicatorThreshold } from '../../../../../../../hooks/api/assessment/useGetAssessmentUnit';
import { AssessmentUnitSubmission, useSubmitAssessment } from '../../../../../../../hooks/api/assessment/useSubmitAssessment';
import { createFlashMessage } from '../../../../../../../redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { QuestionnaireLayout5 } from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout5';
import { RadioOption } from '../../progress-monitoring-assessment/interface';
import useTeacherHRAController from '../useTeacherHRAController';

export interface HRAQuestionnaireLayout_5_Props {
  assessmentId: string;
  unitIdentifier: UnitIdentifierHRAV2.U_4_2;
  unitId: string;
  unitTitle: string;
  instructions: string;
  prompts: HRAQuestionnaireLayout_5_Prompt[];
  fieldName: string;
  instructionIndicatorThresholds: InstructionIndicatorThreshold[];
  submissionId?: string;
  defaultAnswers?: Module_4_GenericAnswer[];
  isPreviousUnitEnabled: boolean;
}

interface HRAQuestionnaireLayout_5_DefaultSubmissionData {
  U_4_2_S1_F1: string;
  U_4_2_S2_F1: string;
}

export interface HRAQuestionnaireLayout_5_Prompt {
  title: string;
  options: RadioOption[];
}

export function HRA_QUESTIONNAIRE_LAYOUT_5(props: HRAQuestionnaireLayout_5_Props) {
  const { assessmentId, unitId, unitIdentifier, unitTitle, instructions, prompts, submissionId, isPreviousUnitEnabled } = props;

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);

  const submitUnitRequest = useSubmitAssessment(assessmentId, unitId);

  const [answers, setAnswers] = useState<Module_4_GenericAnswer[]>(
    prompts.map((q) => {
      const a = props.defaultAnswers?.find((a) => a.section === q.title);

      return {
        section: q.title ?? '',
        answer: a?.answer ?? '',
        answerValue: a?.answerValue ?? null,
      };
    }),
  );

  const { studentId, teacherId } = useSelector((state: RootState) => state.activeLesson.inLesson)!;

  const dispatch = useAppDispatch();
  const hraController = useTeacherHRAController();

  const handleChange = (val: Module_4_GenericAnswer[]) => {
    setAnswers(val);
  };

  const onPreviousUnitClick = () => {
    hraController.onPrevUnit();
  };

  const confirmPrevUnitClick = () => {
    hraController.onPrevUnit();
  };

  const onNextUnit = () => {
    hraController.onNextUnit();
  };

  const handleSubmit = () => {
    const submissionData: HRAQuestionnaireLayout_5_DefaultSubmissionData = {
      U_4_2_S1_F1: answers[0].answerValue!.toString(),
      U_4_2_S2_F1: answers[1].answerValue!.toString(),
    };

    const submitData: AssessmentUnitSubmission = {
      submissionId: submissionId,
      studentProfileId: studentId,
      teacherAccountId: teacherId,
      score: undefined,
      instructionIndicator: undefined,
      transitionScore: undefined,
      submissionData,
    };

    submitUnitRequest.mutate(submitData, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: `Unit ${unitTitle} successfully submitted.` }));
        hraController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: `Oops! There was a problem submitting unit ${unitTitle}.` }));
      },
    });
  };

  return (
    <QuestionnaireLayout5
      title="Hoot Reading Assessment"
      unitIdentifier={unitIdentifier}
      unitTitle={unitTitle}
      instruction={instructions}
      onChange={handleChange}
      isNextEnabled={true}
      isPrevEnabled={isPreviousUnitEnabled}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onPreviousUnitClick={onPreviousUnitClick}
      confirmPrevUnitClick={confirmPrevUnitClick}
      isUnitQuestionnaireDirty
      onSubmit={handleSubmit}
      isSubmitting={submitUnitRequest.isLoading}
      onNext={onNextUnit}
      questionnaire={props.prompts}
      value={answers}
      onExitAssessment={hraController.onExitAssessment}
    />
  );
}
