import { EventType } from '../../../../../../events/eventType';
import { useEmit } from '../../../../../../hooks/useEmit';

const useTeacherPMAController = () => {
  // const dispatch = useAppDispatch();

  const openPMARequestEmitter = useEmit<void>(EventType.PMAOpenRequest, undefined, {
    enabled: false,
  });

  const prevUnitRequestEmitter = useEmit<void>(EventType.PMAPrevUnit, undefined, {
    enabled: false,
  });

  const nextUnitRequestEmitter = useEmit<void>(EventType.PMANextUnit, undefined, {
    enabled: false,
  });

  const setTextRequestEmitter = useEmit<string>(EventType.PMASetText, undefined, {
    enabled: false,
  });

  const setIsUnitCompleteEmitter = useEmit<boolean>(EventType.PMASetIsUnitComplete, undefined, {
    enabled: false,
  });

  const closePmaRequestEmitter = useEmit<void>(EventType.PMACloseRequest, undefined, {
    enabled: false,
  });

  const onPrevUnit = () => {
    prevUnitRequestEmitter.emit();
  };

  const onNextUnit = () => {
    nextUnitRequestEmitter.emit();
  };

  const onNavigateToPMA = () => {
    openPMARequestEmitter.queueMessage();
  };

  const onSetText = (text: string) => {
    setTextRequestEmitter.emit(text);
  };

  const onUnitComplete = (isUnitComplete: boolean) => {
    setIsUnitCompleteEmitter.emit(isUnitComplete);
  };

  const onExitAssessment = () => {
    closePmaRequestEmitter.queueMessage();
  };

  return {
    onNavigateToPMA,
    onPrevUnit,
    onNextUnit,
    onSetText,
    onUnitComplete,
    onExitAssessment,
  };
};

export default useTeacherPMAController;
