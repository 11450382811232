import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import CloseSquare from '@hoot/ui/components/v2/icons/CloseSquare';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { QuestionnaireContainer } from '../QuestionnaireContainer';
import { QuestionnaireFooter } from '../QuestionnaireFooter';
import UnitCompleteCard from '../UnitCompleteCard';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';

/** INTERFACES */

interface Props {
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  title: string;
  instruction: string;
  unitTitle: string;
  onChange: (val: UnitQuestionAnswerPair[]) => void;
  value: UnitQuestionAnswerPair[];
  onPreviousUnitClick: () => void;
  onSubmit: () => void;
  onNext?: () => void;
  isUnitComplete?: boolean;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  isSubmitting: boolean;
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  confirmPrevUnitClick: () => void;
  allowedValues: string[];
  onSetText: (text: string) => void;
  onAllQuestionsAnswered: () => void;
  leaveDialog: boolean;
  setLeaveDialog: (val: boolean) => void;
  hasUnitBeenSubmitted: boolean;
  onExitAssessment: () => void;
}

interface IStepProps {
  letterOptions: string[];
  onEditIndexChanged: (newIndex: number) => void;
  selectedIndex: number | undefined;
  currentIndex: number;
  currentValue: string;
  currentAnswer: PMAStepTestAnswer;
  unitQuestionAnswerPair: PMAStepTestAnswer[];
  stepLength: number;
}

/** Step */
export const Step = (props: IStepProps) => {
  const { currentValue, currentAnswer, unitQuestionAnswerPair, currentIndex, selectedIndex, onEditIndexChanged, stepLength } = props;

  const nextUnansweredQuestionIndex = useMemo(() => {
    return unitQuestionAnswerPair.findIndex((x) => x === PMAStepTestAnswer.NoAnswer);
  }, [unitQuestionAnswerPair]);

  // You can not jump ahead and unitQuestionAnswerPair questions out of order. You can only go back to edit previously answered Qs.
  const isQuestionDisabled = nextUnansweredQuestionIndex >= 0 && currentIndex > nextUnansweredQuestionIndex;

  const handleLetterClick = () => () => {
    if (isQuestionDisabled) {
      return;
    }
    onEditIndexChanged(currentIndex);
  };

  const renderCursorIcon = () => <PlayIcon rotate={RotateDirection.Up} sx={{ height: '24px', width: '16px' }} />;

  const renderAnswerIcon = () => {
    if (currentAnswer === PMAStepTestAnswer.Correct) {
      return <CheckmarkSquare />;
    }
    if (currentAnswer === PMAStepTestAnswer.Wrong) {
      return <CloseSquare />;
    }
  };

  const showIcon = () => {
    // Show cursor on clicked/selected letter.
    if (currentIndex === selectedIndex) {
      // If we're on the last Step.
      if (currentIndex + 1 === stepLength) {
        if (!currentAnswer) {
          return renderCursorIcon();
        }
      }
      return renderCursorIcon();
    }
    if (currentAnswer) {
      return renderAnswerIcon();
    }
    return null;
  };

  const value = currentValue.split(':');
  const isVisible = currentIndex < stepLength;

  return (
    <Box
      onClick={handleLetterClick()}
      sx={{
        visibility: isVisible ? 'visible' : 'hidden',
        display: 'flex',
        flex: '1',
        cursor: isQuestionDisabled ? 'not-allowed' : 'pointer',
        borderRight: '1px solid #CAC4D0',
        '&:last-child': {
          borderRight: '0px',
        },
      }}
    >
      <Stack style={{ flex: 1, alignItems: 'center', justifyContent: 'space-between', gap: '4px' }}>
        <HootTypography
          isPII={false}
          variant="bodylarge"
          sx={{
            color:
              currentAnswer === PMAStepTestAnswer.Correct
                ? hootTokens.palette.success['60']
                : currentAnswer === PMAStepTestAnswer.Wrong
                  ? hootTokens.palette.error['80']
                  : currentIndex <= nextUnansweredQuestionIndex
                    ? hootTokens.palette.black
                    : hootTokens.palette.neutral['140'],
          }}
        >
          {value[0]}
        </HootTypography>
        <Box width={24} height={24} display="flex" justifyContent="center">
          {showIcon()}
        </Box>
      </Stack>
    </Box>
  );
};

/** StepTest */
const QuestionnaireLayout2 = (props: Props) => {
  const allFormFieldsFilledOut = props.value.filter((uqap) => uqap.answer === PMAStepTestAnswer.NoAnswer).length === 0;

  const [currentIndex, setCurrentIndex] = useState<number | null>(0);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);

  const showAmount = props.allowedValues.length;

  const allQuestionsAnswered = props.value.length > 0 && !props.value.some((pair) => pair.answer === PMAStepTestAnswer.NoAnswer);

  useEffect(() => {
    if (allQuestionsAnswered) {
      props.onAllQuestionsAnswered();
    }
  }, [allQuestionsAnswered]);

  useEffect(() => {
    if (currentIndex !== null) {
      props.onSetText(props.allowedValues[currentIndex]);
    }
  }, [currentIndex]);

  const handleEditIndexChanged = (newIndex: number) => {
    setCurrentIndex(newIndex);
    if (allFormFieldsFilledOut) {
      setShowEditAnswerDialog(true);
    }
  };

  const handleAnswerSelect = (value: string, answer: PMAStepTestAnswer) => {
    const indexOfValue = props.allowedValues.indexOf(value);
    const nextIndex = indexOfValue + 1;

    const updatedAnswers = props.value.map((a) => {
      if (a.wordPrompt === value) {
        return { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: answer } as UnitQuestionAnswerPair;
      } else {
        return a;
      }
    });
    props.onChange(updatedAnswers);
    props.setIsDirty(true);

    if (nextIndex < showAmount) {
      setCurrentIndex((prev) => (prev! += 1));
    } else {
      setCurrentIndex(null);
    }
  };

  const handleCancelEdit = () => {
    setShowEditAnswerDialog(false);
  };

  const handleEditAnswer = (newAnswer: PMAStepTestAnswer) => {
    const updatedAnswers = props.value.map((a) => {
      if (a.wordPrompt === props.allowedValues[currentIndex!]) {
        return { fullPrompt: a.fullPrompt, wordPrompt: a.wordPrompt, answer: newAnswer } as UnitQuestionAnswerPair;
      } else {
        return a;
      }
    });
    props.onChange(updatedAnswers);
    setCurrentIndex(null);
    handleCancelEdit();
  };

  const handleSubmit = () => {
    if (!props.onNext || props.isDirty) {
      props.onSubmit();
    } else {
      if (props.onNext) {
        props.onNext();
      }
    }
  };

  // Split the Questions into 2 chunks for the UI
  const allowedValuesMax = Math.floor(props.allowedValues.length / 2);
  const [firstChunk, secondChunk] = [
    props.allowedValues.slice(0, allowedValuesMax),
    props.allowedValues.slice(allowedValuesMax, props.allowedValues.length),
  ];

  const disableSubmissionBtn = !allFormFieldsFilledOut || props.isSubmitting;
  const primaryButtonLabel = !disableSubmissionBtn && !props.isDirty ? 'Continue' : undefined;

  return (
    <QuestionnaireContainer
      unitIdentifier={props.unitIdentifier}
      title={props.title}
      instruction={props.instruction}
      unitTitle={props.unitTitle}
      onExitAssessment={props.onExitAssessment}
    >
      <Stack gap={4} paddingY={3} flex={1}>
        <Stack
          sx={{
            gap: '16px',
            border: '1px solid black',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '24px',
          }}
        >
          {allQuestionsAnswered ? (
            <UnitCompleteCard hasBeenSubmitted={props.hasUnitBeenSubmitted} />
          ) : (
            <>
              <Stack
                sx={{
                  borderRadius: '8px',
                  background: hootTokens.surface[2],
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingY: '16px',
                  width: '100%',
                }}
              >
                <HootTypography isPII={false} variant="bodymedium">
                  Tell me what sound this letter makes:
                </HootTypography>
                <HootTypography isPII={false} variant="displaymedium">
                  {props.allowedValues[currentIndex!]}
                </HootTypography>
              </Stack>
              <Stack flexDirection="row" gap={2}>
                <Button
                  onClick={() => handleAnswerSelect(props.allowedValues[currentIndex!], PMAStepTestAnswer.Wrong)}
                  variant="contained"
                  color="error.190"
                  disableElevation
                  startIcon={<CloseFilledSquareIcon />}
                >
                  Incorrect
                </Button>
                <Button
                  onClick={() => handleAnswerSelect(props.allowedValues[currentIndex!], PMAStepTestAnswer.Correct)}
                  variant="contained"
                  color="success.190"
                  disableElevation
                  startIcon={<CheckmarkSquare />}
                >
                  Correct
                </Button>
              </Stack>
            </>
          )}
        </Stack>
        <Stack gap={2}>
          <HootTypography isPII={false} variant="tableheadingactive">
            Scorecard
          </HootTypography>
          <Stack gap={2}>
            <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
              {firstChunk.map((val, idx) => (
                <Step
                  key={val}
                  currentIndex={idx}
                  selectedIndex={currentIndex ?? undefined}
                  onEditIndexChanged={handleEditIndexChanged}
                  currentValue={val}
                  currentAnswer={props.value.length > 0 ? props.value[idx].answer : PMAStepTestAnswer.NoAnswer}
                  unitQuestionAnswerPair={props.value.map((a) => a.answer)}
                  stepLength={showAmount}
                  letterOptions={[]}
                />
              ))}
            </Stack>
            {showAmount > allowedValuesMax && (
              <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
                {secondChunk.map((val, idx) => (
                  <Step
                    key={val}
                    currentIndex={idx + firstChunk.length}
                    selectedIndex={currentIndex ?? undefined}
                    onEditIndexChanged={handleEditIndexChanged}
                    currentValue={val}
                    currentAnswer={props.value.length > 0 ? props.value[idx + firstChunk.length].answer : PMAStepTestAnswer.NoAnswer}
                    unitQuestionAnswerPair={props.value.map((a) => a.answer)}
                    stepLength={showAmount}
                    letterOptions={[]}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <EditAnswerDialog
        open={showEditAnswerDialog}
        questionLabel={currentIndex !== null ? props.allowedValues[currentIndex] : ''}
        defaultAnswer={currentIndex !== null ? props.value[currentIndex]?.answer : PMAStepTestAnswer.NoAnswer}
        onSubmit={handleEditAnswer}
        onCancel={handleCancelEdit}
        setIsUnitQuestionnaireDirty={props.setIsDirty}
      />
      <QuestionnaireFooter
        allFormFieldsFilledOut={allFormFieldsFilledOut}
        onPreviousUnitClick={props.onPreviousUnitClick}
        setLeaveDialog={props.setLeaveDialog}
        isUnitQuestionnaireDirty={props.isDirty}
        disableSubmissionBtn={disableSubmissionBtn}
        onNextUnitAndSubmissionClick={handleSubmit}
        isSubmitting={props.isSubmitting}
        isNextEnabled={props.isNextEnabled}
        isPrevEnabled={props.isPrevEnabled}
        submitButtonLabel={primaryButtonLabel}
      />
      <LeaveCurrentUnitDialog open={props.leaveDialog} setOpen={props.setLeaveDialog} confirmLeave={props.confirmPrevUnitClick} />
    </QuestionnaireContainer>
  );
};

export default QuestionnaireLayout2;
