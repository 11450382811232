import { Stack } from '@mui/system';
import { CSSProperties } from 'react';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import HootTypography, { HootTypographyProps } from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export function CameraButton(props: { onClick: () => void; isOn: boolean; disabled?: boolean }) {
  return (
    <IconButton disabled={props.disabled} onClick={props.onClick}>
      <Icon name={props.isOn ? 'camera_on' : 'camera_off'} />
    </IconButton>
  );
}

export function MicButton(props: { onClick: () => void; isOn: boolean }) {
  return (
    <IconButton onClick={props.onClick}>
      <Icon name={props.isOn ? 'mic_on' : 'mic_off'} />
    </IconButton>
  );
}

export function LockButton(props: { onClick: () => void; isLocked: boolean }) {
  return (
    <IconButton onClick={props.onClick}>
      <Icon name={props.isLocked ? 'locked' : 'unlocked'} />
    </IconButton>
  );
}

export function MenuButton(props: { onClick: () => void }) {
  return (
    <IconButton onClick={props.onClick}>
      <Icon name="gear" />
    </IconButton>
  );
}

export function WaitingForUser(props: { name: string; imgWidth?: CSSProperties['width']; imgHeight?: CSSProperties['height'] }) {
  return (
    <Stack
      sx={{
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation5,
        padding: '16px',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <ViewStateIllustration
        padding={0}
        illustration={IllustrationEnum.NoInternet}
        title="Any day now..."
        subtitle={`Waiting for ${props.name} to join`}
        subtitleSx={{ textAlign: 'center' }}
        imageWidth={props.imgWidth}
        imageHeight={props.imgHeight}
      />
    </Stack>
  );
}

export function CameraOff(props: { variant?: HootTypographyProps['variant'] }) {
  const { variant = 'bodymedium' } = props;
  return (
    <Stack
      sx={{
        backgroundColor: '#000',
        color: '#FFF',
        width: '100%',
        height: '100%',
        zIndex: 1,
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation2,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HootTypography isPII={false} variant={variant} sx={{ textAlign: 'center' }}>
        Camera is Off
      </HootTypography>
    </Stack>
  );
}
