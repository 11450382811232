import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { zIndexes } from '@hoot/constants/zIndices';
import DailyMyMagicFinger from '@hoot/ui/components/v2/library/DailyMyMagicFinger';
import DailyTheirMagicFinger from '@hoot/ui/components/v2/library/DailyTheirMagicFinger';

export interface AssessmentUnitContainerProps extends PropsWithChildren<any> {
  scaleMagicFinger?: number;
  disableMyMagicFinger?: boolean;
}

const AssessmentUnitContainer = (props: AssessmentUnitContainerProps) => {
  const { children, scaleMagicFinger, disableMyMagicFinger = false } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        zIndex: zIndexes.bookPageOnReader,
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'fit-content',
          height: 'fit-content',
          position: 'relative',
          userSelect: 'none',
        }}
      >
        {!disableMyMagicFinger && <DailyMyMagicFinger height="100%" width="100%" scaleMagicFinger={scaleMagicFinger} />}
        <DailyTheirMagicFinger height="100%" width="100%" scaleMagicFinger={scaleMagicFinger} />
        {children}
      </Box>
    </Box>
  );
};

export default AssessmentUnitContainer;
