export enum QueryKey {
  CurrentTime = 'current-time',
  FeatureFlags = 'feature-flags',
  LatestHootReadingAppVersion = 'latest-hoot-reading-app-version',
  LessonNotesForStudent = 'lesson-notes-for-student',
  LibraryQuery = 'library-query',
  GetTeacherLesson = 'get-teacher-lesson',
  MonthlySchedulesLessons = 'monthly-scheduled-lessons',
  DistrictRepMonthlyEnrolmentsLessons = 'district-rep-monthly-enrolments-lessons',
  RelatedProfiles = 'related-profiles',
  FetchUser = 'fetch-user',
  GetDistrictRepPermissions = 'get-district-rep-permissions',
  GetStudentProfile = 'get-student-profile',
  GetStudentProfileSnippet = 'get-student-profile-snippet',
  GetStudentProfileV2 = 'get-student-profile-v2',
  GetStudentProfiles = 'get-student-profiles',
  GetTeacherAvailability = 'get-teacher-availability',
  GetLessonOpportunities = 'get-lesson-opportunities',
  TeacherAvailabilityException = 'teacher-availability-exception',
  GetTeacherAvailabilityExceptionConflicts = 'get-teacher-availability-exception-conflicts',
  GetTeacherAvailabilityExceptions = 'get-teacher-availability-exceptions',
  CreateAssessment = 'create-assessment',
  GetAssessments = 'get-assessments',
  GetAssessmentsV2 = 'get-assessments-v2',
  GetAssessmentById = 'get-assessment-by-id',
  GetMostRecentAssessment = 'get-most-recent-assessments',
  GetAssessmentUnit = 'get-assessment-unit',
  GetSubmissionDetails = 'get-submission-details',
  GetAssessmentsDetailsV2 = 'get-assessments-details-v2',
  GetFirstAssessmentUnit = 'get-first-assessment-unit',
  GetAssessmentEntryPointUnit = 'get-assessment-entry-point-unit',
  GetCustomerAccount = 'get-customer-account',
  GetLocation = 'get-location',
  GetLocationDetails = 'get-location-details',
  GetDistrictRepresentativeDetails = 'get-district-representative-details',
  GetNotifications = 'get-notifications',
  GetBookmarkedNotifications = 'get-bookmarked-notifications',
  SearchDistrictRepresentativeStudents = 'search-district-representative-students',
  SearchDistrictRepresenatativeStudentsUnderLocation = 'search-district-representative-students-under-location',
  SearchDistrictRepresenatativeStudentsUnderEnrolment = 'search-district-representative-students-under-enrolment',
  GetDistrictRepStudentProfileSnippet = 'get-district-rep-student-profile-snippet',
  GetDistrictRepSchoolSnippet = 'get-district-rep-school-snippet',
  GetDistrictRepEnrolmentSnippet = 'get-district-rep-enrolment-snippet',
  GetDistrictRepEnrolment = 'get-district-rep-enrolment',
  GetDistrictRepEnrolmentByFriendlyId = 'get-district-rep-enrolment-by-friendly-id',
  GetDistrictRepProductSnippet = 'get-district-rep-product-snippet',
  GetDistrictRepProduct = 'get-district-rep-product',
  GetStudentForDistrictRep = 'get-student-for-district-representative',
  SearchDistrictRepresentativeEnrolmentsFilters = 'search-district-representative-enrolments-filters',
  SearchDistrictRepresentativeEnrolments = 'search-district-representative-enrolments',
  SearchDistrictRepresentativeSchedule = 'search-district-representative-schedule',
  SearchDistrictRepresentativeSchools = 'search-district-representative-schools',
  SearchDistrictRepresentativeTeamMembers = 'search-district-representative-team-members',
  GetFlfStudentProfile = 'get-flf-student-profile',
  GetFlfProfile = 'get-flf-profile',
  GetFlfStudentHasNoLesson = 'get-flf-student-has-no-lesson',
  GetInvoiceCurrentPeriod = 'get-invoice-current-period',
  GetInvoiceDetails = 'get-invoice-details',
  GetInvoiceSummary = 'get-invoice-summary',
  GetInvoiceProjects = 'get-invoice-projects',
  GetInvoiceBillableLessonSummary = 'get-invoice-billable-lesson-summary',
  GetInvoiceBillableTasks = 'get-invoice-billable-tasks',
  GetLessonsCSVReport = 'get-lessons-csv-report',
  GetPastInvoicesListing = 'get-past-invoices-listing',
  GetInstructionalFocuses = 'get-instructional-focuses',
  GetInstructionalUnits = 'get-instructional-units',
  GetInstructionalUnitsFilter = 'get-instructional-units-filter',
  GetFilterLevelPrioritizedSkills = 'get-filter-level-prioritized-skills',
  GetSeries = 'get-series',
  GetGenres = 'get-genres',
  GetSubGenres = 'get-sub-genres',
  GetTopics = 'get-topics',
  GetSubTopics = 'get-sub-topics',
  GetThemes = 'get-themes',
  GetSubThemes = 'get-sub-themes',
  GetFormats = 'get-formats',
  GetReadingLevels = 'get-reading-levels',
  GetInvoiceAdditionalServicesSummary = 'get-invoice-additional-services-summary',
  GetWhiteboardTemplates = 'get-whiteboard-templates',
  GetLessonWhiteboardTemplates = 'get-lesson-whiteboard-templates',
  GetLessonWhiteboards = 'get-lesson-whiteboards',
  GetLessonWhiteboardSnapshot = 'get-lesson-whiteboard-snapshot',
  SearchLibraryV2 = 'search-library-v2',
  SearchStudentLibrary = 'search-student-library',
  GetStudentInstructionalLibrary = 'get-student-instructional-library',
  GetAppNotifications = 'get-app-notifications',
  GetStudentBook = 'get-student-book',
  PutBookmark = 'put-bookmark',
  ToggleStudentFavouriteBook = 'toggle-student-favourite-book',
  GetActiveReviewTasks = 'get-active-user-tasks',
  GetRequiredTasksByStudent = 'get-action-required-tasks-by-student',
  GetRequiredTasks = 'get-action-required-tasks',
  GetRecentReviews = 'get-recent-reviews',
  GetReviewHistory = 'get-review-history',
  GetLessonNote = 'get-lesson-note',
  GetLessonReviewDetails = 'get-lesson-review-details',
  GetStudentFormativeAssessments = 'get-student-formative-assessments',
  GetStudentTextReadings = 'get-student-text-readings',
  GetStudentInstructionalFocusData = 'get-student-instructional-focus-data',
  GetLessonPlan = 'get-lesson-plan',
  GetLessonReviewNotes = 'get-lesson-review-notes',
  GetLessonReview = 'get-lesson-review',
  GetFormativeAssessmentBookDetails = 'get-formative-assessment-book-details',
  GetLessonPlanBookDetails = 'get-lesson-plan-book-details',
  GetStudentAssessmentActions = 'get-student-assessment-actions',
  GetStudentAssessmentReminder = 'get-student-assessment-reminders',
  GetStudentTimeline = 'get-student-timeline',
  GetMyStudents = 'get-my-students',
  GetBookCollections = 'get-book-collections',
  GetLessonReviewState = 'get-lesson-review-state',
  GetEdlinkSsoRedirectInfo = 'get-edlink-sso-redirect-info',
  GetTeacherShifts = 'get-teacher-shifts',
  LessonSearch = 'lesson-search',
  GetEventsImpactedByAvailabilityException = 'get-events-impacted-by-availability-exception',
  GetTeacherAvailablityExceptionDrafts = 'get-teacher-availablity-exception-drafts',
  GetAssessmentBook = 'get-assessment-book',
  SearchStudentTeacherPMAs = 'search-student-teacher-pmas',
  searchPMAUnitSubmissionData = 'search-pma-unit-submission-data',
  GetStudentProgressMonitoringAssessments = 'get-student-progress-monitoring-assessments',
  GetStudentProgressMonitoringAssessmentDetails = 'get-student-progress-monitoring-assessment-details',
  PMABookComprehensionQuestions = 'pma-book-comprehension-questions',
  GetStudentsRequiringGrade = 'get-students-requiring-grade',
  GetStudentGrade = 'get-student-grade',
  GetStudentHootLevels = 'get-student-hoot-levels',
}
