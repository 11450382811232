import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ScheduledLesson } from '@hoot/events/interfaces/scheduled-lesson';
import useLessonMessageHandler from '@hoot/hooks/lessons/useLessonMessageHandler';
import { useInLessonLogger } from '@hoot/hooks/useInLessonLogger';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { useReaders } from '@hoot/redux/reducers/readingSlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import CelebrationsDisplay from '@hoot/ui/components/v2/core/CelebrationsDisplay';
import FocusBlade from '@hoot/ui/components/v2/library/FocusBlade';
import TeacherBlade from '@hoot/ui/components/v2/teacher-blade/TeacherBlade';
import useTeacherLessonController from '@hoot/ui/pages/v2/teacher/lesson/useTeacherLessonController';
import useHRAMessageHandler from '../../../../../hooks/hra/useHRAMessageHandler';
import usePMAMessageHandler from '../../../../../hooks/pma/usePMAMessageHandler';

/**
 * Wrapped for all routes considered to be in a lesson.
 *
 * NOTE: You can't just land on this page without having gone through the "Get Ready" modal first.
 * Attempting to manually navigate to this page should end up redirecting the user back to the home page.
 */

interface TeacherInLessonLayoutProps {
  inLesson: ScheduledLesson;
}

const TeacherInLessonLayout = (props: TeacherInLessonLayoutProps) => {
  const { inLesson } = props;
  const lessonId = inLesson.lessonId;

  // Log stuff.
  useInLessonLogger(inLesson?.startsAt);

  // Listen for incoming lesson messages.
  useLessonMessageHandler();

  // Listen for incoming PMA messages
  usePMAMessageHandler();

  // Listen for incoming HRA messages
  useHRAMessageHandler();

  // Lesson message publisher.
  const lessonController = useTeacherLessonController({ activeLesson: inLesson! });

  const navigate = useNavigate();

  const { inLessonReader } = useReaders();
  const { activePage, activeWhiteboardId } = useActiveLessonState();
  const { book } = inLessonReader;
  const activeBookId = book?.id;

  // Load the lesson state on page-load.
  useEffect(() => {
    lessonController.refreshLessonState();

    // Publish leave-lesson event when this page unmounts.
    return () => {
      lessonController.leaveLesson();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the lesson's "active page" changes, then we also need to change the current path.
  useEffect(() => {
    // When the active page changes, then redirect to new active page.
    switch (activePage) {
      case ActiveLessonPage.Library:
        navigate(routesDictionary.lesson.library.url(lessonId), { replace: true });
        return;
      case ActiveLessonPage.PMA:
        navigate(routesDictionary.lesson.progressMonitoringAssessment.url(lessonId), { replace: true });
        return;
      case ActiveLessonPage.HRA:
        navigate(routesDictionary.lesson.hootReadingAssessment.url(lessonId), { replace: true });
        return;
      case ActiveLessonPage.Reader:
        if (activeBookId) {
          navigate(routesDictionary.lesson.library.book.url(lessonId!, activeBookId), { replace: true });
        }
        return;
      case ActiveLessonPage.Whiteboard:
        navigate(routesDictionary.lesson.whiteboard.url(lessonId), { replace: true });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, activeBookId, lessonId]);

  return (
    <>
      <Stack direction="row" height="100%" overflow="hidden">
        <TeacherBlade />
        <Stack
          position="relative"
          direction="column"
          justifyContent="center"
          flex={1}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          {!!inLesson && <Outlet />}
        </Stack>

        {activeBookId && activeWhiteboardId && <FocusBlade />}
      </Stack>
      <CelebrationsDisplay />
    </>
  );
};

const Wrapper = () => {
  const { inLesson } = useActiveLessonState();
  const lessonId = inLesson?.lessonId;

  const navigate = useNavigate();

  // If we're not actually in a lesson, then redirect back to the home page.
  useEffect(() => {
    if (!lessonId) {
      navigate(routesDictionary.home.url, { replace: true });
      return;
    }
  }, [lessonId, navigate]);

  return <>{inLesson && <TeacherInLessonLayout inLesson={inLesson} />}</>;
};

export default Wrapper;
