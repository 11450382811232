import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InstructionIndicatorThreshold, Unfurl } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { AssessmentUnitSubmission, useSubmitAssessment } from '@hoot/hooks/api/assessment/useSubmitAssessment';
import { StepTestAnswer, UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import QuestionnaireLayout3, { Q3UnitQuestionAnswerPair } from '@hoot/ui/components/v2/assessments/teacher/questionnaires/QuestionnaireLayout3';
import {
  calculateScore,
  calculateUnfurledFieldTransitionScore,
  getInstructionIndicator,
  pmaStepTestAnswerToStepTestAnswerDictionary,
  stepTestAnswerToPmaStepTestAnswerDictionary,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/assessment-utils';
import useTeacherHRAController from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/useTeacherHRAController';

export interface HRAQuestionnaireLayout_3_Props {
  assessmentId: string;
  unitIdentifier:
    | UnitIdentifierHRAV2.U_2_1
    | UnitIdentifierHRAV2.U_2_2
    | UnitIdentifierHRAV2.U_2_3
    | UnitIdentifierHRAV2.U_2_4
    | UnitIdentifierHRAV2.U_2_5
    | UnitIdentifierHRAV2.U_2_6
    | UnitIdentifierHRAV2.U_3_1
    | UnitIdentifierHRAV2.U_3_2
    | UnitIdentifierHRAV2.U_3_3
    | UnitIdentifierHRAV2.U_3_4
    | UnitIdentifierHRAV2.U_3_5
    | UnitIdentifierHRAV2.U_3_6;
  unitId: string;
  unitTitle: string;
  prompts: HRAQuestionnaireLayout_3_Prompt[];
  fieldName: string;
  unfurl: Unfurl | undefined;
  instructionIndicatorThresholds: InstructionIndicatorThreshold[];
  submissionId?: string;
  defaultAnswers?: StepTestAnswer[];
  isPreviousUnitEnabled: boolean;
}

export interface HRAQuestionnaireLayout_3_Prompt {
  fullPrompt: string;
  boldedWord: string;
}

export function HRA_QUESTIONNAIRE_LAYOUT_3(props: HRAQuestionnaireLayout_3_Props) {
  const {
    assessmentId,
    unitId,
    unitIdentifier,
    unitTitle,
    prompts,
    fieldName,
    unfurl,
    submissionId,
    instructionIndicatorThresholds,
    defaultAnswers,
    isPreviousUnitEnabled,
  } = props;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(0);
  const [numQuestionsToShow, setNumQuestionsToShow] = useState<number>(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [questionAnswerPairs, setQuestionAnswerPairs] = useState<Q3UnitQuestionAnswerPair[]>([]);
  const [subsectionInstructions, setSubsectionInstructions] = useState('');

  const { studentId, teacherId } = useSelector((state: RootState) => state.activeLesson.inLesson)!;

  const isFormIncomplete = questionAnswerPairs.slice(0, numQuestionsToShow).some((x) => x.answer === PMAStepTestAnswer.NoAnswer);
  const studentText = !isFormIncomplete || currentQuestionIndex === null ? undefined : questionAnswerPairs[currentQuestionIndex]?.fullPrompt;
  const hasUnitBeenSubmitted = !!submissionId;

  const dispatch = useAppDispatch();
  const hraController = useTeacherHRAController();
  const submitUnitRequest = useSubmitAssessment(assessmentId, unitId);

  const resetForm = useCallback(() => {
    let questionAnswerPairs = prompts.map<Q3UnitQuestionAnswerPair>((p, i) => ({
      // The index to display beside a question in the scorecard. Note: example questions will have display an index.
      displayIndex: i + 1,
      fullPrompt: p.fullPrompt,
      wordPrompt: p.boldedWord,
      // Overly complicated b/c these StepTest enums are duplicated and just slightly different.
      answer: defaultAnswers
        ? (stepTestAnswerToPmaStepTestAnswerDictionary[defaultAnswers[i]] ?? PMAStepTestAnswer.NoAnswer)
        : PMAStepTestAnswer.NoAnswer,
    }));
    if (unfurl?.exampleQuestion) {
      questionAnswerPairs.splice(unfurl.exampleQuestion.atIndex, 0, {
        displayIndex: null,
        // If this unit was already submitted, then set this example Q as CORRECT by default.
        answer: submissionId ? PMAStepTestAnswer.Correct : PMAStepTestAnswer.NoAnswer,
        helpText: unfurl.exampleQuestion.helperText,
        fullPrompt: unfurl.exampleQuestion.prompt,
        wordPrompt: unfurl.exampleQuestion.prompt,
        isExampleQuestion: true,
      });
    }

    const numQuestionsToShow = calcNumQuestionsToShow(questionAnswerPairs, unfurl);

    setQuestionAnswerPairs(questionAnswerPairs);
    setNumQuestionsToShow(numQuestionsToShow);
    setCurrentQuestionIndex(submissionId ? null : 0);
    setIsFormDirty(false);
    setSubsectionInstructions(unfurl?.segments?.[0]?.subtitle ?? '');
  }, [prompts, defaultAnswers, unfurl, setQuestionAnswerPairs, setCurrentQuestionIndex, setSubsectionInstructions, submissionId]);

  // When the unit has changed, then reset the form.
  useEffect(() => {
    resetForm();
  }, [unitId, resetForm]);

  // When the current question changes, we need to tell the student what to see.
  useEffect(() => {
    if (studentText) {
      hraController.onSetText(studentText);
    }
  }, [studentText]);

  const onNextUnit = () => {
    hraController.onNextUnit();
  };

  const onSubmitUnit = () => {
    // Map the form answers to values the API expects.
    const answers = questionAnswerPairs
      // Filter out example questions.
      .filter((x) => !x.isExampleQuestion)
      .map<StepTestAnswer>((x) => pmaStepTestAnswerToStepTestAnswerDictionary[x.answer]);

    // Need to blank out all answers after the last shown question.
    answers.fill(StepTestAnswer.NoAnswer, numQuestionsToShow);

    const unitSubmissionData = {
      [fieldName]: answers,
    };
    const score = calculateScore(answers);
    const instructionIndicator = getInstructionIndicator(score, instructionIndicatorThresholds);

    const submitData: AssessmentUnitSubmission = {
      submissionId: submissionId,
      studentProfileId: studentId,
      teacherAccountId: teacherId,
      score: score,
      instructionIndicator: instructionIndicator?.instructionLevel,
      submissionData: unitSubmissionData,
      transitionScore: unfurl ? calculateUnfurledFieldTransitionScore(unfurl, answers) : score,
    };
    submitUnitRequest.mutate(submitData, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: `Unit ${unitTitle} successfully submitted.` }));
        setIsFormDirty(false);
        onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: `Oops! There was a problem submitting unit ${unitTitle}.` }));
      },
    });
  };

  const onNavToPreviousUnit = () => {
    hraController.onPrevUnit();
  };

  const onSelectQuestionAtIndex = (index: number | null) => {
    setCurrentQuestionIndex(index);

    // If we have a non-null Q index, then update the subsection instructions (they can change based on what Q we're looking at).
    // If no Q is selected, then do nothing. Just keep showing the instructions of the last Q.
    if (index !== null) {
      updateSubsectionInstructionsForQuestionIndex(index);
    }
  };

  const updateSubsectionInstructionsForQuestionIndex = (currentQuestionIndex: number) => {
    // These HRA units _should_ have an `unfurl` object.
    if (!unfurl) {
      return '';
    }
    for (const x of unfurl.segments) {
      if (currentQuestionIndex < x.end) {
        setSubsectionInstructions(x.subtitle);
        break;
      }
    }
    // Failsafe.
    return unfurl.segments[0]?.subtitle ?? '';
  };

  const onQuestionAnswered = (questionIndex: number, answer: PMAStepTestAnswer) => {
    // If the new answer is the same as the previous answer, then we don't need to do anything.
    if (questionAnswerPairs[questionIndex].answer === answer) {
      return;
    }
    // Make a copy of all prev answers.
    let updatedQAPairs = [...questionAnswerPairs];

    // Set the answer for the question we're looking at.
    updatedQAPairs[questionIndex].answer = answer;

    // Figure out how many questions we can now show based on this unit's unfurl logic.
    const updatedNumQuestionsToShow = calcNumQuestionsToShow(updatedQAPairs, unfurl);

    // Trim off any answers that comes after the last displayed question.
    updatedQAPairs = updatedQAPairs.map<Q3UnitQuestionAnswerPair>((x, i) => {
      return {
        ...x,
        answer: i >= updatedNumQuestionsToShow ? PMAStepTestAnswer.NoAnswer : x.answer,
      };
    });

    // Apply everything.
    setQuestionAnswerPairs(updatedQAPairs);
    setIsFormDirty(true);
    setNumQuestionsToShow(updatedNumQuestionsToShow);

    // When all Qs have been answered then the student should see the "way to go" screen.
    // On the contrary, if the form _was_ complete, and then becomes incomplete, then the student should see the current/selected Q.
    const _isFormIncomplete = updatedQAPairs.slice(0, updatedNumQuestionsToShow).some((x) => x.answer === PMAStepTestAnswer.NoAnswer);
    if (_isFormIncomplete !== isFormIncomplete) {
      const isUnitCompleted = !_isFormIncomplete;
      hraController.onUnitComplete(isUnitCompleted);
    }
  };

  return (
    <QuestionnaireLayout3
      title="Hoot Reading Assessment"
      unitIdentifier={unitIdentifier}
      unitTitle={unitTitle}
      instruction={subsectionInstructions}
      instructionsExpandedDefault={true}
      isFormIncomplete={isFormIncomplete}
      currentQuestionIndex={currentQuestionIndex}
      onSelectQuestionAtIndex={onSelectQuestionAtIndex}
      questionAnswerPairs={questionAnswerPairs}
      onQuestionAnswered={onQuestionAnswered}
      isFormDirty={isFormDirty}
      isSubmitting={submitUnitRequest.isLoading}
      onNavToPreviousUnit={onNavToPreviousUnit}
      onSubmit={onSubmitUnit}
      onNext={onNextUnit}
      hasUnitBeenSubmitted={hasUnitBeenSubmitted}
      isNextUnitEnabled={true}
      isPreviousUnitEnabled={isPreviousUnitEnabled}
      numQuestionsToShow={numQuestionsToShow}
      onExitAssessment={hraController.onExitAssessment}
    />
  );
}

export const calcNumQuestionsToShow = (_questionAnswerPairs: Q3UnitQuestionAnswerPair[], unfurl?: Unfurl) => {
  if (!unfurl) {
    return _questionAnswerPairs.length;
  }
  // Filter out example questions; the unfurl logic doesn't take this into account.
  const actualQuestions = _questionAnswerPairs.filter((x) => !x.isExampleQuestion);

  const { segments, whenCorrect, exampleQuestion } = unfurl;

  let segmentIndex = 0;

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const { start, end } = segment;

    // FYI: This is a bit of a hack, but it's due to the unfurl data not being consistent.
    // Example segments: [{"start":0,"end":5,"amount":5},{"start":6,"end":10,"amount":5,},{"start":11,"end":20,"amount":10,}]}
    // To determine the actual `start` question index, you need to subtract 1, but the initial start value is at 0 meaning
    // the initial start index would be -1 which isn't right. In this case, we will just default to 0.
    const actualStart = start - 1 < 0 ? 0 : start - 1;
    const questionAnswerSlice = actualQuestions.slice(actualStart, end);
    const numCorrectAnswersInSlice = questionAnswerSlice.filter((x) => x.answer === PMAStepTestAnswer.Correct).length;

    if (numCorrectAnswersInSlice >= whenCorrect) {
      segmentIndex += 1;
    } else {
      // Don't need to continue.
      break;
    }
  }
  const numQuestionsToShow = segments.slice(0, segmentIndex + 1).reduce((a, b) => a + b.amount, 0);

  return exampleQuestion && numQuestionsToShow > exampleQuestion.atIndex ? numQuestionsToShow + 1 : numQuestionsToShow;
};
