import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { InstructionIndicatorThreshold, Unfurl } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';

export function calculateUnfurledFieldTransitionScore(unfurl: Unfurl, answers: StepTestAnswer[]) {
  return unfurl.segments.reduce((a, b, idx) => {
    const start = b.start > 0 ? b.start - 1 : b.start;
    const end = b.end;
    const correctAnswers = answers.slice(start, end).filter((answer) => answer === StepTestAnswer.Correct).length;
    return a + correctAnswers * Math.pow(10, idx);
  }, 0);
}

export function calculateScore(answers: StepTestAnswer[]) {
  let score = 0;
  for (const answer of answers) {
    if (answer === StepTestAnswer.Correct) {
      score = score + 1;
    }
  }
  return score;
}

export function getInstructionIndicator(score: number, instructionIndicatorThresholds: InstructionIndicatorThreshold[]) {
  return instructionIndicatorThresholds.find((threshold) => {
    return threshold.initial <= score && score <= threshold.end;
  });
}

// We have the same damn enums defined twice, except one is barely different...
export const pmaStepTestAnswerToStepTestAnswerDictionary = {
  [PMAStepTestAnswer.Correct]: StepTestAnswer.Correct,
  [PMAStepTestAnswer.Wrong]: StepTestAnswer.Wrong,
  [PMAStepTestAnswer.NoAnswer]: StepTestAnswer.NoAnswer,
};
export const stepTestAnswerToPmaStepTestAnswerDictionary = {
  [StepTestAnswer.Correct]: PMAStepTestAnswer.Correct,
  [StepTestAnswer.Wrong]: PMAStepTestAnswer.Wrong,
  [StepTestAnswer.NoAnswer]: PMAStepTestAnswer.NoAnswer,
};
