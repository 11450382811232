import { Box } from '@mui/system';
import { hootTokens } from '../../../../theme/v2/tokens';

export const AssessmentImage = (imgProps: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <Box
      draggable={false}
      component="img"
      sx={{
        display: 'block',
        objectFit: 'contain',
        WebkitTouchCallout: 'none', // disabling the save/copy option menu for images when holding your finger on it
        boxShadow: hootTokens.elevation.elevation1,
        borderRadius: '10px',
        maxWidth: '100%',
        maxHeight: '100%',
        // A note about how these images render:
        // The book page (AKA the image of the book page) needs to _only_ fill as much space as it needs for its
        // width, but must fill the height of its parent container. This will ensure that, regardless of window
        // dimensions, users are _always_ seeing the same scaled images with the correct aspect ratio. This is ultra
        // important b/c we need to ensure that the magic finger coordinates always point "at the same spot" for
        // both users in a lesson (the magic finger container wraps the visible pages (one or two pages depending on
        // if we're in single-page mode or double-page mode).
        width: 'auto',
        height: 'auto',
        backgroundColor: '#FFF',
      }}
      {...imgProps}
    />
  );
};
