import { SvgIcon, SvgIconProps } from '@mui/material';

const PinIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="7" height="16" viewBox="0 0 7 16" fill="none" {...props}>
      <path
        d="M3.5 0.333333C2.02724 0.333333 0.833333 1.52724 0.833333 3C0.833333 4.47276 2.02724 5.66667 3.5 5.66667C4.97276 5.66667 6.16667 4.47276 6.16667 3C6.16667 1.52724 4.97276 0.333333 3.5 0.333333ZM3 3L3 16L4 16L4 3L3 3Z"
        fill={props.fill ?? '#000'}
      />
    </SvgIcon>
  );
};

export default PinIcon;
