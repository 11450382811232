import { Stack } from '@mui/material';
import { Button } from '../../core/Button';
import { Icon } from '../../core/Icon';

interface Props {
  allFormFieldsFilledOut: boolean;
  onPreviousUnitClick?: () => void;
  isUnitQuestionnaireDirty: boolean;
  setLeaveDialog: (val: boolean) => void;
  onNextUnitAndSubmissionClick: () => void;
  disableSubmissionBtn: boolean;
  isSubmitting?: boolean;
  submitButtonLabel?: string;
  prevButtonLabel?: string;
  isNextEnabled?: boolean;
  isPrevEnabled?: boolean;
}

export function QuestionnaireFooter(props: Props) {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ borderTop: '1px solid #D3D3D3', paddingTop: 3, marginTop: '16px' }}>
      <div style={{ display: 'flex', cursor: props.isPrevEnabled ? 'default' : 'not-allowed' }}>
        <Button
          variant="contained"
          startIcon={<Icon name="arrow_icon" color="primary.200" rotate="180deg" />}
          size="small"
          disabled={!props.isPrevEnabled}
          onClick={props.onPreviousUnitClick}
        >
          {props.prevButtonLabel ?? 'Previous Unit'}
        </Button>
      </div>
      <div style={{ display: 'flex', cursor: !props.allFormFieldsFilledOut ? 'not-allowed' : 'default' }}>
        <Button
          variant="contained"
          startIcon={<Icon name="arrow_icon" color="primary.200" />}
          size="small"
          disabled={props.disableSubmissionBtn}
          isLoading={props.isSubmitting}
          onClick={props.onNextUnitAndSubmissionClick}
        >
          {props.submitButtonLabel ? props.submitButtonLabel : <>Submit and {props.isNextEnabled ? 'Continue' : 'Close'}</>}
        </Button>
      </div>
    </Stack>
  );
}
