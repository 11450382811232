import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import activeLessonReducer from './reducers/activeLessonSlice';
import alertReducer from './reducers/alertSlice';
import applicationReducer from './reducers/applicationSlice';
import debugReducer from './reducers/debugSlice';
import districtRepReducer from './reducers/districtRepSlice';
import flashMessageReducer from './reducers/flashMessageSlice';
import hraReducer from './reducers/hraSlice';
import libraryReducer from './reducers/librarySlice';
import modalsReducer from './reducers/modalsSlice';
import notificationReducer from './reducers/notificationsSlice';
import pmaReducer from './reducers/pmaSlice';
import profileReducer, { hydrateProfile } from './reducers/profileSlice';
import readingReducer from './reducers/readingSlice';
import upcomingLessonsReducer from './reducers/upcomingLessonsSlice';
import whiteboardReducer from './reducers/whiteboardSlice';

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    upcomingLessons: upcomingLessonsReducer,
    activeLesson: activeLessonReducer,
    libraries: libraryReducer,
    profile: profileReducer,
    readers: readingReducer,
    modals: modalsReducer,
    debug: debugReducer,
    alert: alertReducer,
    flashMessage: flashMessageReducer,
    notifications: notificationReducer,
    districtRep: districtRepReducer,
    whiteboard: whiteboardReducer,
    pma: pmaReducer,
    hra: hraReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

store.subscribe(() => {
  const currentState = store.getState();
  if (currentState.application.isConnected === true) {
    localStorage.setItem('REDUX_STATE', JSON.stringify(currentState));
  }
});

const restoreStateFromLocalStorage = () => {
  const persistedState = localStorage.getItem('REDUX_STATE');
  if (persistedState) {
    const stateTree = JSON.parse(persistedState) as RootState;
    store.dispatch(hydrateProfile({ ...stateTree.profile }));
  }
};

restoreStateFromLocalStorage();

export type AppDispatch = ThunkDispatch<RootState, null, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
