import { Dialog, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@hoot/redux/store';
import { Button } from '../core/Button';
import HootTypography from '../core/HootTypography';
import Lottie, { LottieFile } from '../lottie/Lottie';

interface Props {
  isOpen: boolean;
  isResumingAssessment: boolean;
  onOpenHra: () => void;
  onDismiss: () => void;
}

const HRANotificationDialog = (props: Props) => {
  const { isOpen, isResumingAssessment, onOpenHra, onDismiss } = props;
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const _onOpenHra = () => {
    onOpenHra();
    onDismiss();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <Stack direction="column" alignItems="center" padding={3} sx={{ overflow: 'auto' }}>
        <Stack direction="row" justifyContent="space-evenly" width={'100%'}>
          <Stack direction="row" justifyContent="center" flex={3}>
            <HootTypography isPII variant="displaysmall">
              {inLesson?.studentName}
            </HootTypography>
          </Stack>
        </Stack>
        <HootTypography isPII={false} variant="headlinemedium" textAlign="center">
          Requires a Hoot Reading Assessment
        </HootTypography>
        <img src="/images/assessment-clipboard.png" alt="logo" height="355px" width="259px" />

        <Stack rowGap={2} width="100%" justifyContent="center">
          <Button
            onClick={_onOpenHra}
            variant="contained"
            color="success.80"
            fullWidth
            sx={{
              height: '120px',
              color: '#FFF',
            }}
          >
            <Stack alignItems="center" gap={1}>
              <Lottie
                lottieFile={LottieFile.Rocket}
                loop={true}
                play={true}
                style={{
                  width: '64px',
                  height: '64px',
                }}
              />
              <HootTypography variant="titlelarge" isPII={false}>
                {`${isResumingAssessment ? 'Resume' : 'Begin'} Assessment`}
              </HootTypography>
            </Stack>
          </Button>

          <Button onClick={onDismiss} variant="outlined" fullWidth>
            Return to Library
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default HRANotificationDialog;
