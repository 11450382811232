import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Card, Collapse } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import useGetStudentProfileV2 from '../../../../../hooks/api/user/useGetStudentProfileV2';
import { useActiveLessonState } from '../../../../../redux/reducers/activeLessonSlice';
import { createFlashMessage } from '../../../../../redux/reducers/flashMessageSlice';
import { useAppDispatch } from '../../../../../redux/store';
import TeacherInLessonReaderPage from '../../../../pages/v2/teacher/lesson/library/reader/TeacherInLessonReaderPage';
import StudentGradeConfirmationDialog from '../../../../pages/v2/teacher/my-students/student-details/assessments/StudentGradeConfirmationDialog';
import { Button } from '../../core/Button';
import HootTypography from '../../core/HootTypography';
import { Icon } from '../../core/Icon';
import { QuestionnaireToolsMenu } from './QuestionnaireToolsMenu';
import { LeaveAssessmentDialog } from './dialogs/LeaveAssessmentDialog';

export function QuestionnaireContainer(props: {
  title: string;
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  unitTitle: string;
  children: React.ReactNode;
  showBook?: boolean;
  instruction: string;
  additionalInstruction?: React.ReactNode;
  instructionsExpandedDefault?: boolean;
  onExitAssessment: () => void;
}) {
  const { lessonId } = useParams();
  const { inLesson } = useActiveLessonState();

  const dispatch = useAppDispatch();

  const { data: student } = useGetStudentProfileV2(inLesson?.studentId ?? '', {
    enabled: !!inLesson?.studentId,
    onSuccess: (student) => {
      // If we don't have student grade info, then we need to ask for it.
      if (!student.grade) {
        setPromptStudentGradeDialog(true);
      }
    },
    onError: (ex) => {
      console.error(ex);
      dispatch(
        createFlashMessage({
          message: 'An error occurred while attempting to fetch the student data',
          variant: 'error',
          title: undefined,
          anchorOrigin: undefined,
          isIndefinite: undefined,
        }),
      );
    },
  });

  const [promptStudentGradeDialog, setPromptStudentGradeDialog] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleConfirmLeave = () => {
    setShowConfirmDialog(false);
    if (lessonId) {
      props.onExitAssessment();
    }
  };

  const handleShowConfirmationClick = (showConfirmation: boolean) => {
    setShowConfirmDialog(showConfirmation);
  };

  const onCloseDialog = () => {
    setPromptStudentGradeDialog(false);
    handleConfirmLeave();
  };

  const onConfirmDialog = () => {
    setPromptStudentGradeDialog(false);
  };

  if (!student || !inLesson) {
    return null;
  }

  return (
    <Stack justifyContent="flex-start">
      <Stack justifyContent="flex-start">
        <Card elevation={5}>
          <Stack sx={{ height: '100%', gap: 1, justifyContent: 'flex-start', alignItems: 'left', paddingBottom: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <HootTypography isPII={false} variant="titlelarge">
                {props.title}
              </HootTypography>
              <Stack direction="row">
                <QuestionnaireToolsMenu onShowConfirmationClick={handleShowConfirmationClick} currentUnitId={props.unitIdentifier} />
              </Stack>
            </Stack>
            <HootTypography isPII={false} variant="bodylarge">
              {props.unitTitle}
            </HootTypography>
            <Instructions
              instruction={props.instruction}
              additionalInstruction={props.additionalInstruction}
              defaultExpanded={props.instructionsExpandedDefault}
            />
            {props.children}
          </Stack>
        </Card>
      </Stack>
      {props.showBook ? (
        <Stack sx={{ maxHeight: 'calc(100vh - 64px)' }}>
          <TeacherInLessonReaderPage />
        </Stack>
      ) : null}
      <LeaveAssessmentDialog open={showConfirmDialog} setOpen={setShowConfirmDialog} confirmLeave={handleConfirmLeave} />
      <StudentGradeConfirmationDialog
        show={promptStudentGradeDialog}
        onConfirm={onConfirmDialog}
        onDismiss={onCloseDialog}
        studentName={student.name}
        studentId={student.id}
        studentGrade={student.grade ?? null}
      />
    </Stack>
  );
}

function Instructions(props: { instruction: string; additionalInstruction?: React.ReactNode; defaultExpanded?: boolean }) {
  const [openInstructions, setOpenInstructions] = useState<boolean>(props.defaultExpanded ?? false);

  const handleClick = () => {
    setOpenInstructions((prevVal) => !prevVal);
  };

  return (
    <>
      <Button
        startIcon={
          openInstructions ? (
            <Icon name="collapse_filled" sx={{ width: 200, height: 20 }} />
          ) : (
            <Icon name="solid_add_rectangle" htmlColor="primary.0" />
          )
        }
        onClick={handleClick}
        sx={{ width: '150px', gap: '8px' }}
      >
        <HootTypography isPII={false} variant="titlesmall">
          Instructions
        </HootTypography>
      </Button>
      <Collapse in={openInstructions} timeout="auto" unmountOnExit>
        <HootTypography isPII={false} variant="bodylarge" dangerouslySetInnerHTML={{ __html: props.instruction }} />
        {props.additionalInstruction}
      </Collapse>
    </>
  );
}
