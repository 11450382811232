import { Menu, MenuItem } from '@mui/material';
import { StudentTimelineRequest } from '@hoot/hooks/api/my-student/getStudentTimeline';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { eventVisibilityLabels } from './common';
import { EventVisibilityOption } from './enums';

export type EventOptionsType = {
  [key in EventVisibilityOption]: boolean;
};

interface EventVisibilityMenuProps {
  anchorElement: HTMLButtonElement | null;
  onDismiss: () => void;
  eventOptions: EventOptionsType;
  setEventOptions: React.Dispatch<React.SetStateAction<EventOptionsType>>;
  setQuery: React.Dispatch<React.SetStateAction<StudentTimelineRequest>>;
}

const FocusVisibilityMenu = (props: EventVisibilityMenuProps) => {
  const handleToggle = (option: EventVisibilityOption) => {
    const currentEventOptions = props.eventOptions;
    const updatedEventOptions = { ...currentEventOptions, [option]: !currentEventOptions[option] };
    props.setEventOptions(updatedEventOptions);

    const eventVisibilityOptions = Object.keys(updatedEventOptions).filter(
      (key) => updatedEventOptions[key as EventVisibilityOption] === true,
    ) as EventVisibilityOption[];
    props.setQuery((query) => ({
      ...query,
      eventVisibility: eventVisibilityOptions,
    }));
  };

  return (
    <Menu id="event-visibility-menu" open={!!props.anchorElement} anchorEl={props.anchorElement} onClose={props.onDismiss}>
      {Object.values(EventVisibilityOption).map((option) => (
        <MenuItem key={option}>
          <Checkbox label={eventVisibilityLabels[option]} checked={props.eventOptions[option]} onChange={() => handleToggle(option)} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FocusVisibilityMenu;
