import { EventType } from '@hoot/events/eventType';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { useAppDispatch } from '@hoot/redux/store';
import { HRASyncMessage } from '../../events/messages/hra-sync.message';
import { handleSyncHRA } from '../../redux/reducers/hraSlice';

const useHRAMessageHandler = () => {
  const dispatch = useAppDispatch();

  // Refresh lesson response handler
  useSocketSubscription<HRASyncMessage>(EventType.HRASyncResponse, {
    onEventReceived: (response) => {
      dispatch(handleSyncHRA(response));
    },
  });
};

export default useHRAMessageHandler;
