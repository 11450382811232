import { CancellationReason, ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';

export const lessonStatusesForCustomersLookup: Record<ScheduledLessonStatus, string> = {
  SCHEDULED: 'Scheduled',
  OPEN: 'Open',
  OPEN_WAITING_STUDENT: 'Waiting on Student',
  OPEN_WAITING_TEACHER: 'Open',
  IN_PROGRESS: 'In Progress',
  CANCELLED: 'Cancelled',
  COMPLETED_SUCCESSFULLY: 'Completed',
  COMPLETED_UNSUCCESSFULLY: 'Completed',
  RESCHEDULED: 'Cancelled',
};

// NOTE: This isn't the same mapping as our standard cancellationReasonLookup
// in hoot-core - it is only used in this component and was spec'd in sc-11422 &
// https://docs.google.com/spreadsheets/d/1JfRhAjQcYlzy1QVfITvXTEMvr1aNkVdLf3t445Yy5v4/edit?gid=1044740678#gid=1044740678
export const cancellationReasonMessageLookup: Record<CancellationReason, string> = {
  HOOT_CLOSURE: 'Hoot Closure',
  TEACHER_NO_SHOW: 'Teacher Unavailable',
  TEACHER_UNAVAILABLE_BELOW_NOTICE: 'Teacher Unavailable',
  NO_ONE_JOINED: 'Teacher Unavailable',
  SUBSTITUTION: 'Other',
  OTHER: 'Other',
  TECH_DISRUPTION: 'Other',
  TEACHER_UNAVAILABLE_ABOVE_NOTICE: 'Schedule Change',
  STUDENT_UNAVAILABLE_ABOVE_NOTICE: 'Schedule Change',
  PARENT_CHANGE_REQUEST: 'Schedule Change',
  MEMBERSHIP_LAPSE: 'Schedule Change',
  NO_SUB_FOUND: 'Schedule Change',
  UNSUCCESSFUL_PAYMENT: 'Schedule Change',
  SCHEDULING_ERROR: 'Schedule Change',
  STUDENT_UNAVAILABLE_BELOW_NOTICE: 'Student Unavailable',
  STUDENT_ABSENCE: 'Student Unavailable',
  SITE_CLOSURE: 'Student Unavailable',
};
