import { Box, SvgIcon } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface SVGTextComponentProps {
  text: string;
  isLetterUnit?: boolean;
}

const AssessmentTextUnit = (props: SVGTextComponentProps) => {
  const { text, isLetterUnit } = props;
  return (
    <>
      <SvgIcon
        width="682"
        height="682"
        viewBox="0 0 682 682"
        preserveAspectRatio="xMinYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          boxShadow: hootTokens.elevation.elevation1,
          borderRadius: '10px',
          width: 'auto',
          height: 'auto',
        }}
      >
        <foreignObject x="0%" y="0%" width="100%" height="100%">
          <Box
            sx={{
              padding: 3,
              height: '100%',
              backgroundColor: '#FFF',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: isLetterUnit ? '300px' : '60px', // Verifies the desired font sizes
                textTransform: isLetterUnit ? 'lowercase' : undefined,
                color: 'black',
                textAlign: 'center',
                wordWrap: 'break-word',
              }}
            >
              {text}
            </Box>
          </Box>
        </foreignObject>
      </SvgIcon>
    </>
  );
};

export default AssessmentTextUnit;
