import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface StudentHootLevelsResponse {
  moduleOneInProgress: boolean;
  moduleTwoInProgress: boolean;
  moduleThreeInProgress: boolean;
  hasAttemptedTextLanguage: boolean;
  hasAttemptedTextReading: boolean;
}

export async function getStudentHootLevels(studentProfileId: string) {
  const { data } = await axios.get<StudentHootLevelsResponse>(`${config.apiUrl}/my-students/${studentProfileId}/hoot-levels`);
  return data;
}

export default function useGetStudentHootLevels(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentHootLevelsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentHootLevels, studentProfileId], () => getStudentHootLevels(studentProfileId), options);
}
