import { DailyVideo, useAudioTrack, useMeetingSessionState, useParticipantIds, useVideoTrack } from '@daily-co/daily-react';
import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Box, Fade, Stack, StackProps, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TEACHER_VIDEO_HEIGHT, TEACHER_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { zIndexes } from '@hoot/constants/zIndices';
import { DailyMediaState, useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import StudentPMAPage from '@hoot/ui/pages/v2/student/progress-monitoring-assessment/StudentPMAPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { ActiveLessonPage } from '../../../../models/api/enums/active-lesson-page-enum';
import { UnitIdentifierHRAV2 } from '../../../../models/api/enums/hoot-reading-assessment';
import StudentHRAPage from '../../../pages/v2/student/hoot-reading-assessment/StudentHRAPage';
import { FloatingDialog } from '../core/FloatingDialog';
import HootTypography from '../core/HootTypography';
import { Icon } from '../core/Icon';
import DailyAudioVisualizer from '../video/DailyAudioVisualizer';
import { CameraButton, CameraOff, LockButton, MicButton } from './ui-components/VideoComponents';

const pmaUnitsNotPreviewed = [PMAUnitIdentifier.Module_4_Unit_1, PMAUnitIdentifier.Module_4_Unit_3, PMAUnitIdentifier.Module_4_Unit_4];
const hraUnitsNotPreviewed = [UnitIdentifierHRAV2.U_4_1, UnitIdentifierHRAV2.U_4_3, UnitIdentifierHRAV2.U_4_4];

const RemoteStudentTile = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const remoteSessionId = remoteParticipantIds.length > 0 ? remoteParticipantIds[0] : null;
  const remoteVideoTrack = useVideoTrack(remoteSessionId ?? '');
  const remoteAudioTrack = useAudioTrack(remoteSessionId ?? '');

  const studentName = useSelector((state: RootState) => state.activeLesson.inLesson?.studentName);
  const studentFirstName = studentName?.split(' ')?.[0] ?? '';
  const { book } = useSelector((state: RootState) => state.readers.inLessonReader);

  const activeLessonPage = useSelector((state: RootState) => state.activeLesson.activePage);
  const currentPmaUnit = useSelector((state: RootState) => state.pma.currentUnit);
  const currentHraUnit = useSelector((state: RootState) => state.hra.hra?.currentUnitIdentifier);

  const isOnStudentLibrary = useRouteMatch([`${routesDictionary.myStudents.details.library.absolutePath}`]);

  const meetingSessionState = useMeetingSessionState<DailyMediaState>();
  const { toggleMicrophone, toggleCamera, toggleLock } = useStudentMediaState();

  const isVideoOn = !remoteVideoTrack.isOff;
  const isMicrophoneOn = !remoteAudioTrack.isOff;
  const isStudentLocked = meetingSessionState.data?.studentIsLocked;

  const [isStudentPreviewScreenExpanded, setIsStudentPreviewScreenExpanded] = useState(false);

  const onShowPreviewDialog = () => setIsStudentPreviewScreenExpanded(true);
  const onDismissPreviewDialog = () => setIsStudentPreviewScreenExpanded(false);

  // Show the preview thumbnail if...
  const showStudentScreenThumbnail =
    // The student preview _dialog_ is not shown, AND
    !isStudentPreviewScreenExpanded &&
    // We're on the PMA page, and we're on a PMA unit that isn't showing the reader, OR
    ((activeLessonPage === ActiveLessonPage.PMA && !pmaUnitsNotPreviewed.some((x) => x === currentPmaUnit)) ||
      // We're on the HRA page, and we're on an HRA unit that isn't showing the reader.
      (activeLessonPage === ActiveLessonPage.HRA && !hraUnitsNotPreviewed.some((x) => x === currentHraUnit)));

  // In the case of the preview screen, we want it to render w/ square screen dimensions, and as the size width as the video feed.
  const previewScreenSize = TEACHER_VIDEO_WIDTH;

  return (
    <Stack
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation5,
        backgroundColor: isStudentLocked ? hootTokens.palette.warning[180] : hootTokens.palette.white,
        outline: isStudentLocked ? `solid 1px ${hootTokens.palette.warning[100]}` : 'none',
        zIndex: !!isOnStudentLibrary && !!book ? zIndexes.video : undefined,
        gap: 1,
      }}
    >
      <HootTypography isPII variant="titlesmall" textAlign="center">
        {studentName}
      </HootTypography>

      {remoteSessionId && (
        <Box
          sx={{
            position: 'relative',
            width: TEACHER_VIDEO_WIDTH,
            height: TEACHER_VIDEO_HEIGHT,
          }}
        >
          {remoteVideoTrack.isOff ? (
            <>
              <CameraOff variant={showStudentScreenThumbnail ? 'bodysmall' : 'bodymedium'} />
            </>
          ) : (
            <>
              <DailyVideo
                sessionId={remoteSessionId ?? ''}
                type={'video'}
                automirror={true}
                autoPlay={true}
                fit="cover"
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  height: '100%',
                }}
              />
              <Box position="absolute" top={showStudentScreenThumbnail ? 0 : 8} right={8}>
                {isVideoOn && <DailyAudioVisualizer audioTrack={remoteAudioTrack} />}
              </Box>
            </>
          )}
        </Box>
      )}
      <Stack justifyContent="center" direction="row" columnGap={1}>
        <Tooltip title={`Re-enabling student's video is disabled`}>
          <Box>
            <CameraButton disabled={!isVideoOn} isOn={isVideoOn} onClick={toggleCamera} />
          </Box>
        </Tooltip>
        <MicButton isOn={isMicrophoneOn} onClick={toggleMicrophone} />
        <LockButton isLocked={meetingSessionState.data?.studentIsLocked} onClick={toggleLock} />
      </Stack>
      {showStudentScreenThumbnail && (
        <Box
          sx={{
            width: previewScreenSize,
            height: previewScreenSize,
          }}
        >
          <StudentScreenPreview
            // Guaranteed to be the right type based on `showStudentScreen` being true
            previewPage={activeLessonPage as StudentPreviewScreenProps['previewPage']}
            onShowPreviewDialog={onShowPreviewDialog}
          />
        </Box>
      )}
      {isStudentPreviewScreenExpanded && (
        <FloatingStudentScreenPreview
          previewPage={activeLessonPage as StudentPreviewScreenProps['previewPage']}
          studentFirstName={studentFirstName}
          onDismissPreviewDialog={onDismissPreviewDialog}
        />
      )}
    </Stack>
  );
};

interface FloatingStudentScreenPreviewProps {
  previewPage: ActiveLessonPage.HRA | ActiveLessonPage.PMA;
  studentFirstName: string;
  onDismissPreviewDialog: () => void;
}

const FloatingStudentScreenPreview = (props: FloatingStudentScreenPreviewProps) => {
  const { previewPage, studentFirstName, onDismissPreviewDialog } = props;

  return (
    <FloatingDialog width="308px" title={`${studentFirstName}'s Screen`} onClose={onDismissPreviewDialog}>
      <PreviewComponent previewPage={previewPage} scaleMagicFinger={0.35} />
    </FloatingDialog>
  );
};

interface StudentPreviewScreenProps {
  previewPage: ActiveLessonPage.HRA | ActiveLessonPage.PMA;
  onShowPreviewDialog: () => void;
}

const StudentScreenPreview = (props: StudentPreviewScreenProps) => {
  const { previewPage, onShowPreviewDialog } = props;

  const [isHoveringPreviewThumbnail, setIsHoveringPreviewThumbnail] = useState(false);

  return (
    <Box
      sx={{ position: 'relative', width: '100%', height: '100%' }}
      onMouseEnter={() => setIsHoveringPreviewThumbnail(true)}
      onMouseLeave={() => setIsHoveringPreviewThumbnail(false)}
    >
      <PreviewComponent previewPage={previewPage} scaleMagicFinger={0.15} disableMyMagicFinger sx={{ padding: 0 }} />
      <Fade in={isHoveringPreviewThumbnail}>
        <Stack
          onClick={onShowPreviewDialog}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.80)',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.5,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: zIndexes.bookPageOnReader + 1,
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          <Icon name="enter_full_screen_filled" color={'neutral.200'} />
          <HootTypography variant="bodysmall" isPII={false} sx={{ color: hootTokens.palette.white }}>
            Expand
          </HootTypography>
        </Stack>
      </Fade>
    </Box>
  );
};

const PreviewComponent = (props: {
  previewPage: ActiveLessonPage;
  sx?: StackProps['sx'];
  scaleMagicFinger: number;
  disableMyMagicFinger?: boolean;
}) => {
  const { previewPage, ...rest } = props;
  return (
    <>
      {previewPage === ActiveLessonPage.HRA ? (
        <StudentHRAPage {...rest} />
      ) : previewPage === ActiveLessonPage.PMA ? (
        <StudentPMAPage {...rest} />
      ) : null}
    </>
  );
};

export default RemoteStudentTile;
