import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '../../../../../../../hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '../../../../../../../redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { QuestionnaireLayout5 } from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout5';
import { pmaInstructions, pmaTitles } from '../enum';
import { RadioOption } from '../interface';
import useTeacherPMAController from '../useTeacherPMAController';

export interface Layout5Questionnaire {
  title: string;
  options: RadioOption[];
}

export function PMA_QUESTIONNAIRE_LAYOUT_5(props: { questionnaire: Layout5Questionnaire[]; unitIdentifier: PMAUnitIdentifier.Module_4_Unit_2 }) {
  const { unitIdentifier } = props;
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);

  const [answers, setAnswers] = useState<Module_4_GenericAnswer[]>(
    props.questionnaire.map((q) => ({
      section: q.title,
      answer: null,
      answerValue: null,
    })),
  );

  const dispatch = useAppDispatch();

  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, unitIdentifier);

  const pmaController = useTeacherPMAController();

  useSearchPMAUnitSubmissionData(assessmentID!, unitIdentifier, {
    enabled: !!assessmentID,
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (!!data.submissionData) {
        setAnswers(
          (data.submissionData as Module_4_GenericAnswer[]).map((q) => ({
            section: q.section,
            answer: q.answer,
            answerValue: q.answerValue ?? null,
          })),
        );
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const handleChange = (val: Module_4_GenericAnswer[]) => {
    setAnswers(val);
  };

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const handleSubmit = () => {
    const submissionData: Module_4_GenericAnswer[] = answers.map((a) => ({
      section: a.section,
      answer: a.answer,
      answerValue: a.answerValue,
    }));
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: submissionData,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setIsUnitQuestionnaireDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  return (
    <QuestionnaireLayout5
      title="Progress Monitoring Assessment"
      unitIdentifier={unitIdentifier}
      unitTitle={pmaTitles[unitIdentifier]}
      instruction={pmaInstructions[unitIdentifier]}
      onChange={handleChange}
      isNextEnabled={isNextEnabled}
      isPrevEnabled={isPrevEnabled}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onPreviousUnitClick={onPreviousUnitClick}
      confirmPrevUnitClick={confirmPrevUnitClick}
      isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
      onSubmit={handleSubmit}
      questionnaire={props.questionnaire}
      value={answers}
      onExitAssessment={pmaController.onExitAssessment}
      isSubmitting={submitPMAUnitRequest.isLoading}
    />
  );
}
