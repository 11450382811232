import { config } from '@hoot/config';
import { UserProfileType } from '@hoot/models/api/user';

const scriptId = 'ze-snippet';
let zendeskReady: Promise<void> | null = null;

export const LIVE_CHAT_TITLE = 'Live Chat Support';

// A note from Adam on this implementation:
// By default we have the chat bubble settings in Zendesk set to always show so that we can have it appear
// in the Zendesk Help Center. However, this causes an issue with the app as we don't want the bubble to show because it
// covers important actions/buttons when in lessons etc. For that reason we use this code to hide/show the actual chat
// window when a user initiates it from the navigation. zendeskOpenLiveChat/zendeskOpenCloseChat will show the actual
// chat window, zendeskHideWidget/zendeskShowHideWidget is used to hide/show the bubble which is necessary for toggling
// the display of the actual chat window as well (overly complicated...)

// Before making any changes to this, please chat with Adam

export function zendeskInitializeLiveChatScript(currentProfileType: UserProfileType): Promise<void> {
  if (currentProfileType === UserProfileType.Student) {
    console.warn('Zendesk live chat not supported for student profiles.');
    return Promise.resolve();
  }

  if (zendeskReady) {
    // Script already initialized; reuse the existing promise
    return zendeskReady;
  }

  zendeskReady = new Promise((resolve, reject) => {
    const liveChatId = getLiveChatId(currentProfileType);
    if (!liveChatId) {
      console.error('Invalid live chat ID for profile type.');
      reject(new Error('Invalid live chat ID'));
      return;
    }

    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${liveChatId}`;
    script.id = scriptId;
    script.onload = () => {
      resolve();
    };
    script.onerror = (err) => {
      console.error('Failed to load Zendesk script', err);
      reject(new Error('Failed to load Zendesk script'));
    };

    document.body.appendChild(script);
  });

  // Automatically hide widget after initialization
  zendeskReady
    .then(() => {
      zendeskHideWidget();
      addZendeskEventListeners();
    })
    .catch(console.error);

  return zendeskReady;
}

export async function zendeskOpenLiveChat() {
  try {
    await zendeskReady;
    if (zendeskLiveChatScriptInitialized()) {
      (window as any).zE('messenger', 'open');
    } else {
      console.error("Zendesk 'zE' not found. Could not open Zendesk messenger.");
    }
  } catch (err) {
    console.error('Failed to open Zendesk live chat:', err);
  }
}

export async function zendeskShowWidget() {
  try {
    await zendeskReady;
    if (zendeskLiveChatScriptInitialized()) {
      (window as any).zE('messenger', 'show');
    } else {
      console.error("Zendesk 'zE' not found. Could not show Zendesk messenger.");
    }
  } catch (err) {
    console.error('Failed to show Zendesk widget:', err);
  }
}

export async function zendeskHideWidget() {
  try {
    await zendeskReady;
    if (zendeskLiveChatScriptInitialized()) {
      (window as any).zE('messenger', 'hide');
    } else {
      console.error("Zendesk 'zE' not found. Could not hide Zendesk messenger.");
    }
  } catch (err) {
    console.error('Failed to hide Zendesk widget:', err);
  }
}

export async function zendeskCloseLiveChat() {
  try {
    await zendeskReady;
    if (zendeskLiveChatScriptInitialized()) {
      (window as any).zE('messenger', 'close');
    } else {
      console.error("Zendesk 'zE' not found. Could not close Zendesk messenger.");
    }
  } catch (err) {
    console.error('Failed to close Zendesk live chat:', err);
  }
}

export async function zendeskLoginUser(zendeskToken: string) {
  try {
    await zendeskReady;
    if (zendeskLiveChatScriptInitialized()) {
      (window as any).zE('messenger', 'loginUser', function (callback: any) {
        callback(zendeskToken);
      });
    } else {
      console.error("Zendesk 'zE' not found. Could not login Zendesk user.");
    }
  } catch (err) {
    console.error('Failed to login Zendesk user:', err);
  }
}

export async function zendeskLogoutUser() {
  try {
    await zendeskReady;
    if (zendeskLiveChatScriptInitialized()) {
      (window as any).zE('messenger', 'logoutUser');
    } else {
      console.error("Zendesk 'zE' not found. Could not logout Zendesk user.");
    }
  } catch (err) {
    console.error('Failed to logout Zendesk user:', err);
  }
}

export function zendeskLiveChatScriptInitialized(): boolean {
  // @ts-ignore
  return typeof window['zE'] === 'function';
}

function getLiveChatId(currentProfileType: UserProfileType): string | null {
  switch (currentProfileType) {
    case UserProfileType.Teacher:
      return config.zendeskLiveChatIdTeacher;
    case UserProfileType.Parent:
      return config.zendeskLiveChatIdParent;
    case UserProfileType.DistrictRep:
      return config.zendeskLiveChatIdDistrictRep;
  }

  return null;
}

function addZendeskEventListeners() {
  if (!zendeskLiveChatScriptInitialized()) {
    console.error("Zendesk 'zE' not found. Could not add event listeners.");
    return;
  }

  // Event listener for when the chat widget is opened
  (window as any).zE('messenger:on', 'open', function () {
    zendeskShowWidget();
  });

  // Event listener for when the chat widget is closed
  (window as any).zE('messenger:on', 'close', function () {
    zendeskHideWidget();
  });
}
