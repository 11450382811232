import { Box } from '@mui/material';
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { featureFlags } from '@hoot/constants/featureFlags';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import {
  LibraryContext,
  LibraryTab,
  PaginationMode,
  libraryTabToShelfTypeDictionary,
  shelfTypeToLibraryTabDictionary,
  useLibraries,
} from '@hoot/redux/reducers/librarySlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import TeacherLibrary from '@hoot/ui/pages/v2/teacher/library/TeacherLibrary';
import { getDefaultLibraryQuery } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';
import useTeacherOutOfLessonLibraryController from '@hoot/ui/pages/v2/teacher/library/useTeacherOutOfLessonLibraryController';
import { TeacherStudentSpecificLibraryReaderPageQueryParams } from '@hoot/ui/pages/v2/teacher/my-students/student-details/library/reader/TeacherStudentSpecificLibraryReaderPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

enum QueryParams {
  ShelfType = 'shelfType',
}

const TeacherStudentSpecificLibraryPage = () => {
  usePageTitle('Library | Hoot Reading');

  const { studentSpecificLibrary } = useLibraries();
  const { query, paginationMode, results, previewBook } = studentSpecificLibrary;

  const { studentProfileId } = useParams() as { studentProfileId: string };

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const shelfTypeQueryArg = searchParams.get(QueryParams.ShelfType);
  const libraryTab = shelfTypeToLibraryTabDictionary[shelfTypeQueryArg as ShelfType] ?? LibraryTab.AllBooks;

  const libraryController = useTeacherOutOfLessonLibraryController({
    libraryContext: LibraryContext.StudentSpecificLibrary,
    studentProfileId,
  });

  const { isFeatureEnabled } = useFeatureFlags();
  const isSC10263Active = isFeatureEnabled(featureFlags.SC_10263_in_lesson_hra);

  useEffect(
    () => {
      if (!query) {
        libraryController.onSearchLibrary(getDefaultLibraryQuery(PaginationMode.Append, isSC10263Active, libraryTab));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onLibraryTabChanged = (libraryTab: LibraryTab) => {
    const shelfType = libraryTabToShelfTypeDictionary[libraryTab] ?? ShelfType.AllBooks;

    // We're updating the query arg, but we're not reacting to it.
    // The shelf type arg is only used on page load (if we refresh the page, we'll query this shelf type).
    setSearchParams({ [QueryParams.ShelfType]: shelfType }, { replace: true });

    libraryController.onSearchLibrary({
      ...query!,
      page: 1,
      shelfType,
    });
  };

  const onOpenBook = (book: BookResponse, defaultPageId?: string) => {
    libraryController.onDismissBookPreview(false);

    navigate({
      pathname: routesDictionary.myStudents.details.library.book.url(studentProfileId, book.id),
      search: defaultPageId
        ? createSearchParams({ [TeacherStudentSpecificLibraryReaderPageQueryParams.PageId]: defaultPageId! }).toString()
        : undefined,
    });
  };

  return (
    <Box p={3} sx={{ backgroundColor: hootTokens.surface[0] }}>
      <TeacherLibrary
        isInLesson={false}
        selectedTab={libraryTab}
        libraryQuery={query ?? getDefaultLibraryQuery(paginationMode, isSC10263Active)}
        isLoading={libraryController.isLoadingBooks}
        librarySearchResults={results}
        paginationMode={paginationMode}
        studentProfileId={studentProfileId}
        previewBook={previewBook ?? undefined}
        onPaginationModeChanged={libraryController.onPaginationModeChanged}
        onTabChanged={onLibraryTabChanged}
        onLibraryQueryChanged={libraryController.onSearchLibrary}
        onFetchNextPage={libraryController.onFetchNextPage}
        onPreviewBook={libraryController.onPreviewBook}
        onDismissPreviewBook={libraryController.onDismissBookPreview}
        onToggleFavouriteBook={libraryController.onToggleFavouriteBook}
        onOpenBook={onOpenBook}
      />
    </Box>
  );
};

export default TeacherStudentSpecificLibraryPage;
