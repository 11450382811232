import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';

export enum Environment {
  Local = 'local',
  Staging = 'staging',
  Prod = 'prod',
  Demo = 'demo',
  Homer = 'homer',
  Flanders = 'flanders',
  Seymour = 'seymour',
  Wiggum = 'wiggum',
  Marge = 'marge',
  Bart = 'bart',
  Moe = 'moe',
}

export const ENV_NAME = import.meta.env.VITE_HOOT_ENV?.toString() || Environment.Local;

export const IS_PROD = ENV_NAME === Environment.Prod;
export const IS_STAGING = ENV_NAME === Environment.Staging;
export const IS_LOCAL = ENV_NAME === Environment.Local;

export const IS_DEMO = ENV_NAME === Environment.Demo;

export const IS_ANY_DEMO = [
  Environment.Demo,
  Environment.Homer,
  Environment.Flanders,
  Environment.Seymour,
  Environment.Wiggum,
  Environment.Marge,
  Environment.Bart,
  Environment.Moe,
].includes(ENV_NAME as Environment);

export const WINNIPEG_TIMEZONE = 'America/Winnipeg';

export const PASSWORD_MINIMUM_LENGTH = 8;
export const PASSWORD_MAXIMUM_LENGTH = 100;

export const LOCAL_APP_VERSION = import.meta.env.VITE_VERSION;

const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;
export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;
export const APP_UPDATE_PROMPT_PERIOD = TWO_HOURS_IN_MS;
export const VERSION_CHECK_PERIOD = ONE_MINUTE_IN_MS;
export const VERSION_DIALOG_PERIOD = ONE_MINUTE_IN_MS;

/** Example => `Monday, October 16, 2023` */
export const FULL_DATE = 'cccc, LLLL dd, yyyy';
/** Example => `October 16, 2023` */
export const MONTH_DAY_DATE = 'LLLL dd, yyyy';
/** Example => `Oct 8, 2024` */
export const MONTH_DAY_YEAR_SHORT = 'LLL d, yyyy';

export const SERVER_TIME_SYNC_PERIOD = 30 * ONE_MINUTE_IN_MS;
export const FEATURE_FLAG_SYNC_PERIOD = 5 * ONE_MINUTE_IN_MS;

export const LOCAL_STORAGE_USER_KEY = 'USER';
export const LOCAL_STORAGE_USER_TOKEN_KEY = 'USER_TOKEN';

export const SMALL_WINDOW_WIDTH_CUTOFF = 1300;
export const SHOW_OPEN_BUTTON_STATUSES: ScheduledLessonStatus[] = [
  ScheduledLessonStatus.Open,
  ScheduledLessonStatus.OpenWaitingForStudent,
  ScheduledLessonStatus.OpenWaitingForTeacher,
  ScheduledLessonStatus.InProgress,
];

export const URGENT_LESSON_RANGE_IN_HOURS = 6;

export const IMAGE_UPLOAD_URL =
  'https://forms.zohopublic.com/hootreading1/form/TeacherProfileImage/formperma/JUKjmwBACj52VBkR4OmsG_kbGGEX-TCe0cQbQG-0FwU';

export const W9_URL = 'https://forms.zohopublic.com/hootreading1/form/TeacherProfileW9/formperma/WwRIll6RWoDGWY_TtRRqLNyOLb3jTmEDdud96wg5l5U';

export const DIRECT_DEPOSIT_URL =
  'https://forms.zohopublic.com/hootreading1/form/TeacherProfileDirectDeposit/formperma/mYXxKLiInGhIDBwbMFoFePkV8A5Q-rHjQU5YqTCv6-o';

export const COUNTRIES = [
  { label: 'Canada', value: 'CA' },
  { label: 'United States', value: 'US' },
];

export const SNACKBAR_AUTO_HIDE_DURATION = 6000;

export const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DISTRICT_SCHOOL_ID_REGEX = /^[A-Za-z0-9]{3}(-[A-Za-z0-9]{2})?$/;

export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const PAGES_TO_PRELOAD = 5;

export const TEXT_INPUT_DEBOUNCE_TIME_MS = 2000;
//Date constants
export const DATE_WITH_TIME = 'cccc, MMMM d kkkk, h:mma';

export const MAXIMUM_NUMBER_CELEBRATIONS = 3;
