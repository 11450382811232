import { useAppMessage, useMeetingState } from '@daily-co/daily-react';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { zIndexes } from '@hoot/constants/zIndices';
import { DailyMagicFingerMessage } from '@hoot/models/daily/dailyMessage';
import { RootState } from '@hoot/redux/store';

const MAGIC_FINGER_OFFSET = 16;
const MAGIC_FINGER_TOUCH_OFFSET = 30;

const DailyMyMagicFinger = (props: { width: number | string; height: number | string; scaleMagicFinger?: number }) => {
  const { width, height, scaleMagicFinger = 1 } = props;

  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const [showMagicFinger, setShowMagicFinger] = useState<boolean>(false);

  const svgRef = useRef<SVGSVGElement>(null);

  const sendAppMessage = useAppMessage();
  const meetingState = useMeetingState();

  function updateMagicFingerPosition(x: number, y: number, width: number, height: number) {
    if (svgRef && svgRef.current) {
      svgRef.current.style.transform = `translateX(${x - MAGIC_FINGER_OFFSET * scaleMagicFinger}px) translateY(${y}px)`;
      if (inLesson && meetingState === 'joined-meeting') {
        const offsetX = (x - MAGIC_FINGER_OFFSET * scaleMagicFinger) / width;
        const offsetY = y / height;

        if (showMagicFinger) {
          sendAppMessage(
            {
              messageType: 'magic-finger',
              payload: {
                event: 'move',
                lessonId: inLesson.lessonId,
                x: offsetX,
                y: offsetY,
              },
            } as DailyMagicFingerMessage,
            '*',
          );
        }
      }
    }
  }

  const toggleMagicFinger = useCallback(
    (show: boolean, x: number, y: number, width: number, height: number) => {
      setShowMagicFinger(show);

      if (svgRef && svgRef.current) {
        svgRef.current.style.transform = `translateX(${x - MAGIC_FINGER_OFFSET * scaleMagicFinger}px) translateY(${y}px)`;
      }

      if (inLesson && meetingState === 'joined-meeting') {
        const offsetX = (x - MAGIC_FINGER_OFFSET * scaleMagicFinger) / width;
        const offsetY = y / height;

        sendAppMessage(
          {
            messageType: 'magic-finger',
            payload: {
              event: show ? 'show' : 'hide',
              lessonId: inLesson.lessonId,
              x: offsetX,
              y: offsetY,
            },
          } as DailyMagicFingerMessage,
          '*',
        );
      }
    },
    [sendAppMessage, inLesson, meetingState],
  );

  useEffect(() => {
    if (inLesson) {
      const hideMagicFinger = () => {
        toggleMagicFinger(false, 0, 0, 0, 0);
      };

      window.addEventListener('mouseup', hideMagicFinger);
      window.addEventListener('touchend', hideMagicFinger);

      return () => {
        window.removeEventListener('mouseup', hideMagicFinger);
        window.removeEventListener('touchend', hideMagicFinger);
      };
    }
  }, [inLesson, toggleMagicFinger]);

  // Mouse Events

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const clientRect = event.currentTarget.getBoundingClientRect();

    const mouseX = event.pageX - clientRect.left;
    const mouseY = event.pageY - clientRect.top;

    updateMagicFingerPosition(mouseX, mouseY, clientRect.width, clientRect.height);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const clientRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.pageX - clientRect.left;
    const mouseY = event.pageY - clientRect.top;

    toggleMagicFinger(true, mouseX, mouseY, clientRect.width, clientRect.height);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const clientRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.pageX - clientRect.left;
    const mouseY = event.pageY - clientRect.top;
    toggleMagicFinger(false, mouseX, mouseY, clientRect.width, clientRect.height);
  };

  // Touch Events

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const clientRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.targetTouches[0].pageX - clientRect.left;
    const mouseY = event.targetTouches[0].pageY - clientRect.top - MAGIC_FINGER_TOUCH_OFFSET;
    updateMagicFingerPosition(mouseX, mouseY, clientRect.width, clientRect.height);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const clientRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.targetTouches[0].pageX - clientRect.left;
    const mouseY = event.targetTouches[0].pageY - clientRect.top - MAGIC_FINGER_TOUCH_OFFSET;
    toggleMagicFinger(true, mouseX, mouseY, clientRect.width, clientRect.height);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: zIndexes.myMagicFinger,
        touchAction: 'none',
        overflow: 'hidden',
      }}
      height={height}
      width={width}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onMouseLeave={() => setShowMagicFinger(false)}
      onTouchEnd={() => setShowMagicFinger(false)}
    >
      <svg
        style={{
          visibility: showMagicFinger ? 'visible' : 'hidden',
        }}
        ref={svgRef}
        width={200 * scaleMagicFinger}
        height={200 * scaleMagicFinger}
        viewBox="0 0 200 200"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.199 4.48938C9.59901 6.38938 8.799 14.3894 12.099 24.5894C15.299 34.2894 22.599 49.0894 33.699 68.4894C39.199 78.0894 44.999 88.4894 46.499 91.4894C50.999 100.189 64.899 132.889 64.299 133.489C64.099 133.689 60.199 130.189 55.699 125.489C46.299 115.889 37.999 110.089 30.299 107.889C20.299 104.989 11.999 108.689 11.999 115.989C11.999 120.889 13.799 123.989 20.999 130.989C24.399 134.289 28.099 138.589 29.199 140.489C34.099 148.689 40.299 155.489 54.699 168.789C73.599 186.189 77.399 189.189 82.499 190.589C84.699 191.189 91.599 193.289 97.899 195.089C115.199 200.189 119.999 200.789 132.699 198.989C161.199 194.989 185.299 184.789 194.599 172.689L198.199 167.989L197.699 156.489C196.599 135.189 192.499 117.889 184.699 101.989C179.299 90.8894 173.599 83.1894 163.899 73.5894L156.499 66.2894L141.999 66.7894C127.099 67.3894 111.699 69.5894 91.399 73.9894C84.999 75.3894 79.199 76.4894 78.499 76.4894C75.799 76.4894 41.999 29.4894 35.699 16.8894C33.699 12.9894 30.699 8.38938 28.999 6.58938C26.299 3.98938 25.099 3.48938 21.199 3.58938C18.599 3.58938 15.499 3.98938 14.199 4.48938Z"
          fill="rgba(255, 0, 0, 0.5)"
        />
      </svg>
    </Box>
  );
};

export default DailyMyMagicFinger;
