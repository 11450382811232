import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AllowedValue, Unit, useGetAssessmentUnit } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { QuestionnaireContainer } from '@hoot/ui/components/v2/assessments/teacher/QuestionnaireContainer';
import { Layout6Questionnaire } from '@hoot/ui/components/v2/assessments/teacher/questionnaires/QuestionnaireLayout6';
import { Button } from '@hoot/ui/components/v2/core/Button';
import ArrowIcon from '@hoot/ui/components/v2/icons/ArrowIcon';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';
import {
  HRAQuestionnaireLayout_1_Prompts,
  HRAQuestionnaireLayout_1_Props,
  HRA_QUESTIONNAIRE_LAYOUT_1,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/units/HRA_QUESTIONNAIRE_LAYOUT_1';
import HRA_QUESTIONNAIRE_LAYOUT_2, {
  HRAQuestionnaireLayout_2_Props,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/units/HRA_QUESTIONNAIRE_LAYOUT_2';
import {
  HRAQuestionnaireLayout_3_Prompt,
  HRAQuestionnaireLayout_3_Props,
  HRA_QUESTIONNAIRE_LAYOUT_3,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/units/HRA_QUESTIONNAIRE_LAYOUT_3';
import {
  HRAQuestionnaireLayout_4_Props,
  HRA_QUESTIONNAIRE_LAYOUT_4,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/units/HRA_QUESTIONNAIRE_LAYOUT_4';
import {
  HRAQuestionnaireLayout_6_Props,
  HRA_QUESTIONNAIRE_LAYOUT_6,
} from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/units/HRA_QUESTIONNAIRE_LAYOUT_6';
import useTeacherHRAController from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/useTeacherHRAController';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { UnitIdentifierHRAV2 } from '../../../../../../models/api/enums/hoot-reading-assessment';
import { Hra } from '../../../../../../redux/reducers/hraSlice';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { RadioOption } from '../progress-monitoring-assessment/interface';
import { HRAQuestionnaireLayout_5_Prompt, HRAQuestionnaireLayout_5_Props, HRA_QUESTIONNAIRE_LAYOUT_5 } from './units/HRA_QUESTIONNAIRE_LAYOUT_5';

const questionnaireUnitsDictionary: Record<string, UnitIdentifierHRAV2[]> = {
  q1: [UnitIdentifierHRAV2.U_1_1, UnitIdentifierHRAV2.U_1_2, UnitIdentifierHRAV2.U_1_3, UnitIdentifierHRAV2.U_1_4],
  q2: [UnitIdentifierHRAV2.U_1_5],
  q3: [
    UnitIdentifierHRAV2.U_2_1,
    UnitIdentifierHRAV2.U_2_2,
    UnitIdentifierHRAV2.U_2_3,
    UnitIdentifierHRAV2.U_2_4,
    UnitIdentifierHRAV2.U_2_5,
    UnitIdentifierHRAV2.U_2_6,
    UnitIdentifierHRAV2.U_3_1,
    UnitIdentifierHRAV2.U_3_2,
    UnitIdentifierHRAV2.U_3_3,
    UnitIdentifierHRAV2.U_3_4,
    UnitIdentifierHRAV2.U_3_5,
    UnitIdentifierHRAV2.U_3_6,
  ],
  q4: [UnitIdentifierHRAV2.U_4_1],
  q5: [UnitIdentifierHRAV2.U_4_2],
  q6: [UnitIdentifierHRAV2.U_4_3, UnitIdentifierHRAV2.U_4_4],
};

interface HRAPageProps {
  hra: Hra;
}

function HRAPage(props: HRAPageProps) {
  const { hra } = props;
  const { assessmentId, currentUnitId, currentUnitIdentifier, isPrevEnabled } = hra;
  const isAssessmentComplete = currentUnitIdentifier === UnitIdentifierHRAV2.U_HRAV2C;

  const dispatch = useAppDispatch();
  const [questionnaireProps, setQuestionnaireProps] = useState<{
    q1?: HRAQuestionnaireLayout_1_Props;
    q2?: HRAQuestionnaireLayout_2_Props;
    q3?: HRAQuestionnaireLayout_3_Props;
    q4?: HRAQuestionnaireLayout_4_Props;
    q5?: HRAQuestionnaireLayout_5_Props;
    q6?: HRAQuestionnaireLayout_6_Props;
  }>({});

  const { q1, q2, q3, q4, q5, q6 } = questionnaireProps;

  const { onExitAssessment } = useTeacherHRAController();

  const { isFetching } = useGetAssessmentUnit(assessmentId, currentUnitId, {
    enabled: !!assessmentId && !!currentUnitId && !isAssessmentComplete,
    retry: false,
    onSuccess: (unitResponse) => {
      switch (unitResponse.identifier) {
        case UnitIdentifierHRAV2.U_1_1:
        case UnitIdentifierHRAV2.U_1_2:
        case UnitIdentifierHRAV2.U_1_3:
        case UnitIdentifierHRAV2.U_1_4:
          setQuestionnaireProps({ q1: mapUnitToQuestionnaire1Props(unitResponse, assessmentId, isPrevEnabled) });
          break;
        case UnitIdentifierHRAV2.U_1_5:
          setQuestionnaireProps({ q2: mapUnitToQuestionnaire2Props(unitResponse, assessmentId, isPrevEnabled) });
          break;
        case UnitIdentifierHRAV2.U_2_1:
        case UnitIdentifierHRAV2.U_2_2:
        case UnitIdentifierHRAV2.U_2_3:
        case UnitIdentifierHRAV2.U_2_4:
        case UnitIdentifierHRAV2.U_2_5:
        case UnitIdentifierHRAV2.U_2_6:
        case UnitIdentifierHRAV2.U_3_1:
        case UnitIdentifierHRAV2.U_3_2:
        case UnitIdentifierHRAV2.U_3_3:
        case UnitIdentifierHRAV2.U_3_4:
        case UnitIdentifierHRAV2.U_3_5:
        case UnitIdentifierHRAV2.U_3_6:
          setQuestionnaireProps({ q3: mapUnitToQuestionnaire3Props(unitResponse, assessmentId, isPrevEnabled) });
          break;
        case UnitIdentifierHRAV2.U_4_1:
          setQuestionnaireProps({ q4: mapUnitToQuestionnaire4Props(unitResponse, assessmentId, isPrevEnabled) });
          break;
        case UnitIdentifierHRAV2.U_4_2:
          setQuestionnaireProps({ q5: mapUnitToQuestionnaire5Props(unitResponse, assessmentId, isPrevEnabled) });
          break;
        case UnitIdentifierHRAV2.U_4_3:
        case UnitIdentifierHRAV2.U_4_4:
          setQuestionnaireProps({ q6: mapUnitToQuestionnaire6Props(unitResponse, assessmentId, isPrevEnabled) });
          break;
        default:
          console.warn(`Unsupported unit identifier ${unitResponse.identifier}`);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading assessment ID ${assessmentId} unit ${currentUnitId}.` }));
    },
  });

  const renderQuestionnaire = () => {
    if (isFetching) {
      return <CircularProgress />;
    } else if (q1) {
      return <HRA_QUESTIONNAIRE_LAYOUT_1 {...q1} />;
    } else if (q2) {
      return <HRA_QUESTIONNAIRE_LAYOUT_2 {...q2} />;
    } else if (q3) {
      return <HRA_QUESTIONNAIRE_LAYOUT_3 {...q3} />;
    } else if (q4) {
      return <HRA_QUESTIONNAIRE_LAYOUT_4 {...q4} />;
    } else if (q5) {
      return <HRA_QUESTIONNAIRE_LAYOUT_5 {...q5} />;
    } else if (q6) {
      return <HRA_QUESTIONNAIRE_LAYOUT_6 {...q6} />;
    } else {
      // This shouldn't happen, but if we get into a state where we're trying to render a unit that isn't supported,
      // we need to render _something_ so we don't get stuck.
      return (
        <QuestionnaireContainer
          unitIdentifier={UnitIdentifierHRAV2.U_1_1}
          title="???"
          instruction="???"
          unitTitle="???"
          children={null}
          onExitAssessment={onExitAssessment}
        />
      );
    }
  };

  return (
    <Stack flex={1} sx={{ p: 3, overflowY: 'auto' }}>
      {renderQuestionnaire()}
      {isAssessmentComplete && (
        <Dialog open={true}>
          <DialogTitle>Assessment Submitted</DialogTitle>
          <DialogContent>
            <ViewStateIllustration illustration={IllustrationEnum.UnitComplete} title="Great Job!" subtitle="The assessment is complete!" />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onExitAssessment}
              variant="contained"
              fullWidth
              startIcon={<ArrowIcon rotate={RotateDirection.Left} htmlColor={hootTokens.palette.white} />}
            >
              Back to Library
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}

const mapUnitToQuestionnaire1Props = (
  unitResponse: Unit,
  assessmentId: string,
  isPreviousUnitEnabled: boolean,
): HRAQuestionnaireLayout_1_Props | undefined => {
  const supportedUnits = questionnaireUnitsDictionary.q1;
  if (!supportedUnits.some((x) => x === (unitResponse.identifier as string))) {
    console.error(
      `Error mapping HRA unit to questionnaire layout 1 props. Unit must be one of the following identifiers: ${supportedUnits}. Unit identifier supplied is ${unitResponse.identifier}.`,
    );
    return;
  }

  const fields = unitResponse.sections.flatMap((x) => x.fields);

  const field = unitResponse.sections.flatMap((x) => x.fields)[0];

  const prompts = fields.map<HRAQuestionnaireLayout_1_Prompts>((field) => {
    const expectedResponse = field.expectedResponse ?? '';

    // Hacking this a bit. We're assuming that all text between single quotes are meant to be bolded.
    let wordsToBold = field.label.match(/(?!^)'.*?'/g)?.map((x) => x.toString()) ?? [];
    let sentence = field.label;
    let scoreCardLabel = expectedResponse;

    if (wordsToBold.length > 0) {
      // For each bolded term in the sentence, remove quotes.
      for (const w of wordsToBold) {
        const termWithoutQuotes = w.replaceAll("'", '');
        sentence = sentence.replace(w, termWithoutQuotes);
      }
      // Remove quotes from each bolded term.
      wordsToBold = wordsToBold.map((w) => w.replaceAll("'", ''));

      // If there was only a single word wrapped in quotations, then this is our scorecard label.
      if (wordsToBold.length === 1) {
        scoreCardLabel = wordsToBold[0];
      }
    }
    return {
      sentence,
      expectedResponse,
      word: scoreCardLabel,
      wordsToBold: wordsToBold.join(','),
    };
  });
  return {
    assessmentId,
    unitIdentifier: unitResponse.identifier as HRAQuestionnaireLayout_1_Props['unitIdentifier'],
    unitId: unitResponse.id,
    unitTitle: unitResponse.name,
    instructions: unitResponse.introductoryText,
    fieldName: field.name,
    instructionIndicatorThresholds: unitResponse.instructionIndicatorThresholds ?? [],
    defaultAnswers: unitResponse.submissionData?.[field.name],
    submissionId: unitResponse.submissionId,
    prompts,
    isPreviousUnitEnabled,
  };
};

const mapUnitToQuestionnaire2Props = (
  unitResponse: Unit,
  assessmentId: string,
  isPreviousUnitEnabled: boolean,
): HRAQuestionnaireLayout_2_Props | undefined => {
  const supportedUnits = questionnaireUnitsDictionary.q2;
  if (!supportedUnits.some((x) => x === (unitResponse.identifier as string))) {
    console.error(
      `Error mapping HRA unit to questionnaire layout 2 props. Unit must be one of the following identifiers: ${supportedUnits}. Unit identifier supplied is ${unitResponse.identifier}.`,
    );
    return;
  }

  // There is only a single field for these units.
  const field = unitResponse.sections.flatMap((x) => x.fields)[0];
  const letterOptions = field.allowedValues as string[];

  return {
    assessmentId,
    unitIdentifier: unitResponse.identifier as HRAQuestionnaireLayout_2_Props['unitIdentifier'],
    submissionId: unitResponse.submissionId,
    fieldName: field.name,
    unitId: unitResponse.id,
    unitTitle: unitResponse.name,
    instructions: unitResponse.introductoryText,
    letterOptions,
    instructionIndicatorThresholds: unitResponse.instructionIndicatorThresholds ?? [],
    defaultAnswers: unitResponse.submissionData?.[field.name],
    isPreviousUnitEnabled,
  };
};

const mapUnitToQuestionnaire3Props = (
  unitResponse: Unit,
  assessmentId: string,
  isPreviousUnitEnabled: boolean,
): HRAQuestionnaireLayout_3_Props | undefined => {
  const supportedUnits = questionnaireUnitsDictionary.q3;
  if (!supportedUnits.some((x) => x === (unitResponse.identifier as string))) {
    console.error(
      `Error mapping HRA unit to questionnaire layout 3 props. Unit must be one of the following identifiers: ${supportedUnits}. Unit identifier supplied is ${unitResponse.identifier}.`,
    );
    return;
  }

  // *** There should only be a single field for these units. ***
  const field = unitResponse.sections.flatMap((x) => x.fields)[0];
  const sentenceMap = new Map(field.sentences!.map((x) => [x.questionKey, x]));

  // I think `allowedValues` is always a string array for these units.
  const prompts = (field.allowedValues as string[])!.map<HRAQuestionnaireLayout_3_Prompt>((question) => {
    // Not every question has an associated "sentence". This "sentence" data was added specifically for questions that were
    // formatted like "cat:sentence(2)". We'll be replacing that with the actual sentence. e.g. "The cat is black".
    const sentence = sentenceMap.get(question);

    return {
      // NOTE: If there is no sentence for this particular question; it's most likely just a single word.
      fullPrompt: sentence?.sentence ?? question,
      boldedWord: sentence?.boldedWords?.[0] ?? question,
    };
  });

  return {
    assessmentId,
    unitIdentifier: unitResponse.identifier as HRAQuestionnaireLayout_3_Props['unitIdentifier'],
    unitId: unitResponse.id,
    unitTitle: unitResponse.name,
    prompts,
    fieldName: field.name,
    unfurl: field.unfurl,
    instructionIndicatorThresholds: unitResponse.instructionIndicatorThresholds ?? [],
    submissionId: unitResponse.submissionId,
    defaultAnswers: unitResponse.submissionData?.[field.name],
    isPreviousUnitEnabled,
  };
};

const mapUnitToQuestionnaire4Props = (
  unitResponse: Unit,
  assessmentId: string,
  isPreviousUnitEnabled: boolean,
): HRAQuestionnaireLayout_4_Props | undefined => {
  const supportedUnits = questionnaireUnitsDictionary.q4;
  if (!supportedUnits.some((x) => x === (unitResponse.identifier as string))) {
    console.error(
      `Error mapping HRA unit to questionnaire layout 4 props. Unit must be one of the following identifiers: ${supportedUnits}. Unit identifier supplied is ${unitResponse.identifier}.`,
    );
    return;
  }

  return {
    assessmentId,
    unitIdentifier: unitResponse.identifier as HRAQuestionnaireLayout_4_Props['unitIdentifier'],
    unitId: unitResponse.id,
    unitTitle: unitResponse.name,
    instructions: unitResponse.introductoryText,
    submissionId: unitResponse.submissionId,
    submissionData: unitResponse.submissionData,
    isPreviousUnitEnabled,
  };
};

const mapUnitToQuestionnaire5Props = (
  unitResponse: Unit,
  assessmentId: string,
  isPreviousUnitEnabled: boolean,
): HRAQuestionnaireLayout_5_Props | undefined => {
  const supportedUnits = questionnaireUnitsDictionary.q5;
  if (!supportedUnits.some((x) => x === (unitResponse.identifier as string))) {
    console.error(
      `Error mapping HRA unit to questionnaire layout 5 props. Unit must be one of the following identifiers: ${supportedUnits}. Unit identifier supplied is ${unitResponse.identifier}.`,
    );
    return;
  }

  // There is only a single field for these units.

  const prompts = unitResponse.sections
    .flatMap((s) => s.fields)
    .map<HRAQuestionnaireLayout_5_Prompt>((f) => ({
      title: f.label,
      options: (f.allowedValues as { label: string; value: string }[])?.map<RadioOption>((v) => ({
        text: v.label,
        val: v.value,
      })),
    }));

  return {
    assessmentId,
    submissionId: unitResponse.submissionId,
    fieldName: '',
    unitIdentifier: unitResponse.identifier as HRAQuestionnaireLayout_5_Props['unitIdentifier'],
    unitId: unitResponse.id,
    unitTitle: unitResponse.name,
    instructions: unitResponse.introductoryText,
    prompts,
    instructionIndicatorThresholds: unitResponse.instructionIndicatorThresholds ?? [],
    isPreviousUnitEnabled,
    defaultAnswers: unitResponse.submissionData
      ? prompts.map((p, idx) => {
          const getAnswerValue = () => {
            if (idx === 0) {
              return Number.parseInt(unitResponse.submissionData['U_4_2_S1_F1']);
            } else if (idx === 1) {
              return Number.parseInt(unitResponse.submissionData['U_4_2_S2_F1']);
            }
            return 0;
          };

          const answerValue = getAnswerValue() as number;
          const answer = p.options.find((o) => o.val === answerValue.toString())?.text;

          return { section: p.title, answer: answer ?? '', answerValue: answerValue ?? '' };
        })
      : undefined,
  };
};

const mapUnitToQuestionnaire6Props = (
  unitResponse: Unit,
  assessmentId: string,
  isPreviousUnitEnabled: boolean,
): HRAQuestionnaireLayout_6_Props | undefined => {
  const supportedUnits = questionnaireUnitsDictionary.q6;
  if (!supportedUnits.some((x) => x === (unitResponse.identifier as string))) {
    console.error(
      `Error mapping HRA unit to questionnaire layout 5 props. Unit must be one of the following identifiers: ${supportedUnits}. Unit identifier supplied is ${unitResponse.identifier}.`,
    );
    return;
  }
  const fields = unitResponse.sections.flatMap((x) => x.fields).filter((x) => (x.allowedValues?.length ?? 0) > 0);
  const questions = fields.map<Layout6Questionnaire>((f) => ({
    title: f.label as Layout6Questionnaire['title'],
    // Hardcoding this one subtitle b/c this data doesn't exist in the BE.
    subTitle:
      (f.label as Layout6Questionnaire['title']) === 'Vocabulary (Overall)' ? 'Consider the student’s use of vocabulary holistically.' : undefined,
    options: ((f.allowedValues ?? []) as AllowedValue[]).map<RadioOption>((x) => ({
      text: x.label,
      val: x.value,
    })),
  }));
  return {
    assessmentId,
    unitId: unitResponse.id,
    unitTitle: unitResponse.name,
    instructions: unitResponse.introductoryText,
    unitIdentifier: unitResponse.identifier as HRAQuestionnaireLayout_6_Props['unitIdentifier'],
    questionnaire: questions,
    isPreviousUnitEnabled,
  };
};

function HRAPageWrapper() {
  const hra = useSelector((state: RootState) => state.hra.hra);

  if (!hra) {
    return null;
  }

  return <>{hra && <HRAPage hra={hra} />}</>;
}

export default HRAPageWrapper;
