import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import {
  ConditionAction,
  FlexDirection,
  FlexJustify,
  HootAssessmentFieldType,
  HootAssessmentInstructionIndicator,
  HootAssessmentModule,
  OperandType,
  Operator,
  UnitIdentifier,
  UnitIdentifierHRAV2,
} from '@hoot/models/api/enums/hoot-reading-assessment';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface InstructionIndicatorThreshold {
  instructionLevel: HootAssessmentInstructionIndicator;
  initial: number;
  end: number;
}

export interface AllowedValue {
  label: string;
  value: string;
}

export interface Validations {
  required: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
}

export interface Operand {
  value: number | string | Calculation;
  type: OperandType;
}

export interface Calculation {
  operator: Operator;
  leftOperand: Operand;
  rightOperand: Operand;
}

export interface WhenCondition {
  field: string;
  minLength?: number;
}

export interface Condition {
  when: WhenCondition;
  doAction: ConditionAction;
}

export interface Unfurl {
  segments: { start: number; end: number; amount: number; subtitle: string }[];
  whenCorrect: number;
  exampleQuestion?: {
    atIndex: number;
    helperText: string;
    prompt: string;
  };
}

export interface SectionField {
  id: string;
  sequence: number;
  unitId: string;
  name: string;
  type: HootAssessmentFieldType;
  allowedValues?: AllowedValue[] | string[];
  label: string;
  expectedResponse?: string;
  validations?: Validations;
  calculation?: Calculation;
  condition?: Condition;
  instructionIndicatorThresholds?: InstructionIndicatorThreshold[];
  sectionGroup?: string;
  unfurl?: Unfurl;
  sentences?: FieldSentence[];
}

export interface FieldSentence {
  unitFieldId: string;
  formVariant: FormVariant;
  questionKey: string;
  sentence: string;
  boldedWords: string[];
}

export interface UnitSection {
  id: string;
  sequence: number;
  title: string;
  direction?: FlexDirection;
  justify?: FlexJustify;
  fields: SectionField[];
  sections: UnitSection[];
}

export enum FormVariant {
  A = 'A',
  B = 'B',
}

export interface Unit {
  id: string;
  submissionId?: string;
  name: string;
  identifier: UnitIdentifier | UnitIdentifierHRAV2;
  module: HootAssessmentModule;
  version: number;
  introductoryText: string;
  watch?: string[];
  sections: UnitSection[];
  instructionIndicatorThresholds?: InstructionIndicatorThreshold[] | null;
  submissionData?: any;
  previousUnitId?: string;
  nextUnitId?: string;
  fieldFormVariant?: FormVariant;
}

async function getAssessmentUnit(assessmentId: string, unitId?: string) {
  const { data } = await axios.get<Unit>(`${config.apiUrl}/public/hoot-assessment/${assessmentId}/unit/${unitId}`);
  return data;
}

export function useGetAssessmentUnit(
  assessmentId: string,
  unitId?: string,
  options?: Omit<UseQueryOptions<Unit, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetAssessmentUnit, assessmentId, unitId], () => getAssessmentUnit(assessmentId, unitId), options);
}
