import { Box, Drawer, Stack, Tooltip } from '@mui/material';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { featureFlags } from '@hoot/constants/featureFlags';
import { zIndexes } from '@hoot/constants/zIndices';
import useGetFilterLevelPrioritizedSkills, { FilterLevelPrioritizedSkillsRequest } from '@hoot/hooks/api/library/useGetFilterLevelPrioritizedSkills';
import useGetFormats from '@hoot/hooks/api/library/useGetFormats';
import useGetGenres from '@hoot/hooks/api/library/useGetGenres';
import useGetInstructionalFocuses, { InstructionalFocusRequest } from '@hoot/hooks/api/library/useGetInstructionalFocuses';
import useGetInstructionalUnits, { InstructionalUnitsRequest } from '@hoot/hooks/api/library/useGetInstructionalUnits';
import useGetSeries from '@hoot/hooks/api/library/useGetSeries';
import useGetSubGenres from '@hoot/hooks/api/library/useGetSubGenres';
import useGetSubThemes, { SubThemesRequest } from '@hoot/hooks/api/library/useGetSubThemes';
import useGetSubTopics, { SubTopicsRequest } from '@hoot/hooks/api/library/useGetSubTopics';
import useGetThemes from '@hoot/hooks/api/library/useGetThemes';
import useGetTopics from '@hoot/hooks/api/library/useGetTopics';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { BookGenreId } from '@hoot/models/api/enums/book-genre-id';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import {
  BookType,
  DecodableFormat,
  Grade,
  ResourceProgress,
  ResourceState,
  ageOptions,
  bookTypeOptions,
  decodableFormatOptions,
  gradeOptions,
  resourceProgressValues,
  resourceStateValues,
} from '@hoot/models/api/library';
import { PaginationMode, shelfTypeToLibraryTabDictionary } from '@hoot/redux/reducers/librarySlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import ChipGroup, { ChipGroupProps, trueFalseOptions } from '@hoot/ui/components/v2/core/ChipGroup';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import LibraryFilterChipGroup from '@hoot/ui/pages/v2/teacher/library/LibraryFilterChipGroup';
import { getDefaultLibraryQuery } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const TITLE_DIV_HEIGHT = '76px';
const BUTTON_DIV_HEIGHT = '90px';

export interface FilterForm {
  resourceType: BookType | null;
  resourceStateValues: ResourceState[];
  resourceProgressValues: ResourceProgress[];
  instructionalFocusIds: string[];
  instructionalUnitIds: string[];
  filterLevelPrioritizedSkillIds: string[];
  decodableFormats: DecodableFormat[];
  hiLo: boolean | null;
  nonConforming: boolean | null;
  seriesId: string | null;
  age: number | null;
  grade: Grade | null;
  genreId: string | null;
  subGenreId: string | null;
  topicIds: string[];
  subTopicIds: string[];
  themeIds: string[];
  subThemeIds: string[];
  formatId: string | null;
  isInstructionalLibrary: boolean | null;
}

interface Props {
  isOpen: boolean;
  onCloseFilterDrawer: () => void;
  query: QueryLibraryV2;
  paginationMode: PaginationMode;
  onLibraryQueryChanged: (newQuery: QueryLibraryV2) => void;
  studentProfileId?: string;
  doesStudentHaveInstructionalLibrary?: boolean;
  isInLessonReview?: boolean;
}

const TeacherLibraryFilterDrawer = (props: Props) => {
  const {
    isOpen,
    onCloseFilterDrawer,
    query,
    paginationMode,
    onLibraryQueryChanged,
    studentProfileId,
    doesStudentHaveInstructionalLibrary,
    isInLessonReview,
  } = props;
  const isOnStudentLibrary = !!studentProfileId;
  const { isFeatureEnabled } = useFeatureFlags();
  const isSC10263Active = isFeatureEnabled(featureFlags.SC_10263_in_lesson_hra);

  const bookTypeOptionsFiltered = bookTypeOptions.filter((option) => option.value !== BookType.HootReadingAssessment);

  const defaultFormValues = useMemo(() => {
    return {
      resourceType: !!query.resourceType ? (query.resourceType as BookType) : null,
      resourceStateValues: !!query.resourceStateValues ? query.resourceStateValues : [],
      resourceProgressValues: !!query.resourceProgressValues ? query.resourceProgressValues : [],
      instructionalFocusIds: query.instructionalFocusIds && query.instructionalFocusIds.length > 0 ? query.instructionalFocusIds : [],
      instructionalUnitIds: query.instructionalUnitIds && query.instructionalUnitIds.length > 0 ? query.instructionalUnitIds : [],
      filterLevelPrioritizedSkillIds:
        query.filterLevelPrioritizedSkillIds && query.filterLevelPrioritizedSkillIds.length > 0 ? query.filterLevelPrioritizedSkillIds : [],
      decodableFormats: query.decodableFormats && query.decodableFormats.length > 0 ? query.decodableFormats : [],
      hiLo: query.hiLo ?? null,
      nonConforming: query.nonConforming ?? null,
      seriesId: query.seriesId ?? null,
      age: query.age ?? null,
      grade: query.grade ?? null,
      genreId: query.genreId ?? null,
      subGenreId: query.subGenreId ?? null,
      topicIds: query.topicIds && query.topicIds.length > 0 ? query.topicIds : [],
      subTopicIds: query.subTopicIds && query.subTopicIds.length > 0 ? query.subTopicIds : [],
      themeIds: query.themeIds && query.themeIds.length > 0 ? query.themeIds : [],
      subThemeIds: query.subThemeIds && query.subThemeIds.length > 0 ? query.subThemeIds : [],
      formatId: query.formatId ?? null,
      isInstructionalLibrary: query.isInstructionalLibrary ?? null,
    };
  }, [query]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm<FilterForm>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    //Needed to reset the form when the params change on reset from outside the drawer
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const { resourceType, instructionalFocusIds, instructionalUnitIds, hiLo, nonConforming, age, grade, genreId, topicIds, themeIds } = watch();

  /* === Resource Progress and State === */
  const showResourceStateAndProgress = isOnStudentLibrary || (!!isInLessonReview && !!studentProfileId);

  /* === Resource instructional focus === */
  const instructionalFocusesRequest: InstructionalFocusRequest = { resourceType: resourceType };
  const instructionalFocusesData = useGetInstructionalFocuses(instructionalFocusesRequest, {
    enabled: isOpen && !!instructionalFocusesRequest.resourceType,
  });
  const instructionalFocusesOptions: ChipGroupProps['items'] = instructionalFocusesData.data
    ? instructionalFocusesData.data.instructionalFocuses.map((t) => ({ value: t.id, label: t.name }))
    : [];
  const showInstructionalFocuses = !!resourceType && instructionalFocusesOptions.length !== 0;

  /* === Instructional Units === */
  const instructionalUnitsEnabled = instructionalFocusIds.length > 0;
  const instructionalUnitsRequest: InstructionalUnitsRequest = { instructionalFocuses: instructionalFocusIds };
  const { data: instructionalUnitsData } = useGetInstructionalUnits(instructionalUnitsRequest, {
    enabled: isOpen && instructionalUnitsRequest.instructionalFocuses.length > 0,
  });
  const showInstructionalUnits =
    instructionalUnitsEnabled && !!instructionalUnitsData && instructionalUnitsData.categorizedInstructionalUnits.length > 0;

  /* === Filter Level Prioritized Skills === */
  const filterLevelPrioritizedSkillsEnabled = instructionalUnitIds.length > 0 && resourceType !== BookType.FormativeAssessment;
  const filterLevelPrioritizedSkillsRequest: FilterLevelPrioritizedSkillsRequest = { instructionalUnits: instructionalUnitIds };
  const { data: filterLevelPrioritizedSkillsData } = useGetFilterLevelPrioritizedSkills(filterLevelPrioritizedSkillsRequest, {
    enabled: isOpen && filterLevelPrioritizedSkillsRequest.instructionalUnits.length > 0,
  });

  const showPrioritizedSkills =
    filterLevelPrioritizedSkillsEnabled &&
    !!filterLevelPrioritizedSkillsData &&
    filterLevelPrioritizedSkillsData.categorizedPrioritizedSkills.length > 0;

  /* === Decodable format === */
  const showDecodableFormat = instructionalFocusIds.some(
    (st) =>
      st === instructionalFocusesOptions.find((st) => st.value === InstructionalFocus.PreWordResource)?.value ||
      st === instructionalFocusesOptions.find((st) => st.value === InstructionalFocus.EarlyWord)?.value ||
      st === instructionalFocusesOptions.find((st) => st.value === InstructionalFocus.ComplexWordReading)?.value,
  );

  /* === Hi-Lo, Non-conforming and Series === */
  const enableHiLoNonConformingSection =
    resourceType === BookType.Instruction &&
    instructionalFocusIds.some(
      (st) => st === instructionalFocusesOptions.find((st) => st.value === InstructionalFocus.InstructionTextReading)?.value,
    ) &&
    !grade;

  const enableSeriesSection = hiLo === true;

  //The showHiLo and shownonConforming booleans are so that the options don't render without the default values set
  const showHiLo = enableHiLoNonConformingSection && hiLo !== null;
  //TODO: non conforming is temporarily hidden until more books are added with a 'Yes' for nonConforming (search for this comment to find places where non-conforming code was commented out)
  // const showNonConforming = showHiLoNonConformingAndSeriesSection && nonConforming !== null; //<-- do not remove this commented code
  const showNonConforming = false;

  const { data: seriesData } = useGetSeries({ enabled: isOpen && enableSeriesSection });

  const showSeries = enableSeriesSection && !!seriesData && seriesData.categorizedSeries.length > 0;

  /* === Age === */
  const showAge = !!hiLo || !!nonConforming;

  /* === Grade === */
  const showGrade =
    (resourceType === BookType.Instruction &&
      instructionalFocusIds.some(
        (st) => st === instructionalFocusesOptions.find((st) => st.value === InstructionalFocus.InstructionTextReading)?.value,
      ) &&
      !hiLo &&
      !nonConforming) ||
    (resourceType === BookType.FormativeAssessment && instructionalFocusIds.some((x) => x === InstructionalFocus.FormativeAssessmentTextReading)) ||
    (isSC10263Active
      ? false
      : resourceType === BookType.HootReadingAssessment && instructionalFocusIds.some((x) => x === InstructionalFocus.AssessmentTextReading)); //Todo Remove this line when removing FF 10263 (Ticket SC12878)

  /* === Genre and Sub-Genre === */
  const genreAndSubGenreEnabled =
    !!grade && resourceType !== BookType.FormativeAssessment && (isSC10263Active ? true : resourceType !== BookType.HootReadingAssessment); //Todo Remove the code in parentheses when removing FF 10263 (Ticket SC12878)
  const genres = useGetGenres({ enabled: isOpen && genreAndSubGenreEnabled });
  const genreOptions: ChipGroupProps['items'] = genres.data
    ? genres.data.genres.map((g) => ({
        value: g.id,
        label: g.name,
      }))
    : [];
  const showGenreAndSubGenre = genreAndSubGenreEnabled && genreOptions.length > 0;

  const subGenres = useGetSubGenres(genreId, { enabled: isOpen && !!genreId });
  const subGenreOptions: ChipGroupProps['items'] = subGenres.data
    ? subGenres.data.subGenres.map((g) => ({
        value: g.id,
        label: g.name,
      }))
    : [];
  const showSubGenres = subGenreOptions.length > 0;

  /* === Topic === */
  const topicsEnabled = genreId === BookGenreId.NonFiction || genreId === BookGenreId.InformationalFictionAndNarrativeNonFiction;
  const { data: topicsData } = useGetTopics({ enabled: isOpen && topicsEnabled });
  const showTopics = topicsEnabled && !!topicsData && topicsData.categorizedTopics.length > 0;

  /* === Sub Topic === */
  const enableSubTopics = topicIds.length > 0;
  const subTopicsRequest: SubTopicsRequest = { topicIds: topicIds };
  const { data: subTopicsData } = useGetSubTopics(subTopicsRequest, { enabled: isOpen && subTopicsRequest.topicIds.length > 0 });

  const showSubTopics = enableSubTopics && !!subTopicsData && subTopicsData.categorizedSubTopics.length > 0;

  /* === Theme  === */
  const themesEnabled = genreId === BookGenreId.Fiction;
  const { data: themesData } = useGetThemes({ enabled: isOpen && themesEnabled });

  const showThemes = themesEnabled && !!themesData && themesData.categorizedThemes.length > 0;
  /* === Sub Theme === */
  const enableSubThemes = themeIds.length > 0;
  const subThemesRequest: SubThemesRequest = { themeIds: themeIds };
  const { data: subThemesData } = useGetSubThemes(subThemesRequest, { enabled: isOpen && subThemesRequest.themeIds.length > 0 });

  const showSubThemes = enableSubThemes && !!subThemesData && subThemesData.categorizedSubThemes.length > 0;

  /* === Format === */
  const formatEnabled = !!genreId;
  const formats = useGetFormats({ enabled: isOpen && formatEnabled });
  const formatOptions: ChipGroupProps['items'] = formats.data
    ? formats.data.formats.map((g) => ({
        value: g.id,
        label: g.name,
      }))
    : [];
  const showFormat = formatEnabled && formatOptions.length > 0;

  /* === Form Logic === */
  const disableApplyBtn = (showAge && !age) || (showGrade && !grade);
  const applyText = `Apply ${Object.keys(dirtyFields).length > 0 ? `(${Object.keys(dirtyFields).length})` : ''}`;
  const applyBtnNotice = showAge && !age ? `Age is required to apply these filters.` : `Grade is required to apply these filters.`;

  /* === Form Reset Logic === */
  /*
   * Note: depending on what changes in the filter form the resets will cascade down the useEffects.
   * These useEffects are organized in a top-down hierarchy.
   */
  useLayoutEffect(() => {
    setValue('instructionalFocusIds', []);
  }, [resourceType, setValue]);

  useEffect(() => {
    if (!showInstructionalFocuses) {
      setValue('instructionalFocusIds', []);
    }
  }, [showInstructionalFocuses, setValue]);

  useEffect(() => {
    if (instructionalFocusIds.length === 0) {
      setValue('instructionalUnitIds', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionalFocusIds.length, setValue]);

  // Note: must use the stringified version of the dependency here, as the reference to the dependency object from react-hook-form watch() which change with each render
  const useEffectInstructionalUnitDependency = JSON.stringify(instructionalFocusIds);
  useLayoutEffect(() => {
    setValue('filterLevelPrioritizedSkillIds', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useEffectInstructionalUnitDependency, setValue]);

  useEffect(() => {
    if (!showInstructionalUnits) {
      setValue('filterLevelPrioritizedSkillIds', []);
    }
  }, [showInstructionalUnits, setValue]);

  useEffect(() => {
    if (!showDecodableFormat) {
      setValue('decodableFormats', []);
    }
  }, [showDecodableFormat, setValue]);

  useLayoutEffect(() => {
    //TODO: non conforming is temporarily hidden until more books are added with a 'Yes' for nonConforming (search for this comment to find places where non-conforming code was commented out)
    if (!enableHiLoNonConformingSection) {
      setValue('hiLo', null);
      // setValue('nonConforming', null); //<-- do not remove this commented code
      setValue('seriesId', null);
    } else {
      //Default values for hiLo and nonConforming
      setValue('hiLo', false, { shouldDirty: true });
      // setValue('nonConforming', false, { shouldDirty: true }); //<-- do not remove this commented code
    }
  }, [enableHiLoNonConformingSection, setValue]);

  useEffect(() => {
    if (!showSeries) {
      setValue('seriesId', null);
    }
  }, [showSeries, setValue]);

  useEffect(() => {
    if (!showAge) {
      setValue('age', null);
    }
  }, [showAge, setValue]);

  useEffect(() => {
    if (!showGrade) {
      setValue('grade', null);
    }
  }, [showGrade, setValue]);

  useEffect(() => {
    if (!showGenreAndSubGenre) {
      setValue('genreId', null);
      setValue('subGenreId', null);
    }
  }, [showGenreAndSubGenre, setValue]);

  useLayoutEffect(() => {
    setValue('subGenreId', null);
  }, [genreId, setValue]);

  useEffect(() => {
    if (!showSubGenres) {
      setValue('subGenreId', null);
    }
  }, [showSubGenres, setValue]);

  useEffect(() => {
    if (!showTopics) {
      setValue('topicIds', []);
    }
  }, [showTopics, setValue]);

  useEffect(() => {
    if (!showSubTopics) {
      setValue('subTopicIds', []);
    }
  }, [showSubTopics, setValue]);

  useEffect(() => {
    if (!showThemes) {
      setValue('themeIds', []);
    }
  }, [showThemes, setValue]);

  useEffect(() => {
    if (!showSubThemes) {
      setValue('subThemeIds', []);
    }
  }, [showSubThemes, setValue]);

  useEffect(() => {
    if (!showFormat) {
      setValue('formatId', null);
    }
  }, [showFormat, setValue]);

  /* === Form Action Handlers === */
  const onSubmit = (data: FilterForm) => {
    // Removing the empty and null filters to apply to the library query

    const selectedFilters = Object.entries(data)
      .filter((filter) => filter[1] !== null && filter[1] !== undefined && (Array.isArray(filter[1]) ? filter[1].length > 0 : [filter[1]].length > 0))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]: curr[1],
        };
      }, {});

    onLibraryQueryChanged({
      ...getDefaultLibraryQuery(paginationMode, isSC10263Active, shelfTypeToLibraryTabDictionary[query.shelfType]),
      ...selectedFilters,
      title: query.title,
      bookCollectionIds: query.bookCollectionIds,
    });
    onCloseFilterDrawer();
  };

  const onClose = () => {
    reset(defaultFormValues);
    onCloseFilterDrawer();
  };

  const resetToCleanState = () => {
    reset({
      resourceType: null,
      resourceStateValues: [],
      resourceProgressValues: [],
      instructionalFocusIds: [],
      instructionalUnitIds: [],
      filterLevelPrioritizedSkillIds: [],
      decodableFormats: [],
      hiLo: null,
      nonConforming: null,
      seriesId: null,
      age: null,
      grade: null,
      genreId: null,
      subGenreId: null,
      topicIds: [],
      subTopicIds: [],
      themeIds: [],
      subThemeIds: [],
      formatId: null,
      isInstructionalLibrary: doesStudentHaveInstructionalLibrary,
    });
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} sx={{ zIndex: zIndexes.libraryFilterDrawer }}>
      <Box sx={{ position: 'relative', width: '600px', height: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              alignContent: 'center',
              justifyContent: 'space-between',
              px: theme.spacing(3),
              py: theme.spacing(2),
              height: TITLE_DIV_HEIGHT,
              boxSizing: 'border-box',
            })}
          >
            <HootTypography isPII={false} variant="titlelarge">
              Filters
            </HootTypography>
            <Tooltip title={'Close'}>
              <span>
                <IconButton variant="standard" onClick={onClose}>
                  <Icon name="close" />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Stack
            sx={(theme) => ({
              position: 'absolute',
              top: TITLE_DIV_HEIGHT,
              bottom: BUTTON_DIV_HEIGHT,
              left: 0,
              right: 0,
              px: theme.spacing(3),
              overflowY: 'auto',
              mr: '16px',
            })}
          >
            {showResourceStateAndProgress ? (
              <>
                <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                  <Controller
                    name="resourceStateValues"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipGroup
                        label="Resource State"
                        items={resourceStateValues}
                        onChange={onChange}
                        value={value}
                        showBorderDecoration={false}
                        isMultiSelect
                      />
                    )}
                  />
                </Stack>
                <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                  <Controller
                    name="resourceProgressValues"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipGroup
                        label="Resource Progress"
                        items={resourceProgressValues}
                        onChange={onChange}
                        value={value}
                        showBorderDecoration={false}
                        isMultiSelect
                      />
                    )}
                  />
                </Stack>
              </>
            ) : null}

            <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
              <Controller
                name="resourceType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ChipGroup
                    label="Resource Type"
                    items={isSC10263Active ? bookTypeOptionsFiltered : bookTypeOptions}
                    onChange={onChange}
                    value={value}
                    showBorderDecoration={false}
                  />
                )}
              />
            </Stack>

            {showInstructionalFocuses ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="instructionalFocusIds"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ChipGroup
                      isMultiSelect
                      label="Resource Instructional Focus"
                      items={instructionalFocusesOptions}
                      onChange={onChange}
                      value={value}
                      showBorderDecoration={false}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {enableHiLoNonConformingSection ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                {showHiLo ? (
                  <Controller
                    name="hiLo"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipGroup
                        label="Hi-Lo"
                        items={trueFalseOptions}
                        onChange={onChange}
                        value={value}
                        showBorderDecoration={false}
                        cannotRemoveSelection
                      />
                    )}
                  />
                ) : null}

                {showNonConforming ? (
                  <Controller
                    name="nonConforming"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipGroup
                        label="Non-Conforming (NC)"
                        items={trueFalseOptions}
                        onChange={onChange}
                        value={value}
                        showBorderDecoration={false}
                        cannotRemoveSelection
                      />
                    )}
                  />
                ) : null}
              </Stack>
            ) : null}

            {showAge ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="age"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ChipGroup
                      label="Age"
                      items={ageOptions}
                      onChange={onChange}
                      value={value}
                      showBorderDecoration={false}
                      error={!age}
                      requiredField
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showGrade ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="grade"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ChipGroup
                      label="Grade"
                      items={gradeOptions}
                      onChange={onChange}
                      value={value}
                      showBorderDecoration={false}
                      error={!grade}
                      requiredField
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showInstructionalUnits ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="instructionalUnitIds"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Instructional Unit"
                      addMoreBtnLabel="Add Unit"
                      addMoreTitle="Select Instructional Unit(s)"
                      onChange={onChange}
                      value={value}
                      addMoreOptions={instructionalUnitsData.categorizedInstructionalUnits}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showPrioritizedSkills ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="filterLevelPrioritizedSkillIds"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Prioritized Skills"
                      addMoreBtnLabel="Add Skill"
                      addMoreTitle="Select Prioritized Skill(s)"
                      onChange={onChange}
                      value={value}
                      addMoreOptions={filterLevelPrioritizedSkillsData.categorizedPrioritizedSkills}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showSeries ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="seriesId"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Series"
                      addMoreBtnLabel="Add Series"
                      addMoreTitle="Select Series"
                      onChange={onChange}
                      value={value}
                      singleSelect
                      addMoreOptions={seriesData.categorizedSeries}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showDecodableFormat ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="decodableFormats"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ChipGroup
                      isMultiSelect
                      label="Decodable Format"
                      items={decodableFormatOptions}
                      onChange={onChange}
                      value={value}
                      showBorderDecoration={false}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showGenreAndSubGenre ? (
              <>
                <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                  <Controller
                    name="genreId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipGroup label="Genre" items={genreOptions} onChange={onChange} value={value} showBorderDecoration={false} />
                    )}
                  />
                </Stack>
                {showSubGenres ? (
                  <Controller
                    name="subGenreId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipGroup label="Sub Genre" items={subGenreOptions} onChange={onChange} value={value} showBorderDecoration={false} />
                    )}
                  />
                ) : null}
              </>
            ) : null}

            {showTopics ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="topicIds"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Topics"
                      addMoreBtnLabel="Add Topic"
                      addMoreTitle="Select Topic(s)"
                      onChange={onChange}
                      value={value}
                      addMoreOptions={topicsData.categorizedTopics}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showSubTopics ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="subTopicIds"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Sub Topics"
                      addMoreBtnLabel="Add Sub Topic"
                      addMoreTitle="Select Sub Topic(s)"
                      onChange={onChange}
                      value={value}
                      addMoreOptions={subTopicsData.categorizedSubTopics}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showThemes ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="themeIds"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Theme"
                      addMoreBtnLabel="Add Theme"
                      addMoreTitle="Select Theme(s)"
                      onChange={onChange}
                      value={value}
                      addMoreOptions={themesData.categorizedThemes}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showSubThemes ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="subThemeIds"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <LibraryFilterChipGroup
                      label="Sub Theme"
                      addMoreBtnLabel="Add Sub Theme"
                      addMoreTitle="Select Sub Theme(s)"
                      onChange={onChange}
                      value={value}
                      addMoreOptions={subThemesData.categorizedSubThemes}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Stack>
            ) : null}

            {showFormat ? (
              <Stack sx={(theme) => ({ gap: theme.spacing(7) })} direction="row">
                <Controller
                  name="formatId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ChipGroup label="Format" items={formatOptions} onChange={onChange} value={value} showBorderDecoration={false} />
                  )}
                />
              </Stack>
            ) : null}
          </Stack>

          <Box
            sx={(theme) => ({
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: 'space-between',
              px: theme.spacing(3),
              py: theme.spacing(2),
              ...hootTokens.elevation.get(-4),
              gap: theme.spacing(1),
              height: BUTTON_DIV_HEIGHT,
              boxSizing: 'border-box',
            })}
          >
            <Tooltip title={disableApplyBtn ? applyBtnNotice : applyText}>
              <div style={{ flex: 2 }}>
                <Button variant="contained" color="success.60" sx={{ flex: 3, width: '100%' }} type="submit" disabled={disableApplyBtn}>
                  {applyText}
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={'Reset Filters'}>
              <div style={{ flex: 1 }}>
                <Button variant="text" sx={{ color: hootTokens.palette.error[80], flex: 1, width: '100%' }} onClick={resetToCleanState}>
                  Reset Filters
                </Button>
              </div>
            </Tooltip>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default TeacherLibraryFilterDrawer;
