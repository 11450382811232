import { Stack, StackProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { Hra } from '@hoot/redux/reducers/hraSlice';
import AssessmentTextUnit from '@hoot/ui/components/v2/assessments/student/AssessmentTextUnit';
import AssessmentUnitContainer, { AssessmentUnitContainerProps } from '@hoot/ui/components/v2/assessments/student/AssessmentUnitContainer';
import StudentLessonReaderPage from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentLessonReaderPage';
import { UnitIdentifierHRAV2 } from '../../../../../models/api/enums/hoot-reading-assessment';
import { RootState } from '../../../../../redux/store';
import { AssessmentImage } from '../../../../components/v2/assessments/student/AssessmentImage';

interface HRAUnitProps extends AssessmentUnitContainerProps {
  hra: Hra;
}

const HRAUnit = (props: HRAUnitProps) => {
  const { hra, ...hraContainerProps } = props;

  const { currentUnitIdentifier: currentUnit, text, isUnitComplete } = hra;

  if (isUnitComplete) {
    return (
      <AssessmentUnitContainer {...hraContainerProps}>
        <AssessmentImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} />
      </AssessmentUnitContainer>
    );
  }

  switch (currentUnit) {
    case UnitIdentifierHRAV2.U_1_1:
      return (
        <AssessmentUnitContainer {...hraContainerProps}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/segmentation.svg'} />
        </AssessmentUnitContainer>
      );
    case UnitIdentifierHRAV2.U_1_2:
      return (
        <AssessmentUnitContainer {...hraContainerProps}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/blending.svg'} />
        </AssessmentUnitContainer>
      );
    case UnitIdentifierHRAV2.U_1_3:
      return (
        <AssessmentUnitContainer {...hraContainerProps}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/deletion.svg'} />
        </AssessmentUnitContainer>
      );
    case UnitIdentifierHRAV2.U_1_4:
      return (
        <AssessmentUnitContainer {...hraContainerProps}>
          <AssessmentImage src={'/images/progress-monitoring-assessment/substitution.svg'} />
        </AssessmentUnitContainer>
      );
    case UnitIdentifierHRAV2.U_1_5:
      return (
        <AssessmentUnitContainer {...hraContainerProps}>
          <AssessmentTextUnit text={text ?? ''} isLetterUnit={true} />
        </AssessmentUnitContainer>
      );
    case UnitIdentifierHRAV2.U_2_1:
    case UnitIdentifierHRAV2.U_2_2:
    case UnitIdentifierHRAV2.U_2_3:
    case UnitIdentifierHRAV2.U_2_4:
    case UnitIdentifierHRAV2.U_2_5:
    case UnitIdentifierHRAV2.U_2_6:
    case UnitIdentifierHRAV2.U_3_1:
    case UnitIdentifierHRAV2.U_3_2:
    case UnitIdentifierHRAV2.U_3_3:
    case UnitIdentifierHRAV2.U_3_4:
    case UnitIdentifierHRAV2.U_3_5:
    case UnitIdentifierHRAV2.U_3_6:
      return (
        <AssessmentUnitContainer {...hraContainerProps}>
          <AssessmentTextUnit text={text ?? ''} />
        </AssessmentUnitContainer>
      );
    case UnitIdentifierHRAV2.U_4_2:
      return (
        <AssessmentUnitContainer>
          <AssessmentImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} />
        </AssessmentUnitContainer>
      );

    case UnitIdentifierHRAV2.U_4_1:
    case UnitIdentifierHRAV2.U_4_3:
    case UnitIdentifierHRAV2.U_4_4:
      return <StudentLessonReaderPage />;
    default: // end
      return (
        <AssessmentUnitContainer>
          <AssessmentImage src={'/images/progress-monitoring-assessment/WayToGo-OptimizedForWeb.png'} />
        </AssessmentUnitContainer>
      );
  }
};

interface StudentHRAPageProps extends AssessmentUnitContainerProps {
  sx?: StackProps['sx'];
}

const StudentHRAPage = (props: StudentHRAPageProps) => {
  const { sx, ...assessmentUnitContainerProps } = props;
  const hra = useSelector((state: RootState) => state.hra.hra);

  return <Stack sx={{ height: '100%', padding: 2, ...(sx ?? {}) }}>{hra && <HRAUnit {...assessmentUnitContainerProps} hra={hra} />}</Stack>;
};

export default StudentHRAPage;
