import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Unit_4_1_SubmissionData } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import {
  Questionnaire4Submission,
  QuestionnaireLayout4,
} from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout4';
import { pmaInstructions, pmaTitles } from '../enum';
import useTeacherPMAController from '../useTeacherPMAController';

interface PMA_QUESTIONNAIRE_LAYOUT_4_Props {
  unitIdentifier: PMAUnitIdentifier.Module_4_Unit_1;
}

export function PMA_QUESTIONNAIRE_LAYOUT_4(props: PMA_QUESTIONNAIRE_LAYOUT_4_Props) {
  const { unitIdentifier } = props;
  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const assessmentID = useSelector((state: RootState) => state.pma.assessmentId);
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const pmaController = useTeacherPMAController();
  const submitPMAUnitRequest = useSubmitPMAUnit(assessmentID, unitIdentifier);
  const dispatch = useAppDispatch();

  const defaultWordCount = useSelector((state: RootState) => state.readers.inLessonReader.book?.wordCount);

  const submissionDataRequest = useSearchPMAUnitSubmissionData(assessmentID!, unitIdentifier, {
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const handleAllQuestionsAnswered = () => {
    pmaController.onUnitComplete(true);
  };

  const onPreviousUnitClick = () => {
    if (isDirty) {
      setLeaveDialog(true);
    } else {
      pmaController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    pmaController.onPrevUnit();
  };

  const handleSubmit = (data: Questionnaire4Submission) => {
    const subData: Unit_4_1_SubmissionData = {
      time: data.time,
      accuracyRate: data.accuracyRate,
      bookId: data.bookId,
      errors: data.errors,
      wcpm: data.wcpm,
      wordCount: data.wordCount,
    };

    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: inLesson!.studentId,
      teacherAccountId: inLesson!.teacherId,
      submissionData: subData,
      lessonId: inLesson!.lessonId,
    };

    submitPMAUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  const submissionData = submissionDataRequest.data?.submissionData as unknown as Unit_4_1_SubmissionData | undefined;

  const defaultFormValues = useMemo(() => {
    if (!submissionData) {
      return {
        errors: 0,
        wordCount: defaultWordCount ?? 0,
        time: '',
      };
    }

    return {
      time: submissionData.time,
      wordCount: submissionData.wordCount ?? defaultWordCount,
      errors: submissionData.errors,
    };
  }, [submissionData, defaultWordCount]);

  if (submissionDataRequest.isLoading) {
    return null;
  }

  return (
    <QuestionnaireLayout4
      title="Progress Monitoring Assessment"
      unitIdentifier={unitIdentifier}
      unitTitle={pmaTitles[unitIdentifier]}
      instruction={pmaInstructions[unitIdentifier]}
      value={defaultFormValues ?? undefined}
      isSubmitting={submissionDataRequest.isLoading}
      isNextEnabled={isNextEnabled}
      isPrevEnabled={isPrevEnabled}
      isUnitQuestionnaireDirty
      leaveDialog={leaveDialog}
      onSubmit={handleSubmit}
      confirmPrevUnitClick={confirmPrevUnitClick}
      onPreviousUnitClick={onPreviousUnitClick}
      onAllQuestionsAnswered={handleAllQuestionsAnswered}
      setLeaveDialog={setLeaveDialog}
      isDirty={isDirty}
      setIsDirty={setIsDirty}
      onExitAssessment={pmaController.onExitAssessment}
    />
  );
}
