import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HRASyncMessage } from '../../events/messages/hra-sync.message';
import { FormVariant } from '../../hooks/api/assessment/useGetAssessmentUnit';
import { UnitIdentifierHRAV2 } from '../../models/api/enums/hoot-reading-assessment';

export interface HRAState {
  hra: Hra | null;
}

export interface Hra {
  assessmentId: string;
  formVariant: FormVariant;
  currentUnitId: string;
  currentUnitIdentifier: UnitIdentifierHRAV2;
  text?: string;
  isUnitComplete: boolean;
  isPrevEnabled: boolean;
}

const initialState: HRAState = {
  hra: null,
};

export const hraSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    handleSyncHRA: (state, action: PayloadAction<HRASyncMessage>) => {
      state.hra = {
        ...action.payload,
      };
    },
  },
});

export const { handleSyncHRA } = hraSlice.actions;

export default hraSlice.reducer;
