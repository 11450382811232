import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MONTH_DAY_YEAR_SHORT } from '@hoot/constants/constants';
import useGetStudentHootLevels from '@hoot/hooks/api/my-student/getStudentHootLevels';
import useGetStudentTimeline, { StudentTimelineRequest, TimelineFilterType, TimelineViewOption } from '@hoot/hooks/api/my-student/getStudentTimeline';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import {
  eventVisibilityLabels,
  focusVisibilityLabels,
  periodBasedLabels,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/common';
import EventVisibilityMenu, { EventOptionsType } from './EventVisibilityMenu';
import FocusVisibilityMenu, { FocusOptionType } from './FocusVisibilityMenu';
import TimelineFilterDialog, { TimelineFilterFormValues } from './TimelineFilterDialog';
import TimelineGrid from './TimelineGrid';
import TimelineLegendDialog from './TimelineLegendDialog';
import { EventVisibilityOption, FilterTypeValues, FocusVisibilityOption, PeriodBasedValues } from './enums';

const StudentTimelinePage = () => {
  const { studentProfileId } = useParams();

  // check student levels before populating filters/
  const { data: hootLevels } = useGetStudentHootLevels(studentProfileId ?? '', {
    onSuccess: (data) => {
      const focusVisibilityOptions = [
        data.moduleOneInProgress ? FocusVisibilityOption.PreWordReading : undefined,
        data.moduleTwoInProgress ? FocusVisibilityOption.EarlyWordReading : undefined,
        data.moduleThreeInProgress ? FocusVisibilityOption.ComplexReading : undefined,
        data.hasAttemptedTextReading ? FocusVisibilityOption.TextReading : undefined,
        data.hasAttemptedTextLanguage ? FocusVisibilityOption.TextLanguage : undefined,
      ].filter((option) => !!option);
      setQuery({
        filterType: TimelineFilterType.DateBased,
        viewOption: TimelineViewOption.None,
        eventVisibility: [EventVisibilityOption.Hra, EventVisibilityOption.Pma, EventVisibilityOption.Lessons],
        focusVisibility: focusVisibilityOptions,
      });
      setFocusOptions({
        [FocusVisibilityOption.PreWordReading]: data.moduleOneInProgress,
        [FocusVisibilityOption.EarlyWordReading]: data.moduleTwoInProgress,
        [FocusVisibilityOption.ComplexReading]: data.moduleThreeInProgress,
        [FocusVisibilityOption.TextLanguage]: data.hasAttemptedTextLanguage,
        [FocusVisibilityOption.TextReading]: data.hasAttemptedTextReading,
        [FocusVisibilityOption.OtherAreas]: false,
      });
    },
  });

  const [isTlFilterDialogOpen, setIsTlFilterDialogOpen] = useState<boolean>(false);
  const [tlFilterOptions, setTlFilterOptions] = useState<TimelineFilterFormValues>({
    filterType: FilterTypeValues.DateBased,
    startDate: null,
    endDate: null,
    viewOption: null,
  });

  const [isFocusVisibilityOpen, setIsFocusVisibilityOpen] = useState<HTMLButtonElement | null>(null);
  const [focusOptions, setFocusOptions] = useState<FocusOptionType>({
    [FocusVisibilityOption.PreWordReading]: false,
    [FocusVisibilityOption.EarlyWordReading]: false,
    [FocusVisibilityOption.ComplexReading]: false,
    [FocusVisibilityOption.TextLanguage]: false,
    [FocusVisibilityOption.TextReading]: false,
    [FocusVisibilityOption.OtherAreas]: false,
  });

  const [isEventVisibilityOpen, setIsEventVisibilityOpen] = useState<HTMLButtonElement | null>(null);
  const [eventOptions, setEventOptions] = useState<EventOptionsType>({
    [EventVisibilityOption.Hra]: true,
    [EventVisibilityOption.Pma]: true,
    [EventVisibilityOption.Absence]: false,
    [EventVisibilityOption.Cancellation]: false,
    [EventVisibilityOption.Lessons]: true,
  });

  const [query, setQuery] = useState<StudentTimelineRequest>({
    filterType: TimelineFilterType.DateBased,
    viewOption: TimelineViewOption.None,
    eventVisibility: [EventVisibilityOption.Hra, EventVisibilityOption.Pma, EventVisibilityOption.Lessons],
  });

  const [isTimelineLegendOpen, setIsTimelineLegendOpen] = useState<boolean>(false);

  const toggleTimelineFilter = () => setIsTlFilterDialogOpen((prev) => !prev);

  const onFocusVisibilityClick = (e: React.MouseEvent<HTMLButtonElement>) => setIsFocusVisibilityOpen(e.currentTarget);
  const onDismissFocusVisibility = () => setIsFocusVisibilityOpen(null);

  const onEventVisibilityClick = (e: React.MouseEvent<HTMLButtonElement>) => setIsEventVisibilityOpen(e.currentTarget);
  const onDismissEventVisibility = () => setIsEventVisibilityOpen(null);

  const toggleLegendDialog = () => setIsTimelineLegendOpen((prev) => !prev);

  const { data: timelineData, isFetching } = useGetStudentTimeline(studentProfileId ?? '', query, {
    enabled: !!studentProfileId && !!hootLevels,
  });

  const handleTimelineFilterApply = (timelineFilter: TimelineFilterFormValues) => {
    setTlFilterOptions(timelineFilter);
    setQuery({
      ...query,
      filterType: timelineFilter.filterType as TimelineFilterType,
      viewOption: (timelineFilter.viewOption as TimelineViewOption) ?? TimelineViewOption.None,
      fromDate: timelineFilter.startDate?.toMillis(),
      toDate: timelineFilter.endDate?.toMillis(),
    });
  };

  const handleDeleteFilterChip = () => {
    setTlFilterOptions({
      filterType: TimelineFilterType.DateBased,
      viewOption: null,
      startDate: null,
      endDate: null,
    });
    setQuery((query) => ({
      ...query,
      filterType: TimelineFilterType.DateBased,
      viewOption: TimelineViewOption.None,
      startDate: null,
      endDate: null,
    }));
  };

  const handleDeleteEventChip = (option: EventVisibilityOption) => {
    const currentEventOptions = eventOptions;
    const updatedEventOptions = { ...currentEventOptions, [option]: !currentEventOptions[option] };
    setEventOptions(updatedEventOptions);

    const eventVisibilityOptions = Object.keys(updatedEventOptions).filter(
      (key) => updatedEventOptions[key as EventVisibilityOption] === true,
    ) as EventVisibilityOption[];
    setQuery((query) => ({
      ...query,
      eventVisibility: eventVisibilityOptions,
    }));
  };

  const handleDeleteFocusChip = (option: FocusVisibilityOption) => {
    const currentFocusOptions = focusOptions;
    const updatedFocusOptions = { ...currentFocusOptions, [option]: !currentFocusOptions[option] };
    setFocusOptions(updatedFocusOptions);

    const focusVisibilityOptions = Object.keys(updatedFocusOptions).filter(
      (key) => updatedFocusOptions[key as FocusVisibilityOption] === true,
    ) as FocusVisibilityOption[];
    setQuery((query) => ({
      ...query,
      focusVisibility: focusVisibilityOptions,
    }));
  };

  return (
    <>
      <PageLayout>
        <Card title="Student Timeline" isLoading={isFetching}>
          <Stack gap={2}>
            <Stack direction="row" gap={2}>
              <Button onClick={toggleTimelineFilter} startIcon={<Icon name="calendar" />} variant="contained" color="neutral.180">
                Timeline Filter
              </Button>
              <Button onClick={onFocusVisibilityClick} startIcon={<Icon name="show" />} variant="contained" color="neutral.180">
                Focus Visibility
              </Button>
              <Button onClick={onEventVisibilityClick} startIcon={<Icon name="show" />} variant="contained" color="neutral.180">
                Event Visibility
              </Button>
              <Button onClick={toggleLegendDialog} variant="contained" color="neutral.180">
                {isTimelineLegendOpen ? 'Hide Legend' : 'Show Legend'}
              </Button>
            </Stack>

            {/* Chip row(s) */}
            <Box display="flex" flexWrap="wrap" gap={2}>
              {/* Timeline Filter Chips */}
              {tlFilterOptions.startDate && tlFilterOptions.endDate && (
                <Chip
                  label={`Timeline Date: 
                    ${tlFilterOptions.startDate.toFormat(MONTH_DAY_YEAR_SHORT)} - 
                    ${tlFilterOptions.endDate.toFormat(MONTH_DAY_YEAR_SHORT)}
                  `}
                  onDelete={handleDeleteFilterChip}
                  deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                  sx={{
                    background: '#000',
                    color: '#FFF',
                  }}
                />
              )}
              {tlFilterOptions.viewOption && (
                <Chip
                  label={`Timeline Option:
                    ${periodBasedLabels[tlFilterOptions.viewOption as PeriodBasedValues]}
                  `}
                  onDelete={handleDeleteFilterChip}
                  deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                  sx={{
                    background: '#000',
                    color: '#FFF',
                  }}
                />
              )}

              {/* Focus Chips */}
              {Object.entries(focusOptions).map(
                ([key, checked]) =>
                  checked && (
                    <Chip
                      key={key}
                      label={`Focus: ${focusVisibilityLabels[key as FocusVisibilityOption]}`}
                      onDelete={() => handleDeleteFocusChip(key as FocusVisibilityOption)}
                      deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                      sx={{
                        background: '#000',
                        color: '#FFF',
                      }}
                    />
                  ),
              )}

              {/* Event Chips */}
              {Object.entries(eventOptions).map(
                ([key, checked]) =>
                  checked && (
                    <Chip
                      key={key}
                      label={`Event: ${eventVisibilityLabels[key as EventVisibilityOption]}`}
                      onDelete={() => handleDeleteEventChip(key as EventVisibilityOption)}
                      deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                      sx={{
                        background: '#000',
                        color: '#FFF',
                      }}
                    />
                  ),
              )}
            </Box>
          </Stack>
          <TimelineGrid data={timelineData} studentProfileId={studentProfileId!} />
        </Card>
      </PageLayout>

      {/* Dialogs & Menus */}
      <TimelineFilterDialog open={isTlFilterDialogOpen} onDismiss={toggleTimelineFilter} onApply={handleTimelineFilterApply} />
      <FocusVisibilityMenu
        anchorElement={isFocusVisibilityOpen}
        onDismiss={onDismissFocusVisibility}
        focusOptions={focusOptions}
        setFocusOptions={setFocusOptions}
        setQuery={setQuery}
      />
      <EventVisibilityMenu
        anchorElement={isEventVisibilityOpen}
        onDismiss={onDismissEventVisibility}
        eventOptions={eventOptions}
        setEventOptions={setEventOptions}
        setQuery={setQuery}
      />
      <TimelineLegendDialog open={isTimelineLegendOpen} onDismiss={toggleLegendDialog} />
    </>
  );
};

export default StudentTimelinePage;
