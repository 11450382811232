import { Navigate, Route, Routes } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { AlreadyAuthenticatedSsoRequestHandler } from '@hoot/ui/components/v2/EdlinkSsoRedirectHandler';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import Logout from '@hoot/ui/components/v2/Logout';
import { ProvidersLayoutV2 } from '@hoot/ui/components/v2/layout/ProvidersLayoutV2';
import PublicApplicationLayout from '@hoot/ui/components/v2/layout/PublicApplicationLayout';
import StudentLibraryLayout from '@hoot/ui/components/v2/layout/StudentLibraryLayout';
import SelectProfilePage from '@hoot/ui/pages/v2/no-profile/SelectProfilePage';
import StudentHRAPage from '@hoot/ui/pages/v2/student/hoot-reading-assessment/StudentHRAPage';
import StudentInLessonLayout from '@hoot/ui/pages/v2/student/lesson/StudentInLessonLayout';
import StudentInLessonLibraryPage from '@hoot/ui/pages/v2/student/lesson/library/StudentInLessonLibraryPage';
import StudentLessonReaderPage from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentLessonReaderPage';
import StudentInLessonSoloBookReaderPage from '@hoot/ui/pages/v2/student/lesson/library/solo-book/reader/StudentInLessonSoloBookReaderPage';
import StudentInLessonWhiteboardPage from '@hoot/ui/pages/v2/student/lesson/whiteboard/StudentInLessonWhiteboardPage';
import StudentLibraryPage from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';
import StudentLibraryReaderPage from '@hoot/ui/pages/v2/student/library/reader/StudentLibraryReaderPage';
import StudentPMAPage from '@hoot/ui/pages/v2/student/progress-monitoring-assessment/StudentPMAPage';

const StudentRouter = () => {
  return (
    <Routes>
      <Route path={routesDictionary.login.path} element={<AlreadyAuthenticatedSsoRequestHandler />} />

      {/* Main Routes */}
      <Route element={<ProvidersLayoutV2 />}>
        <Route element={<StudentLibraryLayout />}>
          <Route path={routesDictionary.library.path}>
            <Route path={routesDictionary.library.book.path} element={<StudentLibraryReaderPage />} />
            <Route index element={<StudentLibraryPage />} />
          </Route>
        </Route>
        <Route path={routesDictionary.lesson.path} element={<StudentInLessonLayout />}>
          <Route path={routesDictionary.lesson.library.path}>
            <Route path={routesDictionary.lesson.library.book.path} element={<StudentLessonReaderPage />} />
            <Route path={routesDictionary.lesson.library.soloBook.path} element={<StudentInLessonSoloBookReaderPage />} />
            <Route index element={<StudentInLessonLibraryPage />} />
          </Route>
          <Route path={routesDictionary.lesson.whiteboard.path} element={<StudentInLessonWhiteboardPage />} />
          <Route path={routesDictionary.lesson.progressMonitoringAssessment.path} element={<StudentPMAPage />} />
          <Route path={routesDictionary.lesson.hootReadingAssessment.path} element={<StudentHRAPage />} />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PublicApplicationLayout />}>
        <Route path={routesDictionary.selectProfile.path} element={<SelectProfilePage />} />
        <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
      </Route>

      {/* Index & Catch-all Routes */}
      <Route index element={<Navigate replace to={routesDictionary.library.url} />} />
      <Route path={routesDictionary.logout.path} element={<Logout />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default StudentRouter;
