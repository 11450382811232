import { Box, Stack, StackProps } from '@mui/material';
import { featureFlags } from '@hoot/constants/featureFlags';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { LessonWhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { UserProfileType } from '@hoot/models/api/user';
import { LibraryTab, PaginationMode } from '@hoot/redux/reducers/librarySlice';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import LessonWhiteboardTemplates from '@hoot/ui/pages/v2/teacher/lesson/library/LessonWhiteboardTemplates';
import WhiteboardTemplatePreviewDialog from '@hoot/ui/pages/v2/teacher/lesson/library/WhiteboardTemplatePreviewDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import TeacherBookPreviewModal from './TeacherBookPreviewModal';
import TeacherLibraryFilterBar, { getDefaultLibraryQuery } from './TeacherLibraryFilterBar';
import TeacherLibraryGalleryView from './TeacherLibraryGalleryView';
import { TeacherLibraryTableView } from './TeacherLibraryTableView';

interface TeacherLibraryProps {
  libraryQuery: QueryLibraryV2;
  selectedTab: LibraryTab;
  isLoading: boolean;
  paginationMode: PaginationMode;
  librarySearchResults: TeacherLibrarySearchResults;
  isInLesson: boolean;
  // If we're looking at a student-specific library.
  studentProfileId?: string;
  previewBook: BookResponse | undefined;
  onDismissPreviewBook: (invalidateFavs: boolean) => void;
  onLibraryQueryChanged: (newQuery: QueryLibraryV2) => void;
  onPaginationModeChanged: (paginationMode: PaginationMode) => void;
  onLessonWhiteboardTemplateQueryChanged?: (query?: string) => void;
  onTabChanged: (tab: LibraryTab) => void;
  onFetchNextPage: () => void;
  onPreviewBook: (bookId: string) => void;
  onToggleFavouriteBook: (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => void;
  onOpenBook: (book: BookResponse, defaultPageId?: string) => void;
  // Whiteboards are only ever used in a lesson.
  lessonWhiteboardTemplates?: {
    query?: string;
    results: LessonWhiteboardTemplateResponse[];
    previewWhiteboardTemplate: LessonWhiteboardTemplateResponse | null;
    onLessonWhiteboardTemplateClicked: (whiteboardTemplate: LessonWhiteboardTemplateResponse) => void;
    onDismissWhiteboardTemplatePreview: () => void;
    onOpenWhiteboard: (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => void;
  };
  sx?: StackProps['sx'];
}

export interface TeacherLibrarySearchResults {
  page: number;
  pageSize: number;
  books: BookSearch[];
  total: number;
}

const TeacherLibrary = (props: TeacherLibraryProps) => {
  const {
    libraryQuery,
    selectedTab,
    isLoading,
    librarySearchResults,
    studentProfileId,
    paginationMode,
    isInLesson,
    previewBook,
    onFetchNextPage,
    onDismissPreviewBook,
    onLibraryQueryChanged,
    onPaginationModeChanged,
    onLessonWhiteboardTemplateQueryChanged,
    onTabChanged,
    onPreviewBook,
    onToggleFavouriteBook,
    onOpenBook,
    lessonWhiteboardTemplates,
    sx = {},
  } = props;

  const { isFeatureEnabled } = useFeatureFlags();
  const isSC10263Active = isFeatureEnabled(featureFlags.SC_10263_in_lesson_hra);

  const onFetchLibraryPage = (page: number) => {
    onLibraryQueryChanged({
      ...libraryQuery,
      page,
    });
  };

  const onLibraryPageSizeChanged = (pageSize: number) => {
    onLibraryQueryChanged({
      ...libraryQuery,
      page: 1,
      pageSize,
    });
  };

  const onClearLibraryFilters = () => {
    onLibraryQueryChanged({
      ...getDefaultLibraryQuery(paginationMode, isSC10263Active, selectedTab),
      // We're only clearing applied filters (UI); not the search text, nor book collections (even though this is technically a filter).
      title: libraryQuery.title,
      bookCollectionIds: libraryQuery.bookCollectionIds,
    });
  };

  const onClearFiltersAndSearch = () => {
    onLibraryQueryChanged({
      ...getDefaultLibraryQuery(paginationMode, isSC10263Active, selectedTab),
    });
  };

  const _onWhiteboardTemplateQueryChanged = (query?: string) => {
    onLessonWhiteboardTemplateQueryChanged?.(query);
  };

  const onClearSearch = () => {
    if (selectedTab === LibraryTab.Whiteboard) {
      _onWhiteboardTemplateQueryChanged();
    } else {
      onLibraryQueryChanged({
        ...libraryQuery,
        title: undefined,
      });
    }
  };

  const onPreviewPreviousBook = () => {
    if (!previewBook) {
      return;
    }
    const currentBookIndex = librarySearchResults.books.findIndex((book) => book.id === previewBook.id);
    const previousBookIndex = currentBookIndex - 1;

    if (previousBookIndex >= 0) {
      onPreviewBook(librarySearchResults.books[previousBookIndex].id);
    }
  };

  const onPreviewNextBook = () => {
    if (!previewBook) {
      return;
    }
    const currentBookIndex = librarySearchResults.books.findIndex((book) => book.id === previewBook.id);
    const nextBookIndex = currentBookIndex + 1;

    if (nextBookIndex < librarySearchResults.books.length) {
      onPreviewBook(librarySearchResults.books[nextBookIndex].id);
    }
  };

  return (
    <>
      <Stack overflow="hidden" flex={1} sx={{ ...(sx ?? {}), backgroundColor: hootTokens.surface[0] }}>
        <Box
          sx={{
            background: hootTokens.surface[0],
          }}
        >
          <TeacherLibraryFilterBar
            libraryQuery={libraryQuery}
            whiteboardTemplateQuery={lessonWhiteboardTemplates?.query}
            selectedTab={selectedTab}
            paginationMode={paginationMode}
            onLibraryQueryChanged={onLibraryQueryChanged}
            onWhiteboardTemplateQueryChanged={_onWhiteboardTemplateQueryChanged}
            isInLesson={isInLesson}
            onTabChanged={onTabChanged}
            onPaginationModeChanged={onPaginationModeChanged}
            studentProfileId={studentProfileId}
            onClearFilters={onClearLibraryFilters}
          />
        </Box>
        <Stack
          sx={(theme) => ({
            p: theme.spacing(3),
            overflow: 'auto',
            position: 'relative',
            backgroundColor: !studentProfileId ? hootTokens.palette.secondary[160] : hootTokens.surface[2],
            border: '1px solid',
            borderColor: hootTokens.surface[3],
            borderRadius: '8px',
            height: '100%',
          })}
        >
          {lessonWhiteboardTemplates && selectedTab === LibraryTab.Whiteboard ? (
            <LessonWhiteboardTemplates
              query={lessonWhiteboardTemplates.query}
              isLoading={isLoading}
              paginationMode={paginationMode}
              lessonWhiteboardTemplates={lessonWhiteboardTemplates.results}
              previewLessonWhiteboardTemplate={lessonWhiteboardTemplates.previewWhiteboardTemplate}
              onLessonWhiteboardTemplateClicked={lessonWhiteboardTemplates.onLessonWhiteboardTemplateClicked}
              onDismissWhiteboardTemplatePreview={lessonWhiteboardTemplates.onDismissWhiteboardTemplatePreview}
              onClearSearch={onClearSearch}
            />
          ) : paginationMode === PaginationMode.Append ? (
            <TeacherLibraryGalleryView
              query={libraryQuery}
              isLoadingBooks={isLoading}
              librarySearchResults={librarySearchResults}
              studentProfileId={studentProfileId}
              handleBookClick={onPreviewBook}
              onFetchNextPage={onFetchNextPage}
              onClearSearch={onClearSearch}
              onClearFiltersAndSearch={onClearFiltersAndSearch}
            />
          ) : (
            <TeacherLibraryTableView
              query={libraryQuery}
              isLoadingBooks={isLoading}
              librarySearchResults={librarySearchResults}
              studentProfileId={studentProfileId}
              handleBookClick={onPreviewBook}
              onPageSizeChanged={onLibraryPageSizeChanged}
              onFetchPage={onFetchLibraryPage}
            />
          )}
        </Stack>
      </Stack>

      {/* Dialogs */}
      {previewBook && (
        <TeacherBookPreviewModal
          show={!!previewBook}
          book={previewBook}
          query={libraryQuery}
          studentProfileId={studentProfileId}
          isInLesson={isInLesson}
          onDismiss={onDismissPreviewBook}
          onOpenBook={onOpenBook}
          onLibraryQueryChanged={onLibraryQueryChanged}
          onToggleFavouriteBook={onToggleFavouriteBook}
          onPreviewPreviousBook={onPreviewPreviousBook}
          onPreviewNextBook={onPreviewNextBook}
        />
      )}

      {lessonWhiteboardTemplates?.previewWhiteboardTemplate && (
        <WhiteboardTemplatePreviewDialog
          currentTemplate={lessonWhiteboardTemplates.previewWhiteboardTemplate}
          onOpenWhiteboard={lessonWhiteboardTemplates.onOpenWhiteboard}
          onDismiss={lessonWhiteboardTemplates.onDismissWhiteboardTemplatePreview}
        />
      )}
    </>
  );
};

export default TeacherLibrary;
