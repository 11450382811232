import { SvgIcon, SvgIconProps } from '@mui/material';

const DotsIcon = (props: SvgIconProps) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M13.1102 16.084C12.8622 16.084 12.6542 16 12.4862 15.832C12.3182 15.664 12.2342 15.456 12.2342 15.208C12.2342 14.96 12.3182 14.752 12.4862 14.584C12.6542 14.416 12.8622 14.332 13.1102 14.332C13.3502 14.332 13.5542 14.416 13.7222 14.584C13.8902 14.752 13.9742 14.96 13.9742 15.208C13.9742 15.456 13.8902 15.664 13.7222 15.832C13.5542 16 13.3502 16.084 13.1102 16.084ZM16.0047 16.084C15.7567 16.084 15.5487 16 15.3807 15.832C15.2127 15.664 15.1287 15.456 15.1287 15.208C15.1287 14.96 15.2127 14.752 15.3807 14.584C15.5487 14.416 15.7567 14.332 16.0047 14.332C16.2447 14.332 16.4487 14.416 16.6167 14.584C16.7847 14.752 16.8687 14.96 16.8687 15.208C16.8687 15.456 16.7847 15.664 16.6167 15.832C16.4487 16 16.2447 16.084 16.0047 16.084ZM18.8993 16.084C18.6513 16.084 18.4433 16 18.2753 15.832C18.1073 15.664 18.0233 15.456 18.0233 15.208C18.0233 14.96 18.1073 14.752 18.2753 14.584C18.4433 14.416 18.6513 14.332 18.8993 14.332C19.1393 14.332 19.3433 14.416 19.5113 14.584C19.6793 14.752 19.7633 14.96 19.7633 15.208C19.7633 15.456 19.6793 15.664 19.5113 15.832C19.3433 16 19.1393 16.084 18.8993 16.084Z"
      fill="#808080"
    />
  </SvgIcon>
);

export default DotsIcon;
