import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { teacherShiftStatusLookup } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Drawer, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { TeacherAccountShiftResponse, useGetTeacherShifts } from '@hoot/hooks/api/availability/useGetTeacherShifts';
import { useGetTeacherAvailabilityExceptions } from '@hoot/hooks/api/availability/useTeacherAvailabilityException';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { cancellationReasonMessageLookup, lessonStatusesForCustomersLookup } from '@hoot/ui/pages/v2/teacher/schedule/enum';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { chunkTimeSpan } from '@hoot/utils/date';
import useDeleteTeacherAvailabilityException from '../../../../../hooks/api/availability/useDeleteTeacherAvailabilityException';
import useGetLessons, { LessonSearchResponse } from '../../../../../hooks/api/lesson/useGetLessons';
import Card from '../../../../components/v2/core/Card';
import IconButton from '../../../../components/v2/core/IconButton';
import ReadOnlyTextField from '../../../../components/v2/core/ReadOnlyTextField';

/** Interfaces */

interface TimeOffEvent {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
}

/** Components */
export function TeacherScheduleDrawer(props: {
  open?: boolean;
  onClose: () => void;
  selectedDate?: DateTime;
  showCancelledEvents?: boolean;
  onShowCancelledEventsClick?: () => void;
}) {
  const [selectedDate, setSelectedDate] = useState(props.selectedDate ?? DateTime.now());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showDrawerMenu = Boolean(anchorEl);

  const startOfDay = selectedDate.startOf('day');
  const endOfDay = selectedDate.endOf('day');

  const lessonsRequest = useGetLessons({
    fromDate: startOfDay.toMillis(),
    toDate: endOfDay.toMillis(),
  });

  const getTeacherShiftsRequest = useGetTeacherShifts({
    startsAt: startOfDay.toMillis(),
    endsAt: endOfDay.toMillis(),
  });

  const availabilityExceptionsRequest = useGetTeacherAvailabilityExceptions();

  const timeoff =
    availabilityExceptionsRequest.data
      ?.flatMap<TimeOffEvent>((exception) =>
        chunkTimeSpan(DateTime.fromMillis(exception.startsAt), DateTime.fromMillis(exception.endsAt)).map<TimeOffEvent>((ts) => ({
          id: exception.id,
          startTime: ts.startsAt,
          endTime: ts.endsAt,
        })),
      )
      .filter((ae) => ae.startTime >= startOfDay && ae.endTime <= endOfDay) ?? [];

  const handleGearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setAnchorEl(null);
  };

  const handlePrevClick = () => {
    setSelectedDate((currentDate) => currentDate.minus({ day: 1 }));
  };

  const handleNextClick = () => {
    setSelectedDate((currentDate) => currentDate.plus({ day: 1 }));
  };

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <Stack padding="16px" sx={{ width: 380 }} role="presentation">
        <Stack direction="row" justifyContent="space-between">
          <IconButton variant="contained" onClick={props.onClose}>
            <Icon htmlColor={hootTokens.palette.white} name="close" />
          </IconButton>
          <IconButton onClick={handleGearClick}>
            <Icon name="gear" />
          </IconButton>
          <DrawerMenu
            open={showDrawerMenu}
            anchorEl={anchorEl}
            onClose={handleDrawerClose}
            showCancelledEvents={props.showCancelledEvents}
            onShowCancelledEventsClick={props.onShowCancelledEventsClick}
          />
        </Stack>
        <Stack marginTop="24px" direction="row" justifyContent="space-between">
          <IconButton onClick={handlePrevClick}>
            <Icon name="chevron" />
          </IconButton>
          <Stack alignItems="center">
            <Typography variant="displaysmall">{selectedDate.toFormat('LLLL d')}</Typography>
            <Typography variant="bodylarge">{selectedDate.toFormat('cccc')}</Typography>
          </Stack>
          <IconButton onClick={handleNextClick}>
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
        <DrawerShifts shifts={getTeacherShiftsRequest.data?.shifts} />
        <DrawerEvents lessons={lessonsRequest.data?.data} timeoff={timeoff} showCancelledEvents={props.showCancelledEvents ?? true} />
      </Stack>
    </Drawer>
  );
}

function DrawerMenu(props: {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  showCancelledEvents?: boolean;
  onShowCancelledEventsClick?: () => void;
}) {
  const handleShowCancelledEvents = () => {};

  return (
    <Menu
      id="teacher-schedule-drawer"
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleShowCancelledEvents}>
        <Checkbox onClick={props.onShowCancelledEventsClick} checked={props.showCancelledEvents} label="Show cancelled events" />
      </MenuItem>
    </Menu>
  );
}

function DrawerShifts(props: { shifts?: TeacherAccountShiftResponse[] }) {
  if (!props.shifts?.length) {
    return null;
  }

  return (
    <Stack>
      {props.shifts.length > 0 ? (
        <Card sx={{ marginTop: '16px' }} title="Shifts">
          <Stack gap={2}>
            {props.shifts.map((shift) => (
              <Stack key={shift.id} sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                <Typography>
                  {DateTime.fromMillis(shift.startsAt).toFormat('h:mm a')} - {DateTime.fromMillis(shift.endsAt).toFormat('h:mm a')}
                </Typography>
                <Typography>{teacherShiftStatusLookup[shift.status]}</Typography>
              </Stack>
            ))}
          </Stack>
        </Card>
      ) : null}
    </Stack>
  );
}

function DrawerEvents(props: { lessons?: LessonSearchResponse[]; timeoff?: TimeOffEvent[]; showCancelledEvents: boolean }) {
  if (!props.lessons?.length && !props.timeoff?.length) {
    return null;
  }
  const events =
    props.lessons?.filter((l) =>
      props.showCancelledEvents ? l : ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.status),
    ) ?? [];

  const timeOffEvents = props.timeoff ?? [];

  if (events.length === 0 && timeOffEvents.length === 0) return null;

  return (
    <Stack marginTop="16px">
      <Typography variant="titlemedium">Events</Typography>
      <Stack>
        {events.map((l) => (
          <LessonEventCard lesson={l} />
        ))}
        {timeOffEvents.map((to) => (
          <TimeOffEventCard timeoffEvent={to} />
        ))}
      </Stack>
    </Stack>
  );
}

function LessonEventCard(props: { lesson: LessonSearchResponse }) {
  const dispatch = useAppDispatch();

  function filledColour(): ColorPaletteOption {
    switch (props.lesson.status) {
      case ScheduledLessonStatus.CompletedSuccessfully:
      case ScheduledLessonStatus.CompletedUnsuccessfully:
        return 'success.190';
      case ScheduledLessonStatus.Rescheduled:
      case ScheduledLessonStatus.Cancelled:
        return 'error.190';
      default:
        return 'secondary.190';
    }
  }

  const colour = filledColour();

  const onCopyLessonNumber = () => {
    const lessonNumber = props.lesson.prefixedLessonNumber;
    navigator.clipboard.writeText(lessonNumber);
    dispatch(createFlashMessage({ message: `Copied "${lessonNumber}" to clipboard.` }));
  };

  const studentFieldBody = () => {
    const isAccessible = props.lesson.studentId && props.lesson.studentName;
    return isAccessible ? (
      <span>
        <Link
          to={routesDictionary.myStudents.details.profile.url(props.lesson.studentId!)}
          state={{ navigateBackPath: routesDictionary.schedule.url }}
        >
          <HootTypography isPII={true} sx={{ display: 'inherit' }}>
            {props.lesson.studentName} ({props.lesson.prefixedStudentNumber})
          </HootTypography>
        </Link>{' '}
      </span>
    ) : (
      <HootTypography isPII={false} sx={{ display: 'inherit' }}>
        {props.lesson.prefixedStudentNumber}
      </HootTypography>
    );
  };

  return (
    <Card
      sx={{ marginTop: '16px' }}
      title={
        <Stack>
          <Typography>Lesson</Typography>
          <Typography variant="titlemedium">{`${DateTime.fromMillis(props.lesson.startsAt).toFormat('h:mm a')} - ${DateTime.fromMillis(
            props.lesson.startsAt,
          )
            .plus({ minutes: props.lesson.durationInMinutes })
            .toFormat('h:mm a')}`}</Typography>
        </Stack>
      }
    >
      <ReadOnlyTextField label="Student" filledColour={colour} body={studentFieldBody()} />
      <ReadOnlyTextField
        sx={{ marginTop: '16px' }}
        filledColour={colour}
        label="Lesson Status"
        body={lessonStatusesForCustomersLookup[props.lesson.status]}
      />
      {props.lesson.cancellationReason ? (
        <ReadOnlyTextField
          sx={{ marginTop: '16px' }}
          filledColour={colour}
          label="Cancellation Reason"
          body={cancellationReasonMessageLookup[props.lesson.cancellationReason]}
        />
      ) : null}
      <ReadOnlyTextField
        sx={{ marginTop: '16px' }}
        filledColour={colour}
        label="Lesson Number"
        body={
          <Stack direction="row" alignItems="center" minWidth="100%" justifyContent="space-between">
            {props.lesson.prefixedLessonNumber}
            <IconButton size="small" sx={{ p: 0.75 }} onClick={onCopyLessonNumber}>
              <Icon name="copy" />
            </IconButton>
          </Stack>
        }
      />
    </Card>
  );
}

function TimeOffEventCard(props: { timeoffEvent: TimeOffEvent }) {
  const queryClient = useQueryClient();
  const deleteTeacherAvailablityRequest = useDeleteTeacherAvailabilityException();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCloseModal = () => {
    setShowConfirmation(false);
  };

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleOnDelete = () => {
    deleteTeacherAvailablityRequest.mutate(
      {
        teacherAvailabilityExceptionId: props.timeoffEvent.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailabilityExceptions);
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailablityExceptionDrafts);
        },
      },
    );
  };

  return (
    <Card sx={{ marginTop: '16px' }} title="Time Off">
      <ReadOnlyTextField
        label="Time"
        filledColour="warning.190"
        body={`${props.timeoffEvent.startTime.toFormat('h:mm a')} - ${props.timeoffEvent.endTime.toFormat('h:mm a')}`}
      />
      <Button onClick={handleDeleteClick} sx={{ marginTop: '16px' }} fullWidth color="error.80" variant="text" startIcon={<Icon name="trash_bin" />}>
        Delete Time Off
      </Button>
      {showConfirmation ? <DeleteConfirmation onDelete={handleOnDelete} onDismiss={handleCloseModal} /> : null}
    </Card>
  );
}

function DeleteConfirmation(props: { onDelete: () => void; onDismiss: () => void }) {
  return (
    <BasicAlertDialog
      title="Delete Confirmation"
      content="Are you sure you want to delete this Time Off"
      onDismiss={props.onDismiss}
      show={true}
      primaryAction={{ label: 'Delete', onClick: props.onDelete }}
      secondaryAction={{ label: 'Cancel', onClick: props.onDismiss }}
    />
  );
}
