import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { featureFlags } from '@hoot/constants/featureFlags';
import useGetRequiredTasks, {
  RequiredTaskKey,
  RequiredTaskResponse,
  RequiredTaskResponseQuery,
  TaskType,
} from '@hoot/hooks/api/user-tasks/useGetRequiredTasks';
import { DEFAULT_PAGE_SIZE } from '@hoot/models/api/pagination';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Badge from '@hoot/ui/components/v2/core/Badge';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';

export const taskTypeLabel: { [key in TaskType]: string } = {
  [TaskType.LessonAssessment]: 'Assessment',
  [TaskType.LessonReview]: 'Post Lesson Review',
};

export interface TableColumns {
  studentName: React.ReactNode;
  taskType: string;
  status: React.ReactNode;
  actionButton: React.ReactNode;
}

const ActionRequired = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { isFeatureEnabled } = useFeatureFlags();
  const isInLessonHRAEnabled = isFeatureEnabled(featureFlags.SC_10263_in_lesson_hra);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [query, setQuery] = useState<RequiredTaskResponseQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: taskData } = useGetRequiredTasks(query);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((prev) => ({ ...prev, page: 1, pageSize }));
  };

  const handleActionClick = (task: RequiredTaskResponse) => {
    const { taskType, studentProfileId, meta } = task;
    const metaLessonId = meta?.find((m) => m.key === RequiredTaskKey.LessonId)?.val;

    if (taskType === TaskType.LessonReview) {
      navigate(routesDictionary.myStudents.details.reviews.url(studentProfileId), { state: { lessonId: metaLessonId }, replace: true });
    }

    if (!isInLessonHRAEnabled && taskType === TaskType.LessonAssessment) {
      navigate(routesDictionary.myStudents.details.assessments.url(studentProfileId));
    }
  };

  const headers: HeaderData<TableColumns>[] = [
    { name: 'Student Name', property: 'studentName' },
    { name: 'Task Type', property: 'taskType' },
    { name: 'Status', property: 'status' },
    { name: 'Action', property: 'actionButton' },
  ];

  const rowData =
    taskData?.data?.map<TableColumns>((row) => ({
      studentName: (
        <Link to={routesDictionary.myStudents.details.profile.url(row.studentProfileId)} style={{ color: '#000' }}>
          {row.studentProfileName}
        </Link>
      ),
      taskType: taskTypeLabel[row.taskType],
      status: <Tag label={row.status} color={row.status === 'Required' ? TagColor.Success : TagColor.Warning} sx={{ textTransform: 'capitalize' }} />,
      actionButton: (
        <Button onClick={() => handleActionClick(row)} variant="contained" size="small">
          {/* TODO: after SC_10263_in_lesson_hra FF is removed in sc-12878 remove the check here and just leave 'Create' */}
          {row.taskType === TaskType.LessonReview ? 'Create' : 'Go to HRA'}
        </Button>
      ),
    })) ?? [];

  const count = taskData?.data?.length ?? 0;

  return (
    <Stack gap={2}>
      {count > 0 && (
        <Card sx={{ maxWidth: isMobile ? '100%' : '300px' }}>
          <Stack direction="row" gap={2} mb={2}>
            <HootTypography isPII={false} variant="titlemedium">
              Action Required
            </HootTypography>
            <Badge badgeContent={count} color={'error.80'} />
          </Stack>
          <Stack alignItems="center" gap={2}>
            <HootTypography isPII={false} variant="displaylarge">
              {count}
            </HootTypography>
            <HootTypography isPII={false} variant="bodylarge">
              {count > 1 ? 'Items require attention' : count === 1 ? 'Item requires attention' : 'No items require attention'}
            </HootTypography>
            {!isMobile && (
              <Button onClick={() => setOpenDialog(true)} variant="outlined" size="small">
                View
              </Button>
            )}
          </Stack>
        </Card>
      )}

      <BasicAlertDialog
        title={'Action Required'}
        show={openDialog}
        onDismiss={() => setOpenDialog(false)}
        maxWidth={'md'}
        paperProps={{}}
        content={
          <TableV2
            data={rowData}
            headers={headers}
            isPaginated
            count={count}
            page={query.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.Coffee} />}
          />
        }
      />
    </Stack>
  );
};

export default ActionRequired;
