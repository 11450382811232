import { Stack } from '@mui/system';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { zIndexes } from '@hoot/constants/zIndices';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { TypographyVariant } from '@hoot/ui/theme/v2/typography';
import { hootTokens } from '../../../theme/v2/tokens';
import HootTypography from './HootTypography';
import { Icon } from './Icon';

interface FloatingDialogProps {
  title: string;
  titleVariant?: TypographyVariant;
  children: React.ReactNode;
  onClose: () => void;
  width: string;
  height?: string;
  dragFromAnywhere?: boolean;
}

interface Coordinates {
  x: number;
  y: number;
}

const initialCoordinates: Coordinates = {
  x: 24,
  y: 24,
};

export function FloatingDialog(props: FloatingDialogProps) {
  // If Session has a key, use those coordinates. Else, just use the initial defaults.
  const coordinatesFromStorage = JSON.parse(
    window.sessionStorage.getItem(SessionStorageKey.FLOATING_DIALOG_POSITION) || JSON.stringify(initialCoordinates),
  );

  const handleOnStop = (_event: DraggableEvent, element: DraggableData) => {
    const coordinates: Coordinates = {
      x: element.x,
      y: element.y,
    };

    // Record the last position to Session Storage.
    window.sessionStorage.setItem(SessionStorageKey.FLOATING_DIALOG_POSITION, JSON.stringify(coordinates));
  };

  return (
    <Draggable handle="#header" defaultPosition={coordinatesFromStorage} onStop={handleOnStop}>
      <Stack
        id={props.dragFromAnywhere ? 'header' : undefined}
        sx={{
          backgroundColor: hootTokens.palette.white,
          borderRadius: '8px',
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '16px',
          boxShadow: hootTokens.elevation.elevation4,
          width: props.width,
          height: props.height,
          zIndex: zIndexes.floatingDialog,
          cursor: props.dragFromAnywhere ? 'move' : 'auto',
        }}
      >
        <Stack
          id={!props.dragFromAnywhere ? 'header' : undefined}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'move' }}
        >
          <HootTypography isPII={false} variant={props.titleVariant ?? 'titlesmall'}>
            {props.title}
          </HootTypography>
          {/* Note: onTouchStart needed as react-draggable overlays the button functionality */}
          <IconButton onClick={props.onClose} onTouchStart={props.onClose}>
            <Icon name="close" />
          </IconButton>
        </Stack>

        {props.children}
      </Stack>
    </Draggable>
  );
}
