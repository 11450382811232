import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AssessmentUnitSubmission, useSubmitAssessment } from '@hoot/hooks/api/assessment/useSubmitAssessment';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { Layout6Questionnaire, QuestionnaireLayout6 } from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout6';
import useTeacherHRAController from '../useTeacherHRAController';

export interface HRAQuestionnaireLayout_6_Props {
  assessmentId: string;
  unitIdentifier: UnitIdentifierHRAV2.U_4_3 | UnitIdentifierHRAV2.U_4_4;
  unitId: string;
  unitTitle: string;
  instructions: string;
  questionnaire: Layout6Questionnaire[];
  submissionId?: string;
  isPreviousUnitEnabled: boolean;
}

interface HRAQuestionnaireLayout_6_Unit_4_3_SubmitDataType {
  // Radio selections for each question.
  U_4_3_S1_F1: string; // Text-Specific Knowledge
  U_4_3_S2_F1: string; // Text Structure
  U_4_3_S3_F1: string; // Sentence Analysis
  U_4_3_S4_F1: string; // Verbal Reasoning
  U_4_3_S5_F1: string; // Vocabulary (Overall)
  // Book
  U_4_3_S6_F1: {
    id: string;
    title: string;
  };
}

interface HRAQuestionnaireLayout_6_Unit_4_4_SubmitDataType {
  // Radio selections for each question.
  U_4_4_S1_F1: string; // Text-Specific Knowledge
  U_4_4_S2_F1: string; // Text Structure
  U_4_4_S3_F1: string; // Sentence Analysis
  U_4_4_S4_F1: string; // Verbal Reasoning
  U_4_4_S5_F1: string; // Vocabulary (Overall)
  // Book
  U_4_4_S6_F1: {
    id: string;
    title: string;
  };
}

export function HRA_QUESTIONNAIRE_LAYOUT_6(props: HRAQuestionnaireLayout_6_Props) {
  const { assessmentId, unitId, unitTitle, instructions, questionnaire, unitIdentifier, submissionId, isPreviousUnitEnabled } = props;

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isUnitQuestionnaireDirty, setIsUnitQuestionnaireDirty] = useState<boolean>(false);

  const [answers, setAnswers] = useState<Module_4_GenericAnswer[]>(
    props.questionnaire.map((q) => ({
      section: q.title,
      answer: null,
      answerValue: null,
    })),
  );

  const dispatch = useAppDispatch();

  const { studentId, teacherId } = useSelector((state: RootState) => state.activeLesson.inLesson)!;
  const bookId = useSelector((state: RootState) => state.readers.inLessonReader.book?.id);
  const bookTitle = useSelector((state: RootState) => state.readers.inLessonReader.book?.title);

  const hraController = useTeacherHRAController();

  const submitUnitRequest = useSubmitAssessment(assessmentId, unitId);

  const onPreviousUnitClick = () => {
    if (isUnitQuestionnaireDirty) {
      setLeaveDialog(true);
    } else {
      hraController.onPrevUnit();
    }
  };

  const handleChange = (val: Module_4_GenericAnswer[]) => {
    setIsUnitQuestionnaireDirty(true);
    setAnswers(val);
  };

  const confirmPrevUnitClick = () => {
    hraController.onPrevUnit();
  };

  const onNextUnit = () => {
    hraController.onNextUnit();
  };

  const handleSubmit = () => {
    const answerDictionary = new Map(answers.map((x) => [x.section as Layout6Questionnaire['title'], x.answerValue!.toString()]));

    function getSubmissionData() {
      if (props.unitIdentifier === UnitIdentifierHRAV2.U_4_3) {
        const submissionData: HRAQuestionnaireLayout_6_Unit_4_3_SubmitDataType = {
          // Radio selections for each question.
          U_4_3_S1_F1: answerDictionary.get('Text-Specific Knowledge')!,
          U_4_3_S2_F1: answerDictionary.get('Text Structure')!,
          U_4_3_S3_F1: answerDictionary.get('Sentence Analysis')!,
          U_4_3_S4_F1: answerDictionary.get('Verbal Reasoning')!,
          U_4_3_S5_F1: answerDictionary.get('Vocabulary (Overall)')!,
          // Book
          U_4_3_S6_F1: {
            id: bookId!,
            title: bookTitle!,
          },
        };
        return submissionData;
      } else if (props.unitIdentifier === UnitIdentifierHRAV2.U_4_4) {
        const submissionData: HRAQuestionnaireLayout_6_Unit_4_4_SubmitDataType = {
          // Radio selections for each question.
          U_4_4_S1_F1: answerDictionary.get('Text-Specific Knowledge')!,
          U_4_4_S2_F1: answerDictionary.get('Text Structure')!,
          U_4_4_S3_F1: answerDictionary.get('Sentence Analysis')!,
          U_4_4_S4_F1: answerDictionary.get('Verbal Reasoning')!,
          U_4_4_S5_F1: answerDictionary.get('Vocabulary (Overall)')!,
          // Book
          U_4_4_S6_F1: {
            id: bookId!,
            title: bookTitle!,
          },
        };
        return submissionData;
      }
    }

    const submissionData = getSubmissionData();

    const unitSubmission: AssessmentUnitSubmission = {
      submissionId: submissionId,
      studentProfileId: studentId,
      teacherAccountId: teacherId,
      score: undefined,
      instructionIndicator: undefined,
      transitionScore: undefined,
      submissionData,
    };
    submitUnitRequest.mutate(unitSubmission, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: `Unit ${unitTitle} successfully submitted.` }));
        hraController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: `Oops! There was a problem submitting unit ${unitTitle}.` }));
      },
    });
  };

  return (
    <QuestionnaireLayout6
      unitIdentifier={unitIdentifier}
      title="Hoot Reading Assessment"
      unitTitle={unitTitle}
      instruction={instructions}
      onChange={handleChange}
      isNextEnabled={true}
      isPrevEnabled={isPreviousUnitEnabled}
      leaveDialog={leaveDialog}
      setLeaveDialog={setLeaveDialog}
      onPreviousUnitClick={onPreviousUnitClick}
      confirmPrevUnitClick={confirmPrevUnitClick}
      onSubmit={handleSubmit}
      isSubmitting={submitUnitRequest.isLoading}
      onNext={onNextUnit}
      questionnaire={questionnaire}
      value={answers}
      isUnitQuestionnaireDirty={isUnitQuestionnaireDirty}
      showQuestionnaireDefault={unitIdentifier === UnitIdentifierHRAV2.U_4_4}
      onExitAssessment={hraController.onExitAssessment}
    />
  );
}
